import Arrow from "assets/arrow.svg";
import Chevron from "assets/chevron.svg";
import moment from "moment";
import { useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

import { Accordion, AccordionDetails, AccordionSummary, LinearProgress } from "@mui/material";

import { DateDescriptor } from "../hooks/useDate";
import { Event } from "../models/Event";
import { isAnyEventThisDay } from "../utils";
import { CalendarButton } from "./CalendarButton";

interface Props {
  events: Event[];
  isLoading: boolean;
  currentDate: DateDescriptor;
  selectedDay: number;
  today: number;
  onTodayClick: any;
  isToday: any;
  onDayClick: (day: number) => void;
  changeMonth: (direction: number, isExpanded: boolean) => void;
}

export const Calendar: React.FC<Props> = ({
  events,
  isLoading,
  isToday,
  currentDate,
  selectedDay,
  today,
  onDayClick,
  onTodayClick,
  changeMonth,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  const thisMonth = moment().month();

  const backMonthRef = useRef<HTMLImageElement>(null);
  const nextMonthRef = useRef<HTMLImageElement>(null);
  const componentRef = useRef<any>(null);

  const stringMonth = moment(currentDate).format("DD MMMM").toUpperCase();
  const weekdays = moment.weekdays().map((w) => w.substring(0, 2));

  function handleExpand(e) {
    if (e.target === backMonthRef.current || e.target === nextMonthRef.current) {
      if (!isExpanded) {
        setExpanded(true);
      }
      return;
    }

    setExpanded(!isExpanded);
  }

  const handlers: any = useSwipeable({
    onSwiped: (eventData: any) => {
      if (eventData.dir === "Left") {
        changeMonth(1, isExpanded);
      }

      if (eventData.dir === "Right") {
        changeMonth(-1, isExpanded);
      }
    },
  });

  const refPassthrough = (el) => {
    handlers.ref(el);
    componentRef.current = el;
  };

  return (
    <Accordion
      {...handlers}
      ref={refPassthrough}
      disableGutters
      expanded={isExpanded}
      sx={{
        "& .MuiAccordionSummary-root": {
          height: isExpanded ? "54px" : "74px",
          padding: "0px",
          boxShadow: "0px 4px 20px 0px #00000024",
        },
        "& .MuiAccordionSummary-content": {
          margin: "0px",
          height: "100%",
        },
        "& .MuiAccordionDetails-root": {
          padding: "0px",
        },
      }}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex h-full w-full flex-col justify-between">
          <div className="flex w-full flex-1 cursor-default flex-row items-center justify-between bg-brand-primary px-4">
            <button
              onClick={() => changeMonth(-1, isExpanded)}
              aria-label="Previous month"
              className="z-50 h-fit w-fit"
            >
              <img src={Arrow} alt="Previous month" ref={backMonthRef} />
            </button>
            {!isToday() && (
              <div
                onClick={onTodayClick}
                className="absolute ml-8 cursor-pointer rounded px-2 py-1"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.5)", fontSize: "14px" }}
              >
                Today
              </div>
            )}
            <p className="text-base font-medium leading-4 text-text-title" onClick={handleExpand}>
              {stringMonth}
            </p>
            <button onClick={() => changeMonth(1, isExpanded)} aria-label="Next month" className="z-50 h-fit w-fit">
              <img src={Arrow} alt="Next month" className="rotate-180" ref={nextMonthRef} />
            </button>
          </div>
          {!isExpanded && (
            <div
              className="flex h-5 w-full cursor-pointer flex-row items-center justify-center bg-brand-secondary"
              onClick={handleExpand}
            >
              <img src={Chevron} alt="Expand calendar" />
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="bg-brand-secondary">
          <div className="flex w-full justify-center p-6">
            <div className="grid grid-cols-7 justify-items-center gap-0.5">
              <span className="text-xs h-4 w-11 text-center font-semibold capitalize leading-4">{weekdays[1]}</span>
              <span className="text-xs h-4 w-11 text-center font-semibold capitalize leading-4">{weekdays[2]}</span>
              <span className="text-xs h-4 w-11 text-center font-semibold capitalize leading-4">{weekdays[3]}</span>
              <span className="text-xs h-4 w-11 text-center font-semibold capitalize leading-4">{weekdays[4]}</span>
              <span className="text-xs h-4 w-11 text-center font-semibold capitalize leading-4">{weekdays[5]}</span>
              <span className="text-xs h-4 w-11 text-center font-semibold capitalize leading-4">{weekdays[6]}</span>
              <span className="text-xs h-4 w-11 text-center font-semibold capitalize leading-4">{weekdays[0]}</span>
              {!isLoading ? (
                Array(42)
                  .fill(0)
                  .map((_, i) => {
                    const firstDayOfWeek = currentDate.startOfMonth === 0 ? 6 : currentDate.startOfMonth - 1; //adapt for first day == "sunday"

                    const day =
                      i < firstDayOfWeek
                        ? -1
                        : i - firstDayOfWeek + 1 > currentDate.endOfMonth
                        ? -1
                        : i - firstDayOfWeek + 1;

                    const variant =
                      day === -1
                        ? "invalid"
                        : thisMonth > currentDate.month || (thisMonth === currentDate.month && today > day)
                        ? "pastday"
                        : thisMonth < currentDate.month || (thisMonth === currentDate.month && today < day)
                        ? "futureday"
                        : "today";

                    const eventThisDay = day !== -1 && isAnyEventThisDay(events, day, currentDate);

                    return (
                      <CalendarButton
                        key={i}
                        variant={variant}
                        selected={day === selectedDay}
                        onClick={() => onDayClick(day)}
                        eventThisDay={eventThisDay}
                      >
                        {day}
                      </CalendarButton>
                    );
                  })
              ) : (
                <LinearProgress className="col-span-7 mt-2 w-full" />
              )}
            </div>
          </div>
        </div>
        {isExpanded && (
          <>
            <div className="h-px bg-brand-primary" />
            <div
              onClick={() => setExpanded(false)}
              className="flex h-5 w-full cursor-pointer flex-row items-center justify-center bg-brand-secondary"
            >
              <img src={Chevron} alt="Shrink calendar" className="rotate-180" />
            </div>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
