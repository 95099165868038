import moment from "moment";

import { DateDescriptor } from "../hooks/useDate";
import { Event } from "../models/Event";

export function isAnyEventThisDay(events: Event[], day: number, currentDate: DateDescriptor) {
  const thisDay = moment(`${currentDate.year}-${currentDate.month + 1}-${day}`, "YYYY-MM-DD");

  return events.some((event) =>
    thisDay.isSame(moment(`${currentDate.year}-${event.month}-${event.day}`, "YYYY-MM-DD"), "day")
  );
}

export function getSelectedDayEvents(selectedDay: number, events: Event[]) {
  return events.filter((event) => {
    const eventDate = moment(`2022-${event.month}-${event.day}`, "YYYY-MM-DD");
    const eventOnSelectedDate = moment(`${eventDate.year()}-${eventDate.month() + 1}-${selectedDay}`, "YYYY-MM-DD");
    return eventOnSelectedDate.isSame(eventDate, "day");
  });
}

export function sortEvents(events: Event[], ascendent: boolean): Event[] {
  const sorted = events.sort((a, b) => {
    const aDate = moment(`${a.month}-${a.day}`, "MM-DD");
    const bDate = moment(`${b.month}-${b.day}`, "MM-DD");
    return aDate.isBefore(bDate) ? -1 : 1;
  });

  if (ascendent) return sorted;

  return sorted.reverse();
}
