import { environment } from "environment/environment";

import { createLaravelHttpClient } from "@vadiun/http-client";

import { LocalStorage } from "./localStorage";

export const httpClient = createLaravelHttpClient({
  url: environment.backEnd,
  token: () => LocalStorage.token.get() ?? "",
});
