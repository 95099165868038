export const slugShareAddBirthDayGuest = {
  en: {
    "slug.share.addBirthDayGuest.title": "Add your birthday",
    "slug.share.addBirthDayGuest.description": "Share the joy by adding your birthday date to :SLUG's calendar.",

    "slug.share.addBirthDayGuest.form.birthdayDate": "Birthday date",
    "slug.share.addBirthDayGuest.form.name": "Name",
    "slug.share.addBirthDayGuest.form.email": "Email",
    "slug.share.addBirthDayGuest.form.emailDescription": "Your friend will send you greetings to this email.",
    "slug.share.addBirthDayGuest.form.phone": "Mobile number (optional)",
    "slug.share.addBirthDayGuest.form.phoneDescription": "Your friend will send you greetings to this number.",
    "slug.share.addBirthDayGuest.form.action": "Add birthday",

    "slug.share.addBirthDayGuest.form.validation#1": "Birthday month is required",
    "slug.share.addBirthDayGuest.form.validation#2": "Birthday day is required",
    "slug.share.addBirthDayGuest.form.validation#3": "Birthdate is not valid",
    "slug.share.addBirthDayGuest.form.validation#4": "Name must be at least 3 characters",
    "slug.share.addBirthDayGuest.form.validation#5": "Name is required",
    "slug.share.addBirthDayGuest.form.validation#6": "Email is required",
    "slug.share.addBirthDayGuest.form.consent":
      "By adding your birthday you accept to share your email | number with :SLUG.",
  },
  es: {
    "slug.share.addBirthDayGuest.title": "Agrega tu cumpleaños",
    "slug.share.addBirthDayGuest.description":
      "Comparta la alegría agregando tu fecha de cumpleaños al calendario de :SLUG.",

    "slug.share.addBirthDayGuest.form.birthdayDate": "Fecha de cumpleaños",
    "slug.share.addBirthDayGuest.form.name": "Nombre o apodo",
    "slug.share.addBirthDayGuest.form.email": "Correo electrónico",
    "slug.share.addBirthDayGuest.form.emailDescription": "Tu amigo te enviará saludos a este correo electrónico.",
    "slug.share.addBirthDayGuest.form.phone": "Número de teléfono móvil (opcional)",
    "slug.share.addBirthDayGuest.form.phoneDescription": "Tu amigo te enviará saludos a este número.",
    "slug.share.addBirthDayGuest.form.action": "Agregar cumpleaños",

    "slug.share.addBirthDayGuest.form.validation#1": "Se requiere mes de cumpleaños",
    "slug.share.addBirthDayGuest.form.validation#2": "Se requiere el día del cumpleaños",
    "slug.share.addBirthDayGuest.form.validation#3": "La fecha de nacimiento no es válida",
    "slug.share.addBirthDayGuest.form.validation#4": "El nombre debe tener al menos 3 caracteres",
    "slug.share.addBirthDayGuest.form.validation#5": "Se requiere nombre",
    "slug.share.addBirthDayGuest.form.validation#6": "Correo electronico es requerido",
    "slug.share.addBirthDayGuest.form.consent":
      "Al agregar su cumpleaños, acepta compartir su correo electrónico | Número con :SLUG.",
  },
};
