interface ISlug {
  validated: boolean;
}

export class Slug {
  validated: boolean;

  constructor(args: ISlug) {
    this.validated = args.validated;
  }
}
