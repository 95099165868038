export const joinUsPage = {
  en: {
    "main.join.title": "You are on :SLUG’s calendar! ",
    "main.join.description": "Add :SLUG’s <br/> birthday to your calendar!",
    "main.join.action": "Sign up & add birthday",
    "main.join.signupText": "Want to create an account without adding their birthday?",
    "main.join.signup": "Sign up",

    "main.join.user.action": "Add their birthday",
  },
  es: {
    "main.join.title": "¡Estás en el calendario de :SLUG!",
    "main.join.description": "¡Agregue el cumpleaños :SLUG a su calendario!",
    "main.join.action": "Regístrese y agregue cumpleaños",
    "main.join.signupText": "¿Quieres crear una cuenta sin agregar su cumpleaños?",
    "main.join.signup": "Registrarse",
  },
};
