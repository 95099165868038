import { useQueryClient } from 'react-query';

import { useSuperQuery } from '@vadiun/react-hooks';

import { User } from '../models/User';
import { UserRepository } from '../services/userRepository';

const userRepo = new UserRepository();

export const useUser = () => {
  const queryClient = useQueryClient();

  const userQuery = useSuperQuery(
    userRepo.keys.one("me"),
    () => userRepo.get("me"),
    {
      showErrorMessage: false,
    }
  );

  const refetchUser = async () => {
    await queryClient.refetchQueries(userRepo.keys.one("me"));
  };

  return {
    user: userQuery.data as User,
    isLoadingUser: userQuery.isLoading,
    error: userQuery.error,
    refetchUser,
  };
};
