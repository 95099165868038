export const globals = {
  en: {
    "globals.create.account": "Create account",
    "globals.logout": "Log out",
    "globals.verify.cancel": "Nope, go back",
    "globals.slug.invalid": "Oops, looks like this link is no longer valid :(",
    "globals.slug.back": "Go home",
    "globals.save": "Save",
    "globals.saveAdd": "Save and add another",
    "globals.discard": "Discard",

    "globals.validations.birthday.required": "Birthday day is required",
    "globals.validations.birthday.valid": "Birthdate is not valid",
    "globals.validations.nickname.required": "Name or Nickname is required",
    "globals.validations.nickname.length": "Name or Nickname must be at least 3 characters",
    "globals.validations.month.required": "Birthday month is required",
    "globals.validations.yourname.required": "Your name is required",
    "globals.validations.slug.invalid": 'Slug is not valid, " / " character is not allowed.',
    "globals.validations.required": "This field is required",
  },
  es: {
    "globals.create.account": "Crear una cuenta",
    "globals.logout": "Cerrar Sesión",
    "globals.verify.cancel": "Cancelar",
    "globals.slug.invalid": "Oops, parece que este enlace ya no es válido :(",
    "globals.slug.back": "Volver al inicio",
    "globals.save": "Guardar",
    "globals.saveAdd": "Guardar y añadir otro",
    "globals.discard": "Descartar",

    "globals.validations.birthday.required": "Día de cumpleaños es requerido",
    "globals.validations.birthday.valid": "Día de cumpleaños no es válido",
    "globals.validations.nickname.required": "Nombre o apodo es requerido",
    "globals.validations.nickname.length": "Nombre o apodo debe tener al menos 3 caracteres",
    "globals.validations.month.required": "Mes de cumpleaños es requerido",
    "globals.validations.yourname.required": "Tu nombre es requerido",
    "globals.validations.slug.invalid": 'Slug no es valido, " / " no se permite el carácter.',
    "globals.validations.required": "Este campo es obligatorio",
  },
};
