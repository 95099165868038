export const mainError = {
  en: {
    "main.error.title": "Oops! Something happened. Please try again",
    "main.error.action": "Try again",
  },
  es: {
    "main.error.title": "¡Ups! Algo pasó. Inténtalo de nuevo",
    "main.error.action": "Intentar otra vez",
  },
};
