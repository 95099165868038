import { useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';

export const ANIMATION_DURATION = 1000;
export const BUTTON_ANIMATION_DELAY = 700;
export const BUTTON_ANIMATION_DURATION = 0.5;

export const buttonSuccessAnimation = {
  backgroundColor: "#8BDDAC",
  borderRadius: "99999px",
  width: "56px",
};

export const buttonInitialSuccessAnimation = {
  borderRadius: "28px",
  width: "100%",
};

interface Props {
  enterAnimation?: boolean;
  successAnimation?: boolean;
  primaryAnimation?: boolean;
}

export const debounceButtonAnimation = (navigationFn: () => void) =>
  setTimeout(() => {
    navigationFn();
  }, BUTTON_ANIMATION_DELAY);

export const useButtonAnimation = (props: Props) => {
  const [isAnimating, setAnimating] = useState(false);
  const animationControls = useAnimation();

  const primaryTransition = { duration: BUTTON_ANIMATION_DURATION };

  const hidden = {
    display: "none",
    opacity: 0,
    y: -5,
  };
  const initial = props.enterAnimation ? hidden : {};

  useEffect(() => {
    if (props.enterAnimation) {
      enterAnimation();
    }
  }, [props.enterAnimation]);

  useEffect(() => {
    if (!props.successAnimation) return;
    props.primaryAnimation
      ? successPrimaryAnimation()
      : successSecondaryAnimation();
  }, [props.successAnimation]);

  async function successPrimaryAnimation() {
    setAnimating(true);
    await animationControls.start({
      ...buttonSuccessAnimation,
      transition: primaryTransition,
    });
  }

  async function enterAnimation() {
    await animationControls.start({
      opacity: 100,
      y: 0,
      display: "flex",
      transition: {
        duration: 0.4,
      },
    });
    return;
  }
  async function successSecondaryAnimation() {
    setAnimating(true);
    await animationControls.start({
      backgroundColor: "#8BDDAC",
      borderRadius: "99999px",
      borderWidth: "0px",
      width: "56px",
      transition: {
        duration: 0.5,
      },
    });
    await animationControls.start({
      backgroundColor: "#8BDDAC",
      borderRadius: "99999px",
      borderWidth: "0px",
      width: "56px",
      transition: {
        duration: 0.4,
      },
    });
    await animationControls.start({
      backgroundColor: "#FEF6E4",
      borderRadius: "28px",
      borderWidth: "1px",
      width: "100%",
      transition: {
        duration: 0.5,
      },
    });
    setAnimating(false);
  }

  return {
    isAnimating,
    initial,
    control: animationControls,
  };
};
