import { EventRepository, ICreateSubscription } from "app/common/events/services/eventRepository";
import { UserRepository } from "app/common/user/services/userRepository";
import { PageBody, PageContainer } from "layout/components";
import { BodyHeader } from "layout/components/BodyHeader";
import { debounceButtonAnimation } from "layout/hooks/useButtonAnimation";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "shared/services";
import { validateDate } from "shared/utils";

import { useSuperMutation } from "@vadiun/react-hooks";

import { AddBirthdaySubscriptionForm } from "../../../../common/slug/forms/addBirthdaySubscriptionForm/AddBirthdaySubscriptionForm";
import { AddBirthdaySubscriptionFormType } from "../../../../common/slug/forms/addBirthdaySubscriptionForm/AddBirthdaySubscriptionFormType";

interface Props {
  repo: UserRepository;
  eventRepo: EventRepository;
  slug: string;
}
export const SlugShareAddBirthdayGuest = ({ repo, eventRepo, slug }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const emailRef = useRef("");

  const saveDataForJoinUsFlow = (variables: ICreateSubscription) =>
    LocalStorage.joinUsFlow.set({
      nickname: variables.nickname,
      day: variables.day,
      month: variables.month,
      phone: variables.phone,
    });

  const eventMutation = useSuperMutation(eventRepo.createEventFromGuest, {
    showSuccessMessage: false,
    onSuccess: (_, variables) => {
      saveDataForJoinUsFlow(variables);
      const email = encodeURIComponent(emailRef.current);
      debounceButtonAnimation(() => navigate(`/join-us?email=${email}&slug=${slug}`));
    },
  });

  async function handleSubmit(values: AddBirthdaySubscriptionFormType) {
    emailRef.current = values.email;
    await shareBirthday(values);
    setAlreadyAddedSlug();
  }

  function setAlreadyAddedSlug() {
    const alreadyAdded: string[] = LocalStorage.alreadyAdded.get();
    alreadyAdded.push(slug);
    LocalStorage.alreadyAdded.set(alreadyAdded);
  }

  async function shareBirthday(values: AddBirthdaySubscriptionFormType) {
    const { day, month } = validateDate(values.day, values.month);
    const { nickname, email, phone } = values;
    await eventMutation.mutateAsync({
      day,
      month,
      nickname,
      email: email.trim(),
      phone,
      subscriptor_slug: slug,
    });
  }

  return (
    <PageContainer footer hideProfile>
      <PageBody>
        <div className="mb-5 flex w-full flex-col gap-6 lg:px-5">
          <BodyHeader title={t("slug.share.addBirthDayGuest.title")} cloud>
            <BodyHeader.Description>
              {t("slug.share.addBirthDayGuest.description").replace(":SLUG", slug)}
            </BodyHeader.Description>
          </BodyHeader>
          <AddBirthdaySubscriptionForm success={eventMutation.isSuccess} onSubmit={handleSubmit} slug={slug} />
        </div>
      </PageBody>
    </PageContainer>
  );
};
