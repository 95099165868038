import { httpClient } from "shared/services/httpClient";

import { Interest } from "../models/Interest";

export const getEventMapper = (x: any): Interest => new Interest(x);

export class InterestsRepository {
  keys = {
    all: () => ["interest"],
  };

  getAll = async () => {
    const res = await httpClient.get("interests");
    return res.map(getEventMapper);
  };
}
