export const addTheirBdayPage = {
  en: {
    "main.addTheirBday.title": "You are on :SLUG’s calendar! ",
    "main.addTheirBday.description": "Add :SLUG’s <br/> birthday to your calendar!",
    "main.addTheirBday.action": "Add their birthday",
    "main.addTheirBday.signupText": "or go to",
    "main.addTheirBday.signup": "your calendar",

    "main.addTheirBday.user.action": "Add their birthday",
  },
  es: {
    "main.addTheirBday.title": "¡Estás en el calendario de :SLUG!",
    "main.addTheirBday.description": "¡Agregue el cumpleaños :SLUG a su calendario!",
    "main.addTheirBday.action": "Regístrese y agregue cumpleaños",
    "main.addTheirBday.signupText": "¿Quieres crear una cuenta sin agregar su cumpleaños?",
    "main.addTheirBday.signup": "Registrarse",
  },
};
