import { useAuthService } from "app/common/auth/services";
import { EventRepository } from "app/common/events/services/eventRepository";
import { Loading } from "app/common/main/pages/Loading";
import { UserRepository } from "app/common/user/services/userRepository";
import { AuthNavigation } from "app/pages/auth/routes";
import { Error } from "app/pages/error";
import { InvalidSlug } from "app/pages/invalid-slug";
import { JoinUsPage } from "app/pages/join-us";
import { LoggedIn } from "app/pages/logged-in";
import { MainNavigation } from "app/pages/main";
import { UserNavigation } from "app/pages/user";
import { SlugNavigation } from "app/pages/[slug]";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { LocalStorage } from "shared/services";

import { useSuperQuery } from "@vadiun/react-hooks";

import { AddTheirBday } from "./add-their-bday";

const eventRepo = new EventRepository();

function RequireAuth({ children }: { children: JSX.Element }) {
  const authService = useAuthService();

  if (authService.isLoading) return <Loading />;

  if (!authService.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/welcome" replace />;
  }

  return children;
}

function SetupOnboard({ children }: { children: JSX.Element }) {
  const userRepo = new UserRepository();
  const isSlugSetupDone = LocalStorage.slugSetup.get() !== null;
  const { enabled: isSignupAndBdayEnabled, slug } = LocalStorage.signupAndBday.get();

  const userQuery = useSuperQuery(
    userRepo.keys.one("me"),
    () => {
      return userRepo.get("me");
    },
    {
      showErrorMessage: false,
    }
  );
  const { data, isLoading } = userQuery;

  if (isLoading) {
    return <Loading />;
  }

  if (!data?.profile.onboarding) {
    return isSlugSetupDone ? <Navigate to="/user/setup" replace /> : <Navigate to="/user/setup/birthday" replace />;
  }

  if (isSignupAndBdayEnabled) {
    return <Navigate to={`/add-their-bday?slug=${slug}`} replace />;
  }

  return children;
}

export const AppNavigation = () => {
  const authSrv = useAuthService();

  return (
    <Routes>
      <Route path="/auth/*" element={<AuthNavigation />} />
      <Route
        path="/user/*"
        element={
          <RequireAuth>
            <UserNavigation />
          </RequireAuth>
        }
      />
      <Route
        path="/main/*"
        element={
          <RequireAuth>
            <SetupOnboard>
              <MainNavigation />
            </SetupOnboard>
          </RequireAuth>
        }
      />
      <Route path="/:slug/*" element={<SlugNavigation />} />
      <Route path="/invalid-slug" element={<InvalidSlug />} />
      <Route path="/join-us" element={<JoinUsPage authSrv={authSrv} useSearchParams={useSearchParams} />} />
      <Route
        path="/add-their-bday"
        element={<AddTheirBday authSrv={authSrv} useSearchParams={useSearchParams} eventRepo={eventRepo} />}
      />
      <Route path="/logged-in" element={<LoggedIn />} />
      <Route path="/error" element={<Error />} />
      <Route path="/" element={<Navigate to="/main/events/display" />} />
    </Routes>
  );
};
