import Arrow from "assets/arrow.svg";
import Cloud from "assets/cloud.svg";
import cloud_setup from "assets/cloud_setup.svg";
import logo from "assets/logo.png";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  toggleSidebar: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  navigateBack: () => void;
  child?: boolean;
  toolbar?: React.ReactNode;
  setup?: boolean;
  auth?: boolean;
  slug?: boolean;
  hideProfile?: boolean;
}

export const Header = (props: Props) => {
  const bgClass = props.setup ? "bg-brand-accent-default" : "bg-brand-primary";
  const cloudClass = props.setup ? "items-start pt-3" : props.slug ? "items-start pt-4" : "items-center";
  const backArrowBg = "bg-brand-secondary";

  return (
    <header className={`relative flex ${bgClass} mx-auto w-full max-w-xl`}>
      <div className="text-primary flex h-16 flex-grow items-center justify-between px-4">
        {props.child ? (
          <button
            className={`${backArrowBg} mr-4 flex h-8 w-8 items-center justify-center rounded-full`}
            onClick={props.navigateBack}
          >
            <img src={Arrow} alt="go back" className="object-cover" />
          </button>
        ) : (
          <div className={`flex h-full ${cloudClass}`}>
            {props.setup ? (
              <img src={cloud_setup} alt="cloud" className="z-0 h-6" />
            ) : props.slug ? null : (
              !props.auth && <img src={Cloud} alt="Header Cloud" className="z-0 h-6" />
            )}
          </div>
        )}
        <img alt="unforget logo" src={logo} className={`absolute right-0 left-0 mx-auto w-[89px] object-cover`} />
        {/* ME icon */}
        {!props.auth && !props.slug && !props.hideProfile && (
          <Link
            id="unforget-account"
            to="/user/settings"
            className="flex h-8 w-8 items-center justify-center rounded-full bg-icon text-sm text-white"
          >
            ME
          </Link>
        )}
      </div>
    </header>
  );
};
