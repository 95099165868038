import { SettingsFormType } from "./SettingsFormType";

export const createInitialValues = (): SettingsFormType => ({
  day: 1,
  month: "",
  nickname: "",
  slug: "",
  phone: "",
  interests: [],
});
