import { Link, LinkProps } from "react-router-dom";

import { createStyles } from "./styles";

interface Props extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  className?: string;
  variant?: "text" | "solid";
  isOutlined?: boolean;
}

export const LinkButton: React.FC<Props> = (props) => {
  const isText = props.variant === "text";
  const styles = createStyles({
    isError: false,
    isOutlined: !!props.isOutlined,
    isText,
    color: undefined,
    disabled: false,
    sizes: undefined,
  });
  return (
    <Link {...props} className={`${styles} ${props.className} `}>
      {props.children}
    </Link>
  );
};
