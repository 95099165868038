import Cloud from "assets/cloud.svg";
import React from "react";

interface Props {
  title: string;
  children?: React.ReactNode;
  cloud?: boolean;
}

export const BodyHeader = ({ children, title, cloud }: Props) => {
  return (
    <div className="relative z-0 flex max-w-xs flex-col gap-3">
      {cloud && <img src={Cloud} className="absolute top-4 -right-3 -z-10" alt="cloud" />}
      <h2 id="unforget-title" className="z-30 text-2xl font-medium leading-8 tracking-semiwide text-text-title">
        {title}
      </h2>
      {children}
    </div>
  );
};

BodyHeader.Description = ({ children }) => {
  return <p className="track text-base font-light leading-5 text-text-paragraph">{children}</p>;
};
