import { PageBody, PageContainer } from 'layout/components';

import { CircularProgress } from '@mui/material';

export const Loading = () => {
  return (
    <PageContainer slug>
      <PageBody>
        <div className="flex flex-row items-center justify-center">
          <CircularProgress />
        </div>
      </PageBody>
    </PageContainer>
  );
};
