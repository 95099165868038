export const alreadyAddedPage = {
  en: {
    "slug.added.title": "Hey! You've already added your birthday to :SLUG's calendar",
    "slug.added.action": "Go home",
  },
  es: {
    "slug.added.title": "¡Ey! Ya has añadido tu cumpleaños al calendario de :SLUG",
    "slug.added.action": "Volver al inicio",
  },
};
