import { AddBirthdayFormType } from './AddBirthdayFormType';

export const createInitialValues = (): AddBirthdayFormType => ({
  day: 1,
  month: "01",
  nickname: "",
  email: "",
  phone: "",
  type: "single",
});
