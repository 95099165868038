import T_LOGO from "assets/telegram-icon.svg";
import WS_LOGO from "assets/whatsapp-icon.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swipeable } from "shared/components";

import styled from "@emotion/styled";
import { useSuperMutation } from "@vadiun/react-hooks";

interface Props {
  slug: string | undefined;
}

const AddBirthday = styled.button`
  background-color: #b9f3fb;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #172c66;
  display: flex;
  justify-content: center;
  padding: 19px;
  gap: 10px;
`;

export const ShareLink: React.FC<Props> = ({ slug }) => {
  const { t } = useTranslation();
  const [drawerState, setDrawerState] = useState(false);
  //We have to wrap the mutationFn. because share is only available in secure context
  const shareMutation = useSuperMutation((values: ShareData) => navigator.share(values), {
    showSuccessMessage: false,
    showErrorMessage: false,
  });

  const urlOrigin = window.location.origin;
  const url = `${urlOrigin}/${slug}`;
  const message = t("share.message").replace(":SLUG", slug!).replace(":URL", url);
  const wsMessage = `https://wa.me/?text=${encodeURIComponent(message + url)}`;
  const tMessage = `https://t.me/share/url?url=${urlOrigin}&text=${encodeURIComponent(message + url)}`;

  async function handleShare() {
    const shareData: ShareData = {
      title: "Unforget",
      text: message,
      url,
    };
    try {
      if (navigator.canShare(shareData)) {
        shareMutation.mutate(shareData);
      }
    } catch (e) {
      //fallback share
      setDrawerState(true);
    }
  }

  return (
    <>
      <AddBirthday onClick={handleShare}>
        {t("events.displayEvent.share")}
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.5 5H13.5V2H11.5V5H5.5C3.846 5 2.5 6.346 2.5 8V18C2.5 19.103 3.397 20 4.5 20H20.5C21.603 20 22.5 19.103 22.5 18V8C22.5 6.346 21.154 5 19.5 5ZM5.5 7H19.5C19.7652 7 20.0196 7.10536 20.2071 7.29289C20.3946 7.48043 20.5 7.73478 20.5 8L20.501 11.12C19.605 11.348 19.032 11.854 18.585 12.252C18.078 12.702 17.743 13 16.997 13C16.252 13 15.917 12.702 15.41 12.253C14.815 11.724 14.001 11 12.495 11C10.99 11 10.176 11.724 9.581 12.253C9.074 12.703 8.74 13 7.995 13C7.252 13 6.918 12.703 6.413 12.253C5.966 11.855 5.395 11.348 4.5 11.12V8C4.5 7.73478 4.60536 7.48043 4.79289 7.29289C4.98043 7.10536 5.23478 7 5.5 7ZM4.5 18V13.286C4.691 13.409 4.874 13.56 5.083 13.747C5.678 14.276 6.491 15 7.995 15C9.5 15 10.314 14.276 10.909 13.747C11.416 13.297 11.75 13 12.495 13C13.24 13 13.575 13.298 14.082 13.747C14.677 14.276 15.491 15 16.997 15C18.503 15 19.318 14.276 19.913 13.747C20.124 13.559 20.308 13.407 20.501 13.283L20.502 18H4.5Z"
            fill="#172C66"
          />
        </svg>
      </AddBirthday>

      <Swipeable
        state={drawerState}
        onClose={() => setDrawerState(false)}
        onOpen={() => setDrawerState(true)}
        title={t("share.sendTitle")!}
      >
        <a target="_blank" href={wsMessage} rel="noreferrer">
          <div className="flex flex-col items-center">
            <img width="36px" src={WS_LOGO} alt="whatsapp logo" />
            <span className="text-xs" style={{ marginTop: "8px" }}>
              {t("share.whatsapp")}
            </span>
          </div>
        </a>
        <a target="_blank" href={tMessage} rel="noreferrer">
          <div className="flex flex-col items-center">
            <img width="36px" src={T_LOGO} alt="telegram logo" />
            <span className="text-xs" style={{ marginTop: "8px" }}>
              Telegram
            </span>
          </div>
        </a>
      </Swipeable>
    </>
  );
};
