import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { Button } from "layout/components/Button/Button";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PhoneField } from "shared/components/Formik/PhoneField";
import { getMonthDaysOptions, getMonthOptions, MonthOptions } from "shared/utils";

import { MenuItem } from "@mui/material";

import { AddBirthdaySubscriptionFormType } from "./AddBirthdaySubscriptionFormType";
import { createInitialValues } from "./initialValues";
import { validation } from "./validation";

interface Props {
  onSubmit: (value: AddBirthdaySubscriptionFormType) => Promise<any>;
  initialValues?: AddBirthdaySubscriptionFormType;
  disableAlreadyFilledFields?: boolean;
  success?: boolean;
  monthOptions?: MonthOptions[];
  slug;
}

export function AddBirthdaySubscriptionForm({
  onSubmit,
  initialValues = createInitialValues(),
  success,
  disableAlreadyFilledFields,
  monthOptions = getMonthOptions,
  slug,
}: Props) {
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(initialValues.month);
  const [selectedDay, setSelectedDay] = useState(initialValues.day);
  const dayRef = useRef<HTMLInputElement | undefined>(undefined);

  return (
    <Formik<AddBirthdaySubscriptionFormType>
      initialValues={initialValues}
      validationSchema={validation(t)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, handleChange }) => (
        <Form className="flex w-full flex-col items-center gap-6" onSubmit={handleSubmit}>
          <div className="flex w-full flex-row items-start justify-between gap-3">
            <Field
              className={"basis-96"}
              name="month"
              label={t("user.setup.birthday.birthdayDate")}
              variant="standard"
              select
              component={TextField}
              fullWidth
              value={selectedMonth}
              autoFocus
              SelectProps={{
                onChange: (e) => {
                  setSelectedMonth(e.target.value);
                  setSelectedDay(1);
                  setFieldValue("day", "1");
                  handleChange(e);
                },
                onClose: () =>
                  setTimeout(() => {
                    dayRef.current?.focus();
                  }, 1),
              }}
            >
              {monthOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>

            <Field
              style={{ top: 16 }}
              name="day"
              value={selectedDay}
              variant="standard"
              select
              component={TextField}
              fullWidth
              SelectProps={{
                onChange: (e) => {
                  setSelectedDay(e.target.value);
                  handleChange(e);
                },
              }}
            >
              {getMonthDaysOptions(selectedMonth).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </div>

          <Field
            name="nickname"
            label={t("slug.share.addBirthDayGuest.form.name")}
            variant="standard"
            fullWidth
            disabled={disableAlreadyFilledFields && initialValues.nickname.length > 0}
            placeholder="Donna T"
            component={TextField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className="flex w-full flex-col gap-3">
            <Field
              name="email"
              label={t("slug.share.addBirthDayGuest.form.email")}
              variant="standard"
              fullWidth
              disabled={disableAlreadyFilledFields && initialValues.email.length > 0}
              placeholder="donna@email.com"
              component={TextField}
              inputProps={{
                autoCapitalize: "off",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
              {t("slug.share.addBirthDayGuest.form.emailDescription")}
            </span>
          </div>

          <div className="flex w-full flex-col gap-3">
            <Field
              name="phone"
              label={t("slug.share.addBirthDayGuest.form.phone")}
              variant="standard"
              disabled={
                disableAlreadyFilledFields && initialValues.phone !== undefined && initialValues.phone.length > 0
              }
              placeholder="+1 2324 4545"
              component={PhoneField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
              {t("slug.share.addBirthDayGuest.form.phoneDescription")}
            </span>
          </div>

          <div className="flex w-full flex-col gap-3">
            <Button
              type="submit"
              className="flex items-center justify-center"
              variant="solid"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              successAnimation={success}
            >
              {t("slug.share.addBirthDayGuest.form.action")}
            </Button>
            <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
              {t("slug.share.addBirthDayGuest.form.consent").replace(":SLUG", slug)}
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
}
