import { EventRepository } from "app/common/events/services/eventRepository";
import { InterestsRepository } from "app/common/events/services/interestsRepository";
import { useUser } from "app/common/user/hooks/useUser";
import { UserRepository } from "app/common/user/services/userRepository";
import { Route, Routes } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { SetupBirthdayPage } from "./birthday";
import { SetupInterestsPage } from "./interests";
import { SetupLinkExplanation } from "./link-explanation";
import { SetupReminderExplanation } from "./reminder-explanation";
import { SetupLinkPage } from "./root";
import { SetupShareLink } from "./share";

const repo = new UserRepository();
const eventRepository = new EventRepository();
const interestsRepository = new InterestsRepository();

export const SetupNavigation = () => {
  return (
    <Routes>
      {/** (*) route is part of [root] folder */}
      <Route path="*" element={<SetupLinkPage repo={repo} useUser={useUser} />} />
      <Route path="/link-explanation" element={<SetupLinkExplanation />} />
      <Route path="/reminder-explanation" element={<SetupReminderExplanation />} />
      <Route
        path="/birthday"
        element={<SetupBirthdayPage eventRepository={eventRepository} repo={repo} useAuth0={useAuth0} />}
      />
      <Route
        path="/interests"
        element={
          <SetupInterestsPage
            userRepository={repo}
            interestsRepository={interestsRepository}
            useUser={useUser}
            useAuth0={useAuth0}
          />
        }
      />
      <Route path="/share" element={<SetupShareLink repo={repo} useUser={useUser} />} />
    </Routes>
  );
};
