import { EventRepository } from "app/common/events/services/eventRepository";
import { UserRepository } from "app/common/user/services/userRepository";
import clouds_full from "assets/cloud_lg_share.png";
import clouds_mid from "assets/cloud_md_share.png";
import cloud_setup from "assets/cloud_slug.svg";
import red_cloud from "assets/footer_cloud1.svg";
import welcome_cloud from "assets/welcome_cloud.svg";
import { BigHeaderLayout } from "layout/components/BigHeaderLayout";
import { Button } from "layout/components/Button/Button";
import { Title } from "layout/components/Title";
import { debounceButtonAnimation } from "layout/hooks/useButtonAnimation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";

interface Props {
  slug: string;
  userRepo: UserRepository;
  eventRepo: EventRepository;
}

export const SlugSharePage: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const checkCanSubscribeMutation = useSuperQuery(
    props.eventRepo.keys.checkCanSubscribe(props.slug),
    () => props.eventRepo.checkCanSubscribe(props.slug),
    {
      showSuccessMessage: false,
      showErrorMessage: false,
    }
  );

  const eventMutation = useSuperMutation(props.eventRepo.createSubscription, {
    showSuccessMessage: false,
    showErrorMessage: false,
    onError: (e) => {
      if ((e as Error).message === t("slug.share.already")) navigate("../already-added");
    },
    onSuccess: () => {
      if (checkCanSubscribeMutation.data) {
        debounceButtonAnimation(() => navigate(`/${props.slug}/me/add-birthday`));
      } else {
        debounceButtonAnimation(() => navigate(`/main/events/display`));
      }
    },
  });

  async function handleShareBirthday() {
    await shareBirthday(props.slug);
  }

  async function shareBirthday(slug: string) {
    await eventMutation.mutateAsync(slug);
  }

  return (
    <BigHeaderLayout
      headerCloud={<img src={cloud_setup} alt="cloud" className="z-0 mt-4 flex h-6 self-start" />}
      small
      fullWidth
      headerBgColor={"bg-brand-secondary"}
      bgColor={"bg-brand-primary"}
      separator={
        <>
          <div className="relative h-[60px] w-full bg-brand-secondary tablet:hidden">
            <img src={red_cloud} alt="cloud separator" className="absolute -bottom-2 w-full object-cover" />
            <img src={welcome_cloud} alt="cloud separator" className="absolute -bottom-1 w-full object-cover " />
          </div>
          <div className="relative hidden h-[84px] w-full bg-brand-secondary tablet:block">
            {/* Full width cloud separators */}
            <img
              src={clouds_full}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover xl:block"
            />

            {/* Mid width cloud separators */}
            <img
              src={clouds_mid}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover tablet:block xl:hidden"
            />
          </div>
        </>
      }
      footer={
        <div className="flex w-full flex-col items-center gap-3 pl-6 pr-6">
          <Button
            variant="solid"
            className="flex items-center justify-center"
            isLoading={eventMutation.isLoading}
            disabled={eventMutation.isLoading}
            onClick={handleShareBirthday}
            successAnimation={eventMutation.isSuccess}
          >
            {t("slug.share.share")}
          </Button>

          <Button
            variant="outline"
            className="flex items-center justify-center"
            disabled={eventMutation.isLoading}
            onClick={() => {
              navigate("/main/events/display");
            }}
          >
            {t("slug.share.cancel")}
          </Button>
        </div>
      }
    >
      <div className="mx-auto mt-8 flex max-w-xl flex-col gap-10 bg-brand-primary px-7 pb-8">
        <Title variant="small">{t("slug.share.title").replace(":SLUG", props.slug)}</Title>
      </div>
    </BigHeaderLayout>
  );
};
