import { useUser } from "app/common/user/hooks/useUser";
import { AddBirthdayPage } from "app/pages/main/events/add-birthday";
import { BirthdayDetailsPage } from "app/pages/main/events/birthday-details";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { LocalStorage } from "shared/services";

import { EventRepository } from "../../../common/events/services/eventRepository";
import { DisplayEventPage } from "./display";

const repo = new EventRepository();

export const EventsNavigation = () => {
  return (
    <Routes>
      <Route
        path="display"
        element={
          <DisplayEventPage
            repo={repo}
            useUser={useUser}
            useSearchParams={useSearchParams}
            localStorage={LocalStorage}
          />
        }
      />
      <Route path="add-birthday" element={<AddBirthdayPage repo={repo} />} />
      <Route
        path="birthday-details"
        element={<BirthdayDetailsPage localStorage={LocalStorage} repo={repo} useSearchParams={useSearchParams} />}
      />
    </Routes>
  );
};
