import { FC, ReactNode } from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

interface Props {
  children: ReactNode;
  content: ReactNode;
  open: boolean;
  defaultOpen?: boolean;
  onOpenChange: (open: boolean) => void;
  [x: string]: any;
}

export const Tooltip: FC<Props> = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  ...props
}) => {
  return (
    <TooltipPrimitive.Root open={open} onOpenChange={onOpenChange}>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal container={document.getElementById("root")}>
        <TooltipPrimitive.Content {...props}>
          <TooltipPrimitive.Arrow className="fill-button" />
          {content}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};
