import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { resources } from "./i18n/resources";

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources,
    detection: {
      // Review configuration for better detection
      // order: ["navigator"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
