import { FieldProps } from 'formik';
import React, { ComponentProps } from 'react';

import { TextField } from '@mui/material';

export function PhoneField(
  props: ComponentProps<typeof TextField> & FieldProps
) {
  const { field, form } = props;
  const { onChange, ...otherProps } = props;

  function validateCharacter(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const pattern = /^[\d +]*$/;
    const isValid = pattern.test(value);
    if (isValid) {
      form.setFieldValue(field.name, value);
    }
  }

  return (
    <TextField
      {...otherProps}
      name={otherProps.field.name}
      value={field.value}
      error={!!form.errors[field.name] && !!form.touched[field.name]}
      helperText={
        !!form.errors[field.name] && form.touched[field.name]
          ? form.errors[field.name]
          : undefined
      }
      onChange={validateCharacter}
    />
  );
}
