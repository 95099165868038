interface Props {
  children: React.ReactNode;
  variant: "pastday" | "today" | "futureday" | "invalid";
  selected?: boolean;
  eventThisDay?: boolean;
  onClick?: () => void;
  //rest of props
  [x: string]: any;
}

export const CalendarButton: React.FC<Props> = ({
  children,
  selected,
  eventThisDay,
  variant,
  onClick,
  restOfProps,
}) => {
  const today = variant === "today";
  const pastDay = variant === "pastday";
  const invalid = variant === "invalid";

  const variantClass = pastDay ? "rounded" : today ? "rounded-full" : "rounded";
  const selectedClass = selected ? "border-button" : "border-hidden";
  const eventClass = eventThisDay
    ? pastDay
      ? "bg-brand-gray/40 text-white"
      : `${selected ? "bg-button/50" : "bg-button"} text-white`
    : today
    ? "text-button bg-white"
    : pastDay
    ? "bg-white/40"
    : "bg-white";

  return (
    <button
      disabled={invalid}
      {...restOfProps}
      onClick={onClick}
      className={
        !invalid
          ? `${variantClass} ${selectedClass} ${eventClass} h-9 w-9 border-2 text-center text-xs font-medium leading-4 xs:h-11 xs:w-11`
          : "bg-brand-secondary"
      }
    >
      {!invalid && children}
    </button>
  );
};
