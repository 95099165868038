export const slugShareGuestPage = {
  en: {
    "slug.guest.birthdaySignupLabel": "Add Birthday & sign up",
    "slug.guest.birthdayLabel": "Add my birthday",
    "slug.guest.birthdayFasterLabel": "Already have an account?",
    "slug.guest.loginLabel": "Log in",
    "slug.guest.title": "Hey! :SLUG wants to add you to their birthday calendar.",
  },
  es: {
    "slug.guest.birthdaySignupLabel": "Añadir cumpleaños y registrarme",
    "slug.guest.birthdayLabel": "Añadir cumpleaños",
    "slug.guest.birthdayFasterLabel": "¿Ya tienes una cuenta?",
    "slug.guest.loginLabel": "Inicia Sesión",
    "slug.guest.title": "¡Ey! :SLUG quiere agregarte a su calendario de cumpleaños.",
  },
};
