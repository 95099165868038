import React, { FC } from 'react';

import { Dialog } from '@mui/material';

import { Button } from './Button/Button';

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  accept: () => any;
  deny: () => any;
  title?: React.ReactNode | string;
  body?: React.ReactNode | string;
}

export const WarningDialog: FC<Props> = ({
  isVisible,
  handleClose,
  accept,
  deny,
  title,
  body,
}) => {
  return (
    <div>
      <Dialog open={isVisible} keepMounted onClose={handleClose} fullWidth>
        <div className="my-3.5 flex flex-col gap-4">
          <div className="text-text-title px-4 text-lg font-medium leading-4 tracking-wide">
            {title}
          </div>
          <div className="text-text-paragraph mt-1 px-4 text-sm font-normal tracking-wider">
            {body}
          </div>
        </div>
        <div className="flex w-3/4 flex-row gap-2 self-end p-4">
          <Button onClick={deny} sizes="sm" variant="outline">
            CANCEL
          </Button>
          <Button onClick={accept} sizes="sm" variant="solid">
            ACCEPT
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
