import Cloud from 'assets/cloud.svg';
import { Title } from 'layout/components/Title';
import React from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
  titleVariant?: "normal" | "small";
}

export const SetupLayout: React.FC<Props> = ({ title, titleVariant="normal", children }) => {
  return (
    <div className="flex w-full flex-col items-center gap-10">
      <div className="relative flex w-full flex-col items-center justify-center">
        <img
          src={Cloud}
          alt="cloud"
          className="absolute top-10 right-0 z-0 h-10"
        />
        <Title variant={titleVariant}>{title}</Title>
      </div>
      {children}
    </div>
  );
};
