export const setupBirthdayPage = {
  en: {
    "user.setup.birthday.title": "Let's get you started!",
    "user.setup.birthday.birthdayDate": "Birthday date",
    "user.setup.birthday.nicknameLabel": "Name",
    "user.setup.birthday.phoneLabel": "Mobile number (optional)",
    "user.setup.birthday.phoneHint":
      "No worries, we'll just use this number to give you a friendly heads up about any upcoming birthdays.",
    "user.setup.birthday.action": "Continue",
    "user.setup.birthday.validation#1": "Birthday month is required",
    "user.setup.birthday.validation#2": "Birthday day is required",
    "user.setup.birthday.validation#3": "Birthdate is not valid",
    "user.setup.birthday.validation#4": "Your name is required",
  },
  es: {
    "user.setup.birthday.title": "¡Vamos a comenzar!",
    "user.setup.birthday.birthdayDate": "Fecha de cumpleaños",
    "user.setup.birthday.nicknameLabel": "Nombre",
    "user.setup.birthday.phoneLabel": "Número de móvil (opcional)",
    "user.setup.birthday.phoneHint":
      "No se preocupe, solo usaremos este número para darle un aviso amistoso sobre los próximos cumpleaños.",
    "user.setup.birthday.action": "Continuar",
    "user.setup.birthday.validation#1": "Se requiere mes de cumpleaños",
    "user.setup.birthday.validation#2": "Se requiere el día del cumpleaños",
    "user.setup.birthday.validation#3": "La fecha de nacimiento no es válida",
    "user.setup.birthday.validation#4": "Tu nombre es requerido",
  },
};
