import axios from "axios";
import { environment } from "environment/environment";

export const tenorRepository = () => {
  return {
    getGif: async () => {
      const { tenor } = environment;
      const query = encodeURIComponent("happy birthday funny");
      const params = new URLSearchParams({
        q: query,
        key: tenor.key,
        client_key: tenor.client,
        limit: "1",
        media_filter: "gif",
        random: "true",
        contentfilter: "high",
        locale: navigator.language,
      });

      return await axios
        .get(`https://tenor.googleapis.com/v2/search?${params.toString()}`)
        // gettng gif URL
        .then((response) => {
          const results = response.data.results;

          if (results && results.length > 0) {
            return response.data.results[0].media_formats.gif.url;
          }

          return null;
        })
        // reading gif as blob
        .then((url) => {
          if (url) {
            return axios({
              url,
              method: "GET",
              responseType: "blob",
            });
          }

          return null;
        })
        // converting blob to file
        .then((response) => {
          if (response) {
            const { data } = response;
            const file = new File([data], "unforget.gif", {
              type: data.type,
              lastModified: new Date().getTime(),
            });
            return [file];
          }

          return [];
        })
        .catch(() => {
          return [];
        });
    },
  };
};
