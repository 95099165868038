import Cloud1 from "assets/footer_cloud1.svg";
import Cloud2 from "assets/footer_cloud2.svg";
import { Header } from "layout/components";
import { Sidebar, SideBarStatus } from "layout/components/";
import React, { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useResponsiveBreakpoint } from "shared/hooks/useResponsiveBreakpoint";

interface Props {
  child?: boolean;
  toolbar?: React.ReactNode;
  navigateBackTo?: string;
  setup?: boolean;
  auth?: boolean;
  footer?: boolean;
  slug?: boolean;
  hideProfile?: boolean;
  footerComponent?: React.ReactNode;
}

export const PageContainer: React.FC<Props> = ({
  child = false,
  children,
  toolbar,
  navigateBackTo,
  setup,
  auth,
  footer,
  slug,
  hideProfile,
  footerComponent,
}) => {
  const navigate = useNavigate();
  const canGoBack = window.history.length > 1;
  const bgClass = setup ? "bg-brand-accent-default" : "bg-brand-primary";

  return (
    <MainLayoutContext.Consumer>
      {({ dismissVisibleSidebar, setSidebarStatus }) => (
        <div
          className={`${bgClass} relative flex h-full w-full flex-col overflow-auto`}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={dismissVisibleSidebar}
        >
          <Header
            toggleSidebar={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setSidebarStatus({ platform: "mobile", status: "open" });
              e.stopPropagation();
            }}
            navigateBack={() => {
              if (navigateBackTo) {
                navigate(navigateBackTo);
                return;
              }
              navigate(-1);
            }}
            child={child && canGoBack}
            toolbar={toolbar}
            setup={setup}
            auth={auth}
            slug={slug}
            hideProfile={hideProfile}
          />
          <div
            style={{
              flex: "1 1 auto",
              overflowY: "auto",
              marginBottom: 13,
            }}
          >
            {children}
          </div>
          <footer
            className="mx-auto w-full max-w-xl"
            style={{ display: "flex", flex: "0 0 auto", flexDirection: "column" }}
          >
            {footerComponent && <>{footerComponent}</>}
            {footer && (
              <>
                <div className="relative">
                  <img
                    src={Cloud1}
                    alt="footer cloud"
                    className="absolute inset-x-0 bottom-0 z-0 w-full object-cover sm:hidden"
                  />
                  <img
                    src={Cloud2}
                    alt="footer cloud"
                    className="z-1 absolute inset-x-0 bottom-0 w-full object-cover sm:hidden"
                  />
                </div>
              </>
            )}
          </footer>
        </div>
      )}
    </MainLayoutContext.Consumer>
  );
};

type MainLayoutContextType = {
  dismissVisibleSidebar(): void;
  setSidebarStatus(args: SideBarStatus): void;
};

const MainLayoutContext = createContext({} as MainLayoutContextType);

export const MainLayout = () => {
  const breakpoint = useResponsiveBreakpoint();
  const [sidebarStatus, setSidebarStatus] = useState<SideBarStatus>({
    platform: "desktop",
    status: "expanded",
  });

  useEffect(() => {
    if (breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md") {
      setSidebarStatus((status) => {
        if (status.platform === "desktop") return { platform: "mobile", status: "closed" };
        return status;
      });
      return;
    }
    setSidebarStatus((status) => {
      if (status.platform === "mobile") return { platform: "desktop", status: "expanded" };
      return status;
    });
  }, [breakpoint]);

  function dismissVisibleSidebar() {
    if (sidebarStatus.platform === "mobile") {
      setSidebarStatus({ platform: "mobile", status: "closed" });
    }
  }

  function toggleCollapse() {
    if ((sidebarStatus.platform = "desktop")) {
      setSidebarStatus((status) =>
        status.status === "expanded"
          ? { platform: "desktop", status: "collapsed" }
          : { platform: "desktop", status: "expanded" }
      );
    }
  }

  return (
    <MainLayoutContext.Provider value={{ dismissVisibleSidebar, setSidebarStatus }}>
      <div className="flex h-full min-h-full">
        <Sidebar
          status={sidebarStatus}
          collapsable
          toggleCollapse={sidebarStatus.platform === "mobile" ? dismissVisibleSidebar : toggleCollapse}
        />

        <div className="relative min-h-full w-full overflow-auto" onClick={dismissVisibleSidebar}>
          <Outlet />
          {sidebarStatus.platform === "mobile" && sidebarStatus.status === "open" ? (
            <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-black opacity-20"></div>
          ) : null}
        </div>
      </div>
    </MainLayoutContext.Provider>
  );
};
