import { User } from "app/common/auth/models";
import { InterestsRepository } from "app/common/events/services/interestsRepository";
import { Loading } from "app/common/main/pages/Loading";
import { UserRepository } from "app/common/user/services/userRepository";
import ILLUSTRATION from "assets/interests.png";
import { BigHeaderLayout } from "layout/components/BigHeaderLayout";
import { Button } from "layout/components/Button/Button";
import { Title } from "layout/components/Title";
import { debounceButtonAnimation } from "layout/hooks/useButtonAnimation";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Chip } from "@mui/material";
import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";

export const SetupInterestsPage = ({
  interestsRepository,
  userRepository,
  useUser,
  useAuth0,
}: {
  interestsRepository: InterestsRepository;
  userRepository: UserRepository;
  useUser: any;
  useAuth0: any;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user: Auth0_user, isLoading } = useAuth0();
  const { user, isLoadingUser } = useUser() as any;
  const [categories, setCategories] = useState([] as any);

  const interestsQuery = useSuperQuery(interestsRepository.keys.all(), () => interestsRepository.getAll(), {
    showErrorMessage: false,
    onSuccess: (data) => {
      setCategories(
        data.map((interest) => {
          interest.selected = false;
          interest.disabled = false;

          return interest;
        })
      );
    },
  });

  const interestsMutation = useSuperMutation(userRepository.updateProfile, {
    showErrorMessage: false,
    showSuccessMessage: false,
    onSuccess: async () => {
      debounceButtonAnimation(() => navigate("/user/setup/link-explanation"));
    },
  });

  if (interestsQuery.isLoading || isLoadingUser || isLoading) {
    return <Loading />;
  }

  const birthday = moment(user.profile.birthdate ?? "");
  const day = parseInt(birthday.format("DD"), 10);
  const month = birthday.format("MM");
  const initialValues = {
    day: day,
    month: month,
    nickname: user.profile.nickname,
    phone: user.profile.phone ?? "",
    slug: user.profile.slug,
    interests: user.profile.interests,
  };

  async function updateUser() {
    const values = initialValues;

    const userProfile: User = {
      ...user,
      profile: {
        ...user.profile,
        slug: values.slug,
      },
    };

    await interestsMutation.mutateAsync({
      user: userProfile as any,
      ...values,
      day,
      month,
      email: Auth0_user?.email,
      interests: categories.filter((category) => category.selected === true).map((i) => i.id),
    });
  }

  function handleNext() {
    updateUser();
  }

  const selecteCounter = () => {
    return categories.filter((category) => category.selected === true).length;
  };

  const handleClick = (idx: number) => () => {
    categories[idx].selected = !categories[idx].selected;

    const selected = selecteCounter();

    categories.forEach((category) => {
      category.disabled = false;
    });

    if (selected === 3) {
      categories
        .filter((category) => category.selected === false)
        .forEach((category) => {
          category.disabled = true;
        });
    }

    setCategories(JSON.parse(JSON.stringify(categories)));
  };

  return (
    <>
      <BigHeaderLayout
        headerBgColor="bg-brand-primary"
        bgColor="bg-brand-primary"
        small={false}
        fullWidth
        headerFullHeight
        footerPadding
        bodyFullHeight
        titleNode={
          <div>
            <img src={ILLUSTRATION} alt="ilustration" className="m-auto h-[169px] object-contain" />
            <div className="mx-auto flex w-full max-w-xl items-center text-center">
              <Title variant="small">{t("user.setup.interests.title")!}</Title>
            </div>
          </div>
        }
        footer={
          <Button
            id="unforget-submit"
            className="ml-6 mr-6 w-full"
            type="submit"
            variant="solid"
            onClick={handleNext}
            disabled={selecteCounter() === 0 || interestsMutation.isLoading}
            isLoading={interestsMutation.isLoading}
            successAnimation={interestsMutation.isSuccess}
          >
            {t("user.setup.birthday.action")}
          </Button>
        }
      >
        <div className="mx-auto flex max-h-full w-full max-w-xl flex-col items-center px-7 pt-2 text-center">
          <h3 style={{ fontSize: "14px" }} className="track mb-8 w-[300px] font-light leading-5 text-text-paragraph">
            {t("user.setup.interests.paragraph")}
          </h3>
          <div className="scroll flex w-full flex-row flex-wrap gap-2">
            {categories.map((category, idx) => (
              <Chip
                key={category.id}
                label={category.name}
                variant={category.selected ? "filled" : "outlined"}
                onClick={handleClick(idx)}
                disabled={category.disabled}
              />
            ))}
          </div>
        </div>
      </BigHeaderLayout>
    </>
  );
};
