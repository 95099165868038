import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { Button } from "layout/components/Button/Button";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PhoneField } from "shared/components/Formik/PhoneField";
import { getMonthDaysOptions, getMonthOptions, MonthOptions } from "shared/utils";

import { MenuItem } from "@mui/material";

import { BirthdayDetailsFormType } from "./BirthdayDetailsFormType";
import { createInitialValues } from "./initialValues";
import { validation } from "./validation";

interface Props {
  onSubmit: (value: BirthdayDetailsFormType, formikHelpers: FormikHelpers<BirthdayDetailsFormType>) => Promise<any>;
  initialValues: BirthdayDetailsFormType;
  monthOptions?: MonthOptions[];
  success?: boolean;
  isEventLinked?: boolean;
}

export function BirthdayDetailsForm({
  onSubmit,
  initialValues = createInitialValues(),
  monthOptions = getMonthOptions,
  success,
  isEventLinked,
}: Props) {
  const [selectedMonth, setSelectedMonth] = useState(initialValues.month);
  const [selectedDay, setSelectedDay] = useState(initialValues.day);
  const { t } = useTranslation();
  const dayRef = useRef<HTMLInputElement | undefined>(undefined);

  return (
    <Formik<BirthdayDetailsFormType> initialValues={initialValues} validationSchema={validation(t)} onSubmit={onSubmit}>
      {(formik) => (
        <Form
          className={`${formik.dirty ? "mb-3" : "mb-8"} flex w-full flex-col items-center gap-6`}
          onSubmit={formik.handleSubmit}
        >
          <div className="flex w-full flex-row items-start justify-between gap-3">
            <Field
              className={"basis-96"}
              name="month"
              label={t("user.setup.birthday.birthdayDate")}
              variant="standard"
              select
              component={TextField}
              fullWidth
              value={selectedMonth}
              autoFocus
              disabled={isEventLinked}
              SelectProps={{
                onChange: (e) => {
                  setSelectedMonth(e.target.value);
                  setSelectedDay(1);
                  formik.setFieldValue("day", "1");
                  formik.handleChange(e);
                },
                onClose: () =>
                  setTimeout(() => {
                    dayRef.current?.focus();
                  }, 1),
              }}
            >
              {monthOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>

            <Field
              style={{ top: 16 }}
              name="day"
              value={selectedDay}
              variant="standard"
              select
              component={TextField}
              fullWidth
              disabled={isEventLinked}
              SelectProps={{
                onChange: (e) => {
                  setSelectedDay(e.target.value);
                  formik.handleChange(e);
                },
              }}
            >
              {getMonthDaysOptions(selectedMonth).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </div>

          <Field
            name="name"
            label={t("user.settings.form.your-name")}
            variant="standard"
            fullWidth
            placeholder="Donna T"
            component={TextField}
            disabled={isEventLinked}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <div className="flex w-full flex-col gap-3">
            <Field
              name="email"
              label={t("user.settings.form.email")}
              variant="standard"
              fullWidth
              placeholder="donna@email.com"
              component={TextField}
              disabled={isEventLinked}
              inputProps={{
                autoCapitalize: "off",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
              {t("user.settings.form.emailHint")}
            </span>
          </div>

          <div className="flex flex-col gap-2">
            <Field
              name="phone"
              label={t("user.settings.form.phone")}
              variant="standard"
              fullWidth
              placeholder="+1 2324 4545"
              component={PhoneField}
              disabled={isEventLinked}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
              {t("user.setup.birthday.phoneHint")}
            </span>
          </div>

          {formik.dirty && (
            <Button
              variant="solid"
              className="mt-2 flex items-center justify-center"
              onClick={() => {
                formik.submitForm();
              }}
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
              successAnimation={success}
              enterAnimation={formik.dirty}
            >
              {t("events.birthdayDetails.saveLabel")}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
}
