import moment from 'moment';
import * as Yup from 'yup';

import { BirthdayDetailsFormType } from './BirthdayDetailsFormType';

export const validation = (t): Yup.SchemaOf<BirthdayDetailsFormType> => Yup.object().shape({
  month: Yup.string().required(t("globals.validations.month.required")),
  day: Yup.number()
    .required(t("globals.validations.birthday.required"))
    .test("validBirthdate", t("globals.validations.birthday.valid"), (value, context) => {
      const { month } = context.parent;
      if (value === undefined) return false;

      //since moment consider 1 invalid but 01 valid
      const momentCero = value < 10 ? "0" : "";

      const birthday = moment(
        `${momentCero}${value}-${month}`,
        "DD-MM",
        true
      );
      const isValidDate = birthday.isValid();
      if (month === "02" && value === 29) return true; //accept 29 of February
      return isValidDate;
    }) as any,
  name: Yup.string()
    .min(3, t("globals.validations.nickname.length"))
    .required(t("globals.validations.nickname.required")),
  email: Yup.string().email(),
});
