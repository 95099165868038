import { Event } from "app/common/events/models/Event";
import moment, { Moment } from "moment";
import { httpClient } from "shared/services/httpClient";

import { AddBirthdayFormType } from "../forms/addBirthdayForm/AddBirthdayFormType";

export const getEventMapper = (x: any): Event => new Event(x);

export interface ICreateEvent {
  date: Moment;
  nickname: string;
  email: string;
  phone: string | null;
  type: string;
}

export interface ICreateSubscription {
  subscriptor_slug: string;
  day: number;
  month: string;
  nickname: string;
  email: string;
  phone: string | undefined;
}

export const createEventMapper = (x: Omit<AddBirthdayFormType, "type">) => ({
  date: moment(`${x.day}/${x.month}`, "DD/MM").format("YYYY-MM-DD"),
  nickname: x.nickname,
  email: (x.email || "").trim(),
  phone: x.phone,
  type: "birthday",
});

export const createSubscriptionMapper = (x: ICreateSubscription) => ({
  ...x,
  date: moment(`${x.day}/${x.month}`, "DD/MM").format("YYYY-MM-DD"),
  phone: x.phone ?? null,
  type: "birthday",
});

export const getCanSubMapper = (x: any) => x === 1;

export interface IUpdateEvent {
  eventId: string;
  day: number;
  month: string;
  email: string | undefined;
  name: string;
  phone: string | undefined;
}

export const updateEventMapper = (x: IUpdateEvent) => {
  return {
    date: moment(`${x.day}/${x.month}`, "DD/MM").format("YYYY-MM-DD"),
    email: (x.email || "").trim(),
    nickname: x.name,
    phone: x.phone,
  };
};

export class EventRepository {
  keys = {
    all: () => ["event"],
    one: (id: string) => ["event", id],
    calendarEvents: (from: string, to: string) => ["calendarEvents", from, to],
    upcomingEvents: () => ["upcomingEvents"],
    pastEvents: () => ["pastEvents"],
    todayEvents: (from: string) => ["todayEvents", from],
    canSubscribe: (id: string) => ["event-can-subscribe", id],
    checkCanSubscribe: (id: string) => ["event-check-can-subscribe", id],
  };

  getAll = async () => {
    const res = await httpClient.get("events");
    return res.map(getEventMapper);
  };

  get = async (id: string) => {
    const res = await httpClient.get(`events/${id}`);
    return getEventMapper(res);
  };

  getEventsOnInterval = async (from: string, to: string) => {
    const res = await httpClient.get(`users/me/events?from=${from}&to=${to}`);
    return res.map(getEventMapper) as Event[];
  };

  getUpcomingEvents = async () => {
    const res = await httpClient.get("users/me/events?upcoming=1");
    return res.map(getEventMapper) as Event[];
  };

  getPastEvents = async () => {
    const res = await httpClient.get("users/me/events?past=1");
    return res.map(getEventMapper) as Event[];
  };

  getTodayEvents = async (from: string) => {
    const res = await httpClient.get(`users/me/events?from=${from}&to=${from}`);
    return res.map(getEventMapper) as Event[];
  };

  getEventById = async (id: string) => {
    const res = await httpClient.get(`events/${id}`);
    return getEventMapper(res);
  };

  create = async (x: Omit<AddBirthdayFormType, "type">) => httpClient.post("own-events", createEventMapper(x));

  update = async (x: IUpdateEvent) => httpClient.put(`events/${x.eventId}`, updateEventMapper(x));

  delete = async (id: string) => httpClient.delete(`events/${id}`);

  //without date
  createEventFromUser = async (x: ICreateSubscription) =>
    httpClient.post("events-from-user", createSubscriptionMapper(x));

  /** Register the birthday to an slug for GUESTS */
  /**
   * {"day":1,"month":"01","nickname":"done","email":"centurion.javier@gmail.com","phone":"","subscriptor_slug":"unforget97","date":"2023-01-01","type":"birthday"}
   */
  createEventFromGuest = async (x: ICreateSubscription) =>
    httpClient.post("events-from-guest", createSubscriptionMapper(x));

  /** Register the birthday to an slug for LoggedIn users */
  /** /api/events/me/subscribe/unforget97 */
  createSubscription = async (x: string) => httpClient.post(`events/me/subscribe/${encodeURIComponent(x)}`);

  /** Register the birthday to an slug for LoggedIn users */
  /** /api/events/subscribe/unforget97 */
  addSubscription = async (x: string) => httpClient.post(`events/subscribe/${encodeURIComponent(x)}`);

  getCanSubscribe = async (x: string) => {
    const res = await httpClient.get(`events/me/can-subscribe/${encodeURIComponent(x)}`);
    return getCanSubMapper(res);
  };

  checkCanSubscribe = async (x: string) => {
    const res = await httpClient.get(`events/can-subscribe/${encodeURIComponent(x)}`);
    return getCanSubMapper(res);
  };

  sendGreetings = async (eventId: number) => httpClient.post(`event/${eventId}/send-greetings`);
}
