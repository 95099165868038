import { getBaseUrl } from "app/common/helpers";
import { UserRepository } from "app/common/user/services/userRepository";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { Button } from "layout/components/Button/Button";
import { PartyIcon } from "layout/components/Icons/PartyIcon";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PhoneField } from "shared/components/Formik/PhoneField";
import { getMonthDaysOptions, getMonthOptions, MonthOptions } from "shared/utils";

import styled from "@emotion/styled";
import { Chip, CircularProgress, IconButton, InputAdornment, InputLabel, MenuItem } from "@mui/material";
import { useSuperMutation } from "@vadiun/react-hooks";

import { createInitialValues } from "./initialValues";
import { SettingsFormType } from "./SettingsFormType";
import { validation } from "./validation";

interface Props {
  onSubmit: (value: SettingsFormType, formikHelpers: FormikHelpers<SettingsFormType>) => Promise<any>;
  initialValues: SettingsFormType;
  monthOptions?: MonthOptions[];
  repo: UserRepository;
  onInterestsChange?: any;
}

const FormActions = styled.div`
  width: 100%;
  position: fixed;
  background: #fef6e4;
  box-shadow: 0px -4px 20px rgb(24 22 20 / 14%);
  margin-right: -1.25rem;
  padding: 1.25rem;
  bottom: 0;
  z-index: 1;
  margin-left: -1.25rem;
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`;

export function SettingsForm({
  onSubmit,
  initialValues = createInitialValues(),
  monthOptions = getMonthOptions,
  repo,
  onInterestsChange,
}: Props) {
  const [selectedMonth, setSelectedMonth] = useState(initialValues.month);
  const [selectedDay, setSelectedDay] = useState(initialValues.day);
  const { t } = useTranslation();
  const dayRef = useRef<HTMLInputElement | undefined>(undefined);

  const slugMutation = useSuperMutation(repo.getSlug, {
    showSuccessMessage: false,
  });

  const randomSlugMutation = useSuperMutation(repo.getUniqueSlug, {
    showSuccessMessage: false,
  });

  const selectedInterests = initialValues.interests;

  return (
    <Formik<SettingsFormType> initialValues={initialValues} validationSchema={validation(t)} onSubmit={onSubmit}>
      {(formik) => (
        <>
          <Form className="relative mb-8 flex w-full flex-col gap-6" onSubmit={formik.handleSubmit}>
            <div className="flex w-full flex-row items-start justify-between gap-3">
              <Field
                className={"basis-96"}
                name="month"
                label={t("user.setup.birthday.birthdayDate")}
                variant="standard"
                select
                component={TextField}
                fullWidth
                value={selectedMonth}
                autoFocus
                SelectProps={{
                  onChange: (e) => {
                    setSelectedMonth(e.target.value);
                    setSelectedDay(1);
                    formik.setFieldValue("day", "1");
                    formik.handleChange(e);
                  },
                  onClose: () =>
                    setTimeout(() => {
                      dayRef.current?.focus();
                    }, 1),
                }}
              >
                {monthOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>

              <Field
                style={{ top: 16 }}
                name="day"
                value={selectedDay}
                variant="standard"
                select
                component={TextField}
                fullWidth
                SelectProps={{
                  onChange: (e) => {
                    setSelectedDay(e.target.value);
                    formik.handleChange(e);
                  },
                }}
              >
                {getMonthDaysOptions(selectedMonth).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            </div>

            <Field
              name="nickname"
              label={t("user.settings.form.your-name")}
              variant="standard"
              fullWidth
              placeholder="Donna T"
              component={TextField}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <div className="flex flex-col gap-2">
              <Field
                name="slug"
                label={t("user.settings.form.slugLabel")}
                variant="standard"
                fullWidth
                placeholder="happysnake"
                component={TextField}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="get random slug"
                        onClick={async () => {
                          const randomSlug = await randomSlugMutation.mutateAsync();
                          formik.setFieldValue("slug", randomSlug.slug, true);
                        }}
                        edge="end"
                        className="group"
                      >
                        {randomSlugMutation.isLoading ? <CircularProgress size={20} /> : <PartyIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
                {getBaseUrl()}/{initialValues.slug}
              </span>
            </div>

            <div className="flex flex-col gap-2">
              <Field
                name="phone"
                label={t("user.settings.form.phone")}
                variant="standard"
                fullWidth
                placeholder="+1 2324 4545"
                component={PhoneField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
                {t("user.setup.birthday.phoneHint")}
              </span>
            </div>

            <div className="flex flex-col gap-2">
              <Field
                name="interests"
                fullWidth
                component={() => {
                  return (
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row justify-between">
                        <InputLabel style={{ fontSize: "14px" }} variant="standard">
                          {t("user.settings.form.interests")}
                        </InputLabel>
                        <div role="button" onClick={onInterestsChange}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.16675 10.8334L14.0501 10.8334L11.0251 14.4668C10.8836 14.6369 10.8156 14.8563 10.8359 15.0767C10.8562 15.2971 10.9632 15.5003 11.1334 15.6418C11.3036 15.7832 11.523 15.8513 11.7434 15.8309C11.9637 15.8106 12.167 15.7036 12.3084 15.5334L16.4751 10.5334C16.5031 10.4937 16.5282 10.4519 16.5501 10.4084C16.5501 10.3668 16.5501 10.3418 16.6084 10.3001C16.6462 10.2045 16.666 10.1028 16.6667 10.0001C16.666 9.89736 16.6462 9.79565 16.6084 9.7001C16.6084 9.65843 16.6084 9.63343 16.5501 9.59176C16.5282 9.54831 16.5031 9.50653 16.4751 9.46676L12.3084 4.46676C12.2301 4.37269 12.1319 4.29704 12.021 4.24519C11.9101 4.19334 11.7892 4.16656 11.6667 4.16676C11.472 4.16638 11.2833 4.2342 11.1334 4.35843C11.049 4.42839 10.9793 4.51431 10.9282 4.61126C10.877 4.70822 10.8455 4.81431 10.8355 4.92345C10.8254 5.0326 10.837 5.14266 10.8695 5.24733C10.902 5.352 10.9549 5.44922 11.0251 5.53343L14.0501 9.16676L4.16675 9.16676C3.94573 9.16676 3.73377 9.25456 3.57749 9.41084C3.42121 9.56712 3.33341 9.77908 3.33341 10.0001C3.33341 10.2211 3.42121 10.4331 3.57749 10.5894C3.73377 10.7456 3.94573 10.8334 4.16675 10.8334Z"
                              fill="#405180"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="scroll flex w-full flex-row flex-wrap gap-2">
                        {selectedInterests.map((category) => (
                          <Chip key={category.id} label={category.name} variant={"outlined"} disabled={true} />
                        ))}

                        {selectedInterests.length === 0 && (
                          <span className="text-xs font-bold leading-4 tracking-semiwide text-text-paragraph">
                            {t("user.settings.noInterests")}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
                {t("user.setup.birthday.interestsHint")}
              </span>
            </div>

            {formik.dirty && (
              <FormActions className="flex gap-2.5">
                <Button
                  variant="outline"
                  className="mt-2 flex items-center justify-center"
                  disabled={slugMutation.isLoading}
                  onClick={async () => {
                    formik.resetForm();
                  }}
                >
                  {t("user.settings.form.cancelLabel")}
                </Button>
                <Button
                  variant="solid"
                  className="mt-2 flex items-center justify-center"
                  disabled={slugMutation.isLoading}
                  onClick={async () => {
                    if (formik.errors.slug) return;
                    const validation = await slugMutation.mutateAsync(formik.values.slug);
                    if (!validation.validated && formik.values.slug !== initialValues.slug) {
                      formik.setFieldError("slug", t("user.settings.form.slug")!);
                      return;
                    }
                    formik.submitForm();
                  }}
                  isLoading={(slugMutation.isLoading && formik.errors.slug === undefined) || formik.isSubmitting}
                >
                  {t("user.settings.form.saveLabel")}
                </Button>
              </FormActions>
            )}
          </Form>
        </>
      )}
    </Formik>
  );
}
