import { useAuthService } from "app/common/auth/services";
import { EventRepository } from "app/common/events/services/eventRepository";
import { Loading } from "app/common/main/pages/Loading";
import { useUser } from "app/common/user/hooks/useUser";
import { UserRepository } from "app/common/user/services/userRepository";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { LocalStorage } from "shared/services";

import { useSuperQuery } from "@vadiun/react-hooks";

import { AlreadyAdded } from "./already-added";
import { SlugShareGuestPage } from "./guest";
import { SlugShareAddBirthdayGuest } from "./guest/add-birthday";
import { SlugAddGuestPage } from "./me/add-birthday";
import { SlugSharePage } from "./root";

function RequireAuth({ children, slug }: { children: JSX.Element; slug: string }) {
  const authService = useAuthService();

  if (authService.isLoading) return <Loading />;

  if (!authService.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const alreadyAdded: string[] = LocalStorage.alreadyAdded.get();
    if (alreadyAdded.find((x) => x === slug)) return <Navigate to={`../already-added`} replace />;

    return <Navigate to={`/${slug}/guest`} replace />;
  }

  return children;
}

function RequireOnboarding({ children }) {
  const userQuery = useSuperQuery(userRepo.keys.one("me"), () => userRepo.get("me"), {
    showErrorMessage: false,
  });

  if (userQuery.isLoading) return <Loading />;
  if (userQuery.error) return <Navigate to="/main/events/display" replace />;

  if (userQuery.data && !userQuery.data.profile.onboarding) {
    const setupStep = LocalStorage.slugSetup.get() !== null;
    return setupStep ? <Navigate to="/user/setup" replace /> : <Navigate to="/user/setup/birthday" replace />;
  }
  return children;
}

function VerifyOwnLink({ children }) {
  const { user, isLoadingUser } = useUser();
  const params = useParams();
  const slug = params.slug ?? "";

  if (isLoadingUser) {
    return <Loading />;
  }

  if (user.profile.slug === slug) return <Navigate to="/main/events/display" replace />;

  return children;
}

const eventRepo = new EventRepository();
const userRepo = new UserRepository();

function VerifyAlreadyAdded({ children, slug }: { children: JSX.Element; slug: string }) {
  const canSubscribeMutation = useSuperQuery(eventRepo.keys.canSubscribe(slug), () => eventRepo.getCanSubscribe(slug), {
    showSuccessMessage: false,
    showErrorMessage: false,
  });

  const checkCanSubscribeMutation = useSuperQuery(
    eventRepo.keys.checkCanSubscribe(slug),
    () => eventRepo.checkCanSubscribe(slug),
    {
      showSuccessMessage: false,
      showErrorMessage: false,
    }
  );

  if (canSubscribeMutation.isLoading || checkCanSubscribeMutation.isLoading) {
    return <Loading />;
  }

  if (!checkCanSubscribeMutation.data) return <Navigate to={`/${slug}/me/add-birthday`} replace />;

  if (canSubscribeMutation.data === false) return <Navigate to="already-added" replace />;

  return children;
}

export const SlugNavigation = () => {
  const authSrv = useAuthService();
  const params = useParams();
  const slug = params.slug ?? "";

  const { data, isLoading } = useSuperQuery(
    userRepo.keys.validateSlug(slug),
    () => {
      return userRepo.getSlug(slug);
    },
    {
      showErrorMessage: false,
    }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (data?.validated) return <Navigate to="/invalid-slug" replace />;

  return (
    <Routes>
      <Route path="/guest" element={<SlugShareGuestPage authSrv={authSrv} slug={slug} />} />
      <Route
        path="/guest/add-birthday"
        element={<SlugShareAddBirthdayGuest repo={userRepo} eventRepo={eventRepo} slug={slug} />}
      />
      <Route path="/already-added" element={<AlreadyAdded slug={slug} />} />

      <Route
        path="/me/add-birthday"
        element={
          <RequireAuth slug={slug}>
            <SlugAddGuestPage slug={slug} eventRepo={eventRepo} />
          </RequireAuth>
        }
      />

      {/** (*) route is part of [root] folder */}
      <Route
        path="*"
        element={
          <RequireAuth slug={slug}>
            <RequireOnboarding>
              <VerifyOwnLink>
                <VerifyAlreadyAdded slug={slug}>
                  <SlugSharePage slug={slug} userRepo={userRepo} eventRepo={eventRepo} />
                </VerifyAlreadyAdded>
              </VerifyOwnLink>
            </RequireOnboarding>
          </RequireAuth>
        }
      />
    </Routes>
  );
};
