import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { Button } from "layout/components/Button/Button";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PhoneField } from "shared/components/Formik/PhoneField";
import { getMonthDaysOptions, MonthOptions } from "shared/utils";

import { MenuItem } from "@mui/material";

import { BirthdaySetupFormType } from "./BirthdaySetupFormType";
import { createInitialValues } from "./initialValues";
import { validation } from "./validation";

interface Props {
  onSubmit: (value: BirthdaySetupFormType) => Promise<any>;
  initialValues?: BirthdaySetupFormType;
  monthOptions: MonthOptions[];
}

export function BirthdaySetupForm({ onSubmit, initialValues = createInitialValues(), monthOptions }: Props) {
  const dayRef = useRef<HTMLInputElement | undefined>(undefined);
  const [selectedMonth, setSelectedMonth] = useState(initialValues.month);
  const [selectedDay, setSelectedDay] = useState(initialValues.day);
  const { t } = useTranslation();

  return (
    <Formik<BirthdaySetupFormType> initialValues={initialValues} validationSchema={validation(t)} onSubmit={onSubmit}>
      {({ handleSubmit, setFieldValue, isSubmitting, handleChange }) => (
        <Form className="flex w-full flex-col gap-6" onSubmit={handleSubmit}>
          <div className="flex w-full flex-row items-start justify-between gap-3">
            <Field
              className={"basis-96"}
              name="month"
              label={t("user.setup.birthday.birthdayDate")}
              variant="standard"
              select
              component={TextField}
              fullWidth
              value={selectedMonth}
              autoFocus
              SelectProps={{
                onChange: (e) => {
                  setSelectedMonth(e.target.value);
                  setSelectedDay(1);
                  setFieldValue("day", "1");
                  handleChange(e);
                },
                onClose: () =>
                  setTimeout(() => {
                    dayRef.current?.focus();
                  }, 1),
              }}
            >
              {monthOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>

            <Field
              style={{ top: 16 }}
              name="day"
              value={selectedDay}
              variant="standard"
              select
              component={TextField}
              fullWidth
              SelectProps={{
                onChange: (e) => {
                  setSelectedDay(e.target.value);
                  handleChange(e);
                },
              }}
            >
              {getMonthDaysOptions(selectedMonth).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </div>

          <Field
            id="unforget-nickname"
            name="nickname"
            variant="standard"
            fullWidth
            label={t("user.setup.birthday.nicknameLabel")}
            margin="none"
            placeholder="Anna"
            component={TextField}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <div className="flex flex-col gap-2">
            <Field
              name="phone"
              variant="standard"
              fullWidth
              label={t("user.setup.birthday.phoneLabel")}
              margin="none"
              placeholder="+1 2324 4545"
              component={PhoneField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
              {t("user.setup.birthday.phoneHint")}
            </span>
          </div>

          <Button
            id="unforget-submit"
            className="mt-2"
            type="submit"
            variant="solid"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {t("user.setup.birthday.action")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
