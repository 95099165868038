import { useAuthService } from "app/common/auth/services";
import { Route, Routes } from "react-router-dom";

import { WelcomePage } from "./welcome";

export const AuthNavigation = () => {
  const authSrv = useAuthService();

  return (
    <Routes>
      <Route path={"welcome"} element={<WelcomePage authSrv={authSrv} />} />
    </Routes>
  );
};
