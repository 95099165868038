import cloud_slug from "assets/cloud_slug.svg";
import error_cloud1 from "assets/error_cloud1.svg";
import error_cloud2 from "assets/error_cloud2.svg";
import error_cloud3 from "assets/error_cloud3.svg";
import logo from "assets/logo.png";
import { LinkButton } from "layout/components/Button/LinkButton";
import { Title } from "layout/components/Title";
import { useTranslation } from "react-i18next";

export function Error() {
  const { t } = useTranslation();

  return (
    <div
      className={`relative flex h-full w-full flex-col overflow-auto bg-pink-50`}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="mx-auto h-full w-full max-w-xl bg-pink-50">
        <div className={`relative z-0 flex h-[138px] w-full flex-col overflow-hidden bg-pink-100`}>
          <div className={`text-primary z-10 flex h-16 flex-row items-center justify-start px-4 md:px-8`}>
            <div className={`flex h-full items-start pt-3`}>
              <img src={cloud_slug} alt="cloud" className="z-0 h-6" />
            </div>
            <img alt="unforget logo" src={logo} className={`absolute right-0 left-0 mx-auto w-[89px] object-cover`} />
          </div>
          <div className="absolute bottom-0 flex w-full flex-col overflow-hidden bg-pink-100">
            <div className="w-full bg-pink-100">
              <img src={error_cloud3} alt="cloud separator" className="w-full object-cover sm:hidden" />
            </div>
            <div className="relative flex h-[22px] w-full flex-col items-center justify-center gap-6">
              <img
                src={error_cloud2}
                alt="cloud separator"
                className="absolute inset-x-0 -bottom-1 w-full object-cover sm:hidden"
              />
              <img
                src={error_cloud1}
                alt="cloud separator"
                className="absolute inset-x-0 -bottom-1 w-full object-cover sm:hidden"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-20 bg-pink-50 px-7 pt-14">
          <Title variant="small">{t("main.error.title")}</Title>
          <LinkButton to="/main/events/display" className="bg-status-error" replace>
            {t("main.error.action")}
          </LinkButton>
        </div>
      </div>
    </div>
  );
}
