import { interestsSettingsPage } from "app/pages/user/settings/interests/dictionary";

import { share } from "../app/common/events/i18n/share";
import { mainError } from "../app/common/main/i18n/error";
import { userCommon } from "../app/common/user/i18n/common";
import { addTheirBdayPage } from "../app/pages/add-their-bday/dictionary";
import { welcomePage } from "../app/pages/auth/welcome/dictionary";
import { joinUsPage } from "../app/pages/join-us/dictionary";
import { addBirthday } from "../app/pages/main/events/add-birthday/dictionary";
import { birthdayDetailsPage } from "../app/pages/main/events/birthday-details/dictionary";
import { displayEventPage } from "../app/pages/main/events/display/dictionary";
import { settingsPage } from "../app/pages/user/settings/dictionary";
import { setupBirthdayPage } from "../app/pages/user/setup/birthday/dictionary";
import { setupInterestsPage } from "../app/pages/user/setup/interests/dictionary";
import { setupLinkExplanation } from "../app/pages/user/setup/link-explanation/dictionary";
import { setupReminderExplanation } from "../app/pages/user/setup/reminder-explanation/dictionary";
import { setupLinkPage } from "../app/pages/user/setup/root/dictionary";
import { setupShareLinkPage } from "../app/pages/user/setup/share/dictionary";
import { alreadyAddedPage } from "../app/pages/[slug]/already-added/dictionary";
import { slugShareAddBirthDayGuest } from "../app/pages/[slug]/guest/add-birthday/dictionary";
import { slugShareGuestPage } from "../app/pages/[slug]/guest/dictionary";
import { slugSharePage } from "../app/pages/[slug]/root/dictionary";
import { globals } from "./globals";

export const resources = {
  en: {
    translation: {
      ...globals.en,
      ...welcomePage.en,
      ...displayEventPage.en,
      ...share.en,
      ...settingsPage.en,
      ...addBirthday.en,
      ...birthdayDetailsPage.en,
      ...userCommon.en,
      ...interestsSettingsPage.en,

      ...setupLinkPage.en,
      ...setupBirthdayPage.en,
      ...setupShareLinkPage.en,
      ...setupLinkExplanation.en,
      ...setupReminderExplanation.en,
      ...setupInterestsPage.en,

      ...alreadyAddedPage.en,
      ...slugSharePage.en,
      ...slugShareGuestPage.en,
      ...slugShareAddBirthDayGuest.en,

      ...mainError.en,
      ...joinUsPage.en,
      ...addTheirBdayPage.en,
    },
  },
  es: {
    translation: {
      ...globals.es,
      ...welcomePage.es,
      ...displayEventPage.es,
      ...share.es,
      ...settingsPage.es,
      ...addBirthday.es,
      ...birthdayDetailsPage.es,
      ...userCommon.es,
      ...interestsSettingsPage.es,

      ...setupLinkPage.es,
      ...setupBirthdayPage.es,
      ...setupShareLinkPage.es,
      ...setupLinkExplanation.es,
      ...setupReminderExplanation.es,
      ...setupInterestsPage.es,

      ...alreadyAddedPage.es,
      ...slugSharePage.es,
      ...slugShareGuestPage.es,
      ...slugShareAddBirthDayGuest.es,

      ...mainError.es,
      ...joinUsPage.es,
      ...addTheirBdayPage.es,
    },
  },
};
