import clouds_full from "assets/cloud_lg_share.png";
import clouds_mid from "assets/cloud_md_share.png";
import red_cloud from "assets/footer_cloud1.svg";
import welcome_cloud from "assets/welcome_cloud.svg";
import { BigHeaderLayout } from "layout/components/BigHeaderLayout";
import { Button } from "layout/components/Button/Button";
import { Title } from "layout/components/Title";

import { CircularProgress } from "@mui/material";

interface Props {
  msg: string;
  acceptLabel: string;
  denyLabel: string;
  onDecision: (confirmation: boolean) => void;
  isLoading?: boolean;
  success?: boolean;
}

export const VerifyDecision: React.FC<Props> = ({ msg, acceptLabel, denyLabel, onDecision, isLoading, success }) => {
  return (
    <BigHeaderLayout
      canGoBack
      small
      fullWidth
      headerBgColor={"bg-brand-secondary"}
      bgColor={"bg-brand-primary"}
      separator={
        <>
          <div className="relative h-[60px] w-full bg-brand-secondary tablet:hidden">
            <img src={red_cloud} alt="cloud separator" className="absolute -bottom-2 w-full object-cover" />
            <img src={welcome_cloud} alt="cloud separator" className="absolute -bottom-1 w-full object-cover " />
          </div>
          <div className="relative hidden h-[84px] w-full bg-brand-secondary tablet:block">
            {/* Full width cloud separators */}
            <img
              src={clouds_full}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover xl:block"
            />

            {/* Mid width cloud separators */}
            <img
              src={clouds_mid}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover tablet:block xl:hidden"
            />
          </div>
        </>
      }
      footer={
        <div className="pl-6 pr-6">
          {isLoading ? (
            <div className="flex w-full items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="flex w-full flex-col items-center gap-3">
              <Button
                id="unforget-confirm"
                variant="solid"
                className="flex items-center justify-center"
                onClick={() => onDecision(true)}
                successAnimation={success}
              >
                {acceptLabel}
              </Button>
              <Button
                variant="outline"
                onClick={() => onDecision(false)}
                className="flex items-center justify-center"
                successAnimation={success}
              >
                {denyLabel}
              </Button>
            </div>
          )}
        </div>
      }
    >
      <div className="mx-auto mt-8 flex max-w-xl flex-col gap-10 px-7">
        <Title variant="small">{msg}</Title>
      </div>
    </BigHeaderLayout>
  );
};
