import { getBaseUrl } from "app/common/helpers";
import { UserRepository } from "app/common/user/services/userRepository";
import ARROW_LEFT from "assets/arrow_left.svg";
import ARROW_RIGHT from "assets/arrow_right.svg";
import ILLUSTRATION from "assets/onboarding-1.png";
import { BigHeaderLayout } from "layout/components/BigHeaderLayout";
import { Button } from "layout/components/Button/Button";
import { Title } from "layout/components/Title";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "shared/services";

import styled from "@emotion/styled";
import { useSnackbar, useSuperMutation } from "@vadiun/react-hooks";

import { SlugInput } from "../../../../common/user/setup/components/SlugInput";

const ActionsContainer = styled.div`
  justify-content: space-between;

  & div {
    display: relative !important;
    width: auto !important;
    min-width: 48px;
  }
`;

interface Props {
  repo: UserRepository;
  useUser: any;
}

export enum SetupLinkStatus {
  INITIAL,
  FETCHING_CURRENT_SLUG,
  GENERATING_SLUG,
  DEBOUNCING,
  VALIDATING,
  VALID_SLUG,
  INVALID_SLUG,
  INVALID_FORMAT,
}

let timeout: NodeJS.Timeout | undefined = undefined;
const debounce = (fn: () => void) => {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(fn, 700);
};

/******************************
 * Diagrama de estados https://www.figma.com/file/rhCk1yG23A4OoaD4v3V7Rs/Unforget?node-id=0%3A1
 */
export const SetupLinkPage: React.FC<Props> = ({ repo, useUser }) => {
  const showSnackbar = useSnackbar();
  const [inputTouched, setInputTouched] = useState(false);
  const [status, setStatus] = useState<SetupLinkStatus>(SetupLinkStatus.FETCHING_CURRENT_SLUG);
  const { user, refetchUser } = useUser();
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userShareMutation = useSuperMutation(repo.updateSlug, {
    showSuccessMessage: false,
    onSuccess: async () => {
      removeSlugSetupFlag();
      await refetchUser();
      navigate("/user/setup/reminder-explanation");
    },
  });

  useEffect(() => {
    if (user === undefined) {
      return;
    }
    if (user && user.profile.slug) {
      setText(user.profile.slug);
      setStatus(SetupLinkStatus.VALID_SLUG);
    } else {
      setStatus(SetupLinkStatus.INITIAL);
      handleInputChange(user.profile.slug);
    }
  }, [user?.profile?.slug]);

  const removeSlugSetupFlag = () => LocalStorage.slugSetup.remove();

  async function handleShare() {
    setInputTouched(true);
    if (status !== SetupLinkStatus.VALID_SLUG) {
      return;
    }
    await userShareMutation.mutateAsync({
      id: user.id,
      slug: text,
    });
  }

  async function handleInputChange(slug: string) {
    setStatus(SetupLinkStatus.DEBOUNCING);
    setText(slug);
    debounce(async () => {
      if (slug.includes("/") || slug.includes(" ")) {
        setStatus(SetupLinkStatus.INVALID_FORMAT);
        return;
      }
      if (slug.length === 0) {
        setStatus(SetupLinkStatus.INVALID_SLUG);
        return;
      }
      setStatus(SetupLinkStatus.VALIDATING);
      try {
        const { validated } = await repo.getSlug(slug);

        setStatus(validated ? SetupLinkStatus.VALID_SLUG : SetupLinkStatus.INVALID_SLUG);
      } catch {
        showSnackbar({
          message: t("user.setup.link.validateError"),
          title: t("user.setup.link.errorTitle")!,
          type: "error",
        });
        setStatus(SetupLinkStatus.INITIAL);
      }
    });
  }

  async function handleRandomSlugClick() {
    setStatus(SetupLinkStatus.GENERATING_SLUG);
    try {
      const newSlug = await repo.getUniqueSlug();
      setText(newSlug.slug);
      setStatus(SetupLinkStatus.VALID_SLUG);
    } catch {
      showSnackbar({
        message: t("user.setup.link.generateError"),
        title: t("user.setup.link.errorTitle")!,
        type: "error",
      });
      setStatus(SetupLinkStatus.INITIAL);
    }
  }

  return (
    <BigHeaderLayout
      headerBgColor="bg-brand-primary"
      bgColor="bg-brand-primary"
      small={false}
      medium={true}
      fullWidth
      titleNode={
        <>
          <img src={ILLUSTRATION} alt="ilustration" className="m-auto h-[220px] object-contain" />
        </>
      }
      footer={
        <ActionsContainer className="flex w-full flex-row pl-6 pr-6" style={{ flex: "0 0 auto" }}>
          <Button
            variant="outline"
            onClick={() => {
              navigate("/user/setup/link-explanation");
            }}
            className="h-12 w-12"
          >
            <img src={ARROW_LEFT} className="object-cover" alt={t("user.settings.change")!} />
          </Button>
          <div className="mx-auto flex w-full max-w-xl items-center justify-center">
            <svg width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4.5" cy="4" r="4" fill="#6A7699" />
              <circle cx="20.5" cy="4" r="4" fill="#F582AE" />
              <circle cx="36.5" cy="4" r="4" fill="#6A7699" />
            </svg>
          </div>
          <Button variant="solid" onClick={handleShare} className="h-12 w-12" id="unforget-next">
            <img src={ARROW_RIGHT} className="object-cover" alt={t("user.settings.change")!} />
          </Button>
        </ActionsContainer>
      }
    >
      <div className="mx-auto flex w-full max-w-xl items-center px-7 pb-3 pt-2 text-center">
        <Title variant="small">{t("user.setup.link.header")!}</Title>
      </div>
      {/* Content */}
      <div className="mx-auto flex w-full max-w-xl flex-col items-center gap-5 px-7 pb-5 pt-2">
        <h3 style={{ fontSize: "14px" }} className="track font-light leading-5 text-text-paragraph">
          {t("user.setup.link.title")}
        </h3>
        <div className="mx-auto flex w-full max-w-xl flex-col items-center gap-8 ">
          <div className="flex w-full flex-col gap-1">
            <SlugInput
              onChange={handleInputChange}
              onShuffle={handleRandomSlugClick}
              text={text}
              onBlur={() => setInputTouched(true)}
              status={status}
              touched={inputTouched}
            />
            <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
              {getBaseUrl()}/{text}
            </span>
          </div>
        </div>
      </div>
    </BigHeaderLayout>
  );
};
