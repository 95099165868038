import { EventsNavigation } from "app/pages/main/events";
import { Route, Routes } from "react-router-dom";

export const MainNavigation = () => {
  return (
    <Routes>
      <Route path="events/*" element={<EventsNavigation />} />
    </Routes>
  );
};
