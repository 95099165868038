export const birthdayDetailsPage = {
  en: {
    "events.birthdayDetails.title": "Birthday details",
    "events.birthdayDetails.love": "Send some love",
    "events.birthdayDetails.linked": "This birthday is linked to your friend’s calendar.",
    "events.birthdayDetails.sendTitle": "Send a message via",
    "events.birthdayDetails.whatsapp": "Whatsapp",
    "events.birthdayDetails.delete": "Delete birthday",
    "events.birthdayDetails.deleteMessage": "Delete birthday? This action can not be undone",
    "events.birthdayDetails.deleteAction": "Delete",
    "events.birthdayDetails.updateMessage":
      "Update birthday? This birthday is linked to other calendar, by updating this event it will be unlinked from the calendar",
    "events.birthdayDetails.updateAction": "Update",
    "events.birthdayDetails.greetings": "Happy Birthday",
    "events.birthdayDetails.saveLabel": "Save",
  },
  es: {
    "events.birthdayDetails.title": "Detalles de cumpleaños",
    "events.birthdayDetails.love": "Envía un poco de amor",
    "events.birthdayDetails.linked": "Este cumpleaños está vinculado al calendario de tu amigo.",
    "events.birthdayDetails.sendTitle": "Enviar un mensaje a través de",
    "events.birthdayDetails.whatsapp": "Whatsapp",
    "events.birthdayDetails.delete": "Eliminar cumpleaños",
    "events.birthdayDetails.deleteMessage": "¿Eliminar cumpleaños? Esta acción no se puede deshacer",
    "events.birthdayDetails.deleteAction": "Eliminar",
    "events.birthdayDetails.updateMessage":
      "¿Actualizar cumpleaños? Este cumpleaños está vinculado a otro calendario, al actualizar este evento se desvinculará del calendario",
    "events.birthdayDetails.updateAction": "Actualizar",
    "events.birthdayDetails.greetings": "Feliz cumpleaños",
    "events.birthdayDetails.saveLabel": "Guardar",
  },
};
