import moment from "moment";
import * as Yup from "yup";

import { AddBirthdaySubscriptionFormType } from "./AddBirthdaySubscriptionFormType";

export const validation = (t): Yup.SchemaOf<AddBirthdaySubscriptionFormType> =>
  Yup.object().shape({
    month: Yup.string().required(t("slug.share.addBirthDayGuest.form.validation#1")),
    day: Yup.number()
      .required(t("slug.share.addBirthDayGuest.form.validation#2"))
      .test("validBirthdate", t("slug.share.addBirthDayGuest.form.validation#3"), (value, context) => {
        const { month } = context.parent;
        if (value === undefined) return false;

        //since moment consider 1 invalid but 01 valid
        const momentCero = value < 10 ? "0" : "";

        const birthday = moment(`${momentCero}${value}-${month}`, "DD-MM", true);
        const isValidDate = birthday.isValid();
        if (month === "02" && value === 29) return true; //accept 29 of February
        return isValidDate;
      }) as any,
    nickname: Yup.string()
      .min(3, t("slug.share.addBirthDayGuest.form.validation#4"))
      .required(t("slug.share.addBirthDayGuest.form.validation#5")),
    email: Yup.string().email().required(t("slug.share.addBirthDayGuest.form.validation#6")),
    phone: Yup.string(),
  });
