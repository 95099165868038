import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { Button } from "layout/components/Button/Button";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PhoneField } from "shared/components/Formik/PhoneField";
import { getMonthDaysOptions, MonthOptions } from "shared/utils";

import { MenuItem } from "@mui/material";

import { AddBirthdayFormType } from "./AddBirthdayFormType";
import { createInitialValues } from "./initialValues";
import { validation } from "./validation";

interface Props {
  onSubmit: (value: AddBirthdayFormType) => Promise<any>;
  initialValues?: AddBirthdayFormType;
  successSave?: boolean;
  isSubmitting?: boolean;
  isSubmittingAnother?: boolean;
  successSaveAnother?: boolean;
  monthOptions: MonthOptions[];
}

export function AddBirthdayForm({
  onSubmit,
  initialValues = createInitialValues(),
  successSave,
  isSubmitting,
  isSubmittingAnother,
  successSaveAnother,
  monthOptions,
}: Props) {
  const [selectedMonth, setSelectedMonth] = useState(initialValues.month);
  const [selectedDay, setSelectedDay] = useState(initialValues.day);
  const { t } = useTranslation();
  const dayRef = useRef<HTMLInputElement | undefined>(undefined);
  const navigate = useNavigate();

  return (
    <Formik<AddBirthdayFormType> initialValues={initialValues} validationSchema={validation(t)} onSubmit={onSubmit}>
      {(formik) => (
        <Form className="flex w-full flex-col gap-4">
          <div className="flex w-full flex-row items-start justify-between gap-3">
            <Field
              className={"basis-96"}
              name="month"
              label={t("user.setup.birthday.birthdayDate")}
              variant="standard"
              select
              component={TextField}
              fullWidth
              value={selectedMonth}
              SelectProps={{
                onChange: (e) => {
                  setSelectedMonth(e.target.value);
                  setSelectedDay(1);
                  formik.setFieldValue("day", "1");
                  formik.handleChange(e);
                },
                onClose: () =>
                  setTimeout(() => {
                    dayRef.current?.focus();
                  }, 1),
              }}
            >
              {monthOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>

            <Field
              style={{ top: 16 }}
              name="day"
              value={selectedDay}
              variant="standard"
              select
              component={TextField}
              fullWidth
              SelectProps={{
                onChange: (e) => {
                  setSelectedDay(e.target.value);
                  formik.handleChange(e);
                },
              }}
            >
              {getMonthDaysOptions(selectedMonth).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </div>

          <Field
            name="nickname"
            label={t("user.settings.form.nickname")}
            variant="standard"
            fullWidth
            autoFocus
            placeholder="Donna T"
            component={TextField}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Field
            name="email"
            label={t("user.settings.form.email")}
            variant="standard"
            fullWidth
            placeholder="donna@email.com"
            component={TextField}
            inputProps={{
              autoCapitalize: "off",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
            {t("user.settings.form.emailHint")}
          </span>

          <Field
            name="phone"
            label={t("user.settings.form.phone")}
            variant="standard"
            fullWidth
            placeholder="+1 2324 4545"
            component={PhoneField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
            {t("user.setup.birthday.phoneHint")}
          </span>

          <div className="mt-4">
            <Button
              type="button"
              variant="solid"
              className="flex items-center justify-center"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              successAnimation={successSave}
              onClick={async () => {
                await formik.submitForm();
              }}
            >
              {t("globals.save")}
            </Button>
          </div>
          <Button
            variant="outline"
            type="button"
            className="flex items-center justify-center"
            successAnimation={successSaveAnother}
            isLoading={isSubmittingAnother}
            onClick={async () => {
              formik.setFieldValue("type", "multiple");
              await formik.submitForm();
              if (formik.isValid) {
                formik.resetForm();
              }
            }}
          >
            {t("globals.saveAdd")}
          </Button>
          <Button
            type="button"
            onClick={() => {
              formik.resetForm();
              navigate("../display");
            }}
            variant="text"
            color="red"
          >
            {t("globals.discard")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
