import { EventRepository } from "app/common/events/services/eventRepository";
import { tenorRepository } from "app/common/events/services/tenorRepository";
import MSG from "assets/msg.svg";
import T_LOGO from "assets/telegram-icon.svg";
import TRASH_ICON from "assets/trash.svg";
import WS_LOGO from "assets/whatsapp-icon.png";
import { PageBody, PageContainer } from "layout/components";
import { BodyHeader } from "layout/components/BodyHeader";
import { Button } from "layout/components/Button/Button";
import { debounceButtonAnimation } from "layout/hooks/useButtonAnimation";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Swipeable } from "shared/components";
import { VerifyAction } from "shared/components/VerifyAction/VerifyAction";
import { LocalStorage } from "shared/services";
import { validateDate } from "shared/utils";

import { CircularProgress } from "@mui/material";
import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";

import { BirthdayDetailsForm } from "../../../../common/events/forms/birthdayDetailsForm/BirthdayDetailsForm";
import { BirthdayDetailsFormType } from "../../../../common/events/forms/birthdayDetailsForm/BirthdayDetailsFormType";
import { useSendGreetings } from "../../../../common/events/hooks/useSendGreetings";

interface Props {
  repo: EventRepository;
  useSearchParams: any;
  localStorage: typeof LocalStorage;
}

export const BirthdayDetailsPage = ({ repo, useSearchParams, localStorage }: Props) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const formValuesRef = useRef<BirthdayDetailsFormType | undefined>();
  const isVerifyingDelete = !!searchParams.get("verify-delete");
  const isVerifyingUpdate = !!searchParams.get("verify-update");

  const [drawerState, setDrawerState] = useState(false);
  const { sendGreetings } = useSendGreetings();

  const navigate = useNavigate();

  const eventId = searchParams.get("event") ?? "";
  const { data, isLoading } = useSuperQuery(repo.keys.one(eventId), () => {
    return repo.getEventById(eventId);
  });

  const queryClient = useQueryClient();

  function getBackUrl() {
    const selectedDate = localStorage.selectedDate.get();
    return `/main/events/display?day=${selectedDate.date}&month=${selectedDate.month + 1}`;
  }

  const navigateHome = () => {
    debounceButtonAnimation(() => navigate(getBackUrl()));
  };

  const deleteEventMutation = useSuperMutation(repo.delete, {
    onSuccess: navigateHome,
    showErrorMessage: false,
    showSuccessMessage: false,
  });

  const updateEventMutation = useSuperMutation(repo.update, {
    showSuccessMessage: false,
    showErrorMessage: false,
    onSuccess: navigateHome,
  });

  //We have to wrap the mutationFn. because share is only available in secure context
  const shareMutation = useSuperMutation((values: ShareData) => navigator.share(values), {
    showSuccessMessage: false,
    showErrorMessage: false,
  });

  const isEventLinked = data?.host_id !== null;

  const initialValues = {
    day: data ? data.day : 1,
    month: data
      ? moment()
          .month(data.month - 1)
          .format("MM")
      : "01",
    name: data ? data.nickname : "",
    email: data ? (data.email !== null ? data.email : "") : "",
  };

  async function handleSubmit(values: BirthdayDetailsFormType) {
    formValuesRef.current = values;
    if (isEventLinked) {
      setSearchParams(`event=${eventId}&verify-update=true`);

      return;
    }
    await updateEvent();
    return;
  }

  async function updateEvent() {
    const phone = data?.phone;
    const values = formValuesRef.current ?? initialValues;

    const { day, month } = validateDate(values.day, values.month);
    const { email, name } = values;
    await updateEventMutation.mutateAsync({
      eventId,
      phone,
      day,
      month,
      email,
      name,
    });
    queryClient.refetchQueries(repo.keys.one(eventId));
    return;
  }

  function handleVerifyDeleteEvent() {
    setSearchParams(`event=${eventId}&verify-delete=true`);
  }

  async function handleDeleteEvent() {
    deleteEventMutation.mutate(eventId);
  }

  const phoneNumber = data?.phone ?? "";

  const text = t("events.displayEvent.descriptor.greetings").replace(":NICKNAME", data?.nickname!);
  const message = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(text)}`;
  const tMessage = `https://t.me/share/url?url=${window.location.origin}&text=${encodeURIComponent(text)}`;

  async function handleShare() {
    const shareData: ShareData = {
      title: text,
      text,
    };

    try {
      if (navigator.canShare(shareData)) {
        shareData.files = await tenorRepository().getGif();
        shareMutation.mutate(shareData);
      }
    } catch (e) {
      //fallback share
      setDrawerState(true);
    }
  }

  return (
    <>
      {isVerifyingDelete ? (
        <VerifyAction
          onAccept={handleDeleteEvent}
          success={deleteEventMutation.isSuccess}
          isLoading={deleteEventMutation.isLoading}
          msg={t("events.birthdayDetails.deleteMessage")}
          acceptMsg={t("events.birthdayDetails.deleteAction")}
        />
      ) : isVerifyingUpdate ? (
        <VerifyAction
          onAccept={updateEvent}
          success={updateEventMutation.isSuccess}
          isLoading={updateEventMutation.isLoading}
          msg={t("events.birthdayDetails.updateMessage")}
          acceptMsg={t("events.birthdayDetails.updateAction")}
        />
      ) : (
        <PageContainer footer child navigateBackTo={getBackUrl()}>
          <PageBody>
            <div className="flex flex-col lg:px-5" style={{ marginTop: isEventLinked ? "-24px" : 0 }}>
              {!isLoading ? (
                <div className={`flex w-full flex-col`}>
                  <div className="flex w-full flex-col gap-8">
                    <div className="flex w-full flex-col gap-2">
                      {isEventLinked && !isLoading && (
                        <div className=" items-left flex w-full flex-col">
                          <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
                            {t("events.birthdayDetails.linked")}
                          </span>
                        </div>
                      )}
                      <BodyHeader title={t("events.birthdayDetails.title")} />
                    </div>
                    <BirthdayDetailsForm
                      isEventLinked={isEventLinked}
                      onSubmit={handleSubmit}
                      success={updateEventMutation.isSuccess}
                      initialValues={initialValues}
                    />
                  </div>
                  <div className="flex w-full flex-col items-center gap-3">
                    <Button variant="outline" onClick={handleShare}>
                      <img src={MSG} alt="Send some love" className="object-cover" />
                      {t("events.birthdayDetails.love")}
                    </Button>
                    <Button type="button" variant="text" color="red" onClick={handleVerifyDeleteEvent}>
                      <img src={TRASH_ICON} className="object-cover" alt="Delete birthday" />
                      {t("events.birthdayDetails.delete")}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="mt-5 flex flex-row items-center justify-center">
                  <CircularProgress />
                </div>
              )}
            </div>
            <Swipeable
              state={drawerState}
              onClose={() => setDrawerState(false)}
              onOpen={() => setDrawerState(true)}
              title={t("events.birthdayDetails.sendTitle")!}
            >
              <a target="_blank" href={message} onClick={() => sendGreetings(Number(eventId), "all")} rel="noreferrer">
                <div className="flex flex-col items-center">
                  <img width="36px" src={WS_LOGO} alt="whatsapp logo" />
                  <span className="text-xs" style={{ marginTop: "8px" }}>
                    {t("share.whatsapp")}
                  </span>
                </div>
              </a>
              <a target="_blank" href={tMessage} onClick={() => sendGreetings(Number(eventId), "all")} rel="noreferrer">
                <div className="flex flex-col items-center">
                  <img width="36px" src={T_LOGO} alt="telegram logo" />
                  <span className="text-xs" style={{ marginTop: "8px" }}>
                    Telegram
                  </span>
                </div>
              </a>
            </Swipeable>
          </PageBody>
        </PageContainer>
      )}
    </>
  );
};
