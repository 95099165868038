interface IEvent {
  day: number;
  email: string;
  greetings: boolean;
  host_id: number | null;
  id: number;
  month: number;
  nickname: string;
  phone: string;
  type: string;
}

export class Event {
  day: number;
  email: string;
  greetings: boolean;
  host_id: number | null;
  id: number;
  month: number;
  nickname: string;
  phone: string;
  type: string;

  constructor(args: IEvent) {
    this.day = args.day;
    this.email = args.email;
    this.greetings = args.greetings;
    this.host_id = args.host_id;
    this.id = args.id;
    this.month = args.month;
    this.nickname = args.nickname;
    this.phone = args.phone;
    this.type = args.type;
  }
}
