export const setupInterestsPage = {
  en: {
    "user.setup.interests.title": "What you’d like for your birthday?",
    "user.setup.interests.paragraph": "Tell us your top 3 interests and we'll give your friends some great gift ideas.",
  },
  es: {
    "user.setup.interests.title": "What you’d like for your birthday?",
    "user.setup.interests.paragraph": "Tell us your top 3 interests and we'll give your friends some great gift ideas.",
  },
};
