import { EventRepository } from "app/common/events/services/eventRepository";
import logo from "assets/logo.png";
import wave_solid from "assets/wave_solid.svg";
import { Button } from "layout/components/Button/Button";
import { LinkButton } from "layout/components/Button/LinkButton";
import { debounceButtonAnimation } from "layout/hooks/useButtonAnimation";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useSuperMutation } from "@vadiun/react-hooks";

interface Props {
  eventRepo: EventRepository;
  slug: string;
}

export const SlugAddGuestPage: React.FC<Props> = ({ eventRepo, slug }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-height: 550px)");

  const eventMutation = useSuperMutation(eventRepo.addSubscription, {
    showSuccessMessage: false,
    showErrorMessage: false,
    onError: (e) => {
      // TODO: review error handling!
      navigate("/main/events/display");
    },
    onSuccess: (e) => {
      const date = e.birthday.split("T")[0];
      const birthday = moment(date);

      debounceButtonAnimation(() =>
        navigate(`/main/events/display?day=${birthday.date()}&month=${birthday.month() + 1}`)
      );
    },
  });

  async function handleAddBirthday() {
    await addBirthday(slug);
  }

  async function addBirthday(slug: string) {
    await eventMutation.mutateAsync(slug);
  }

  const JoinUsSmallLayout = () => (
    <div className={`relative z-0 flex h-full w-full flex-col overflow-auto`}>
      <img src={wave_solid} className="absolute inset-x-0 top-0 -z-20 w-full object-cover" alt="background" />
      <div className="flex h-full flex-col justify-between px-6 pb-7">
        <div className="flex flex-col gap-4">
          <div className="flex h-16 items-center justify-center">
            <img alt="unforget logo" src={logo} className={`h-[15.38px] w-[89px] object-cover`} />
          </div>
          <div className="flex flex-col gap-6">
            <h4 className="z-30 px-4 text-center text-xl font-medium leading-7 text-white">
              {t("main.join.title").replace(":SLUG", slug)}
            </h4>
            <p
              className="text-paragraph text-center text-xl font-medium leading-6"
              dangerouslySetInnerHTML={{
                __html: t("main.join.description").replace(":SLUG", slug),
              }}
            ></p>
          </div>
        </div>

        <Button
          onClick={handleAddBirthday}
          variant="solid"
          isLoading={eventMutation.isLoading}
          disabled={eventMutation.isLoading}
          successAnimation={eventMutation.isSuccess}
        >
          {t("main.join.user.action")}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {matches ? (
        <JoinUsSmallLayout />
      ) : (
        <div className={`relative z-0 flex h-full w-full flex-col gap-12 overflow-auto`}>
          <img src={wave_solid} className="absolute inset-x-0 top-0 -z-20 w-full object-cover" alt="background" />
          <div className="absolute inset-x-0 top-0 hidden h-1/3 bg-mint md:block" />
          <div className={`text-primary z-10 flex h-16 flex-row items-center justify-center px-4 md:px-8`}>
            <img alt="unforget logo" src={logo} className={`absolute right-0 left-0 mx-auto w-[89px] object-cover`} />
          </div>
          <div className="mx-auto flex w-full max-w-xl flex-col items-center px-7 pb-14">
            <h2 className="z-30 px-4 text-center text-3xl font-medium leading-10 text-white">
              {t("main.join.title").replace(":SLUG", slug)}
            </h2>
          </div>
          <div className="absolute inset-x-0 bottom-12 mx-auto flex w-full max-w-xl flex-col items-center gap-8 px-7 pt-28 md:relative md:pt-40">
            <p
              className="text-paragraph text-center text-xl font-medium leading-6"
              dangerouslySetInnerHTML={{
                __html: t("main.join.description").replace(":SLUG", slug),
              }}
            ></p>
            <div className="flex w-full flex-col gap-2">
              <Button
                onClick={handleAddBirthday}
                variant="solid"
                isLoading={eventMutation.isLoading}
                disabled={eventMutation.isLoading}
                successAnimation={eventMutation.isSuccess}
              >
                {t("main.join.user.action")}
              </Button>
              <div className="flex w-full flex-col gap-5">
                <LinkButton to="/main/events/display" replace variant="text">
                  {t("user.setup.shareLink.calendarLabel")}
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
