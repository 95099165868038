import check from "assets/check.svg";
import { HTMLMotionProps, motion } from "framer-motion";
import { useButtonAnimation } from "layout/hooks/useButtonAnimation";
import React, { forwardRef } from "react";

import { CircularProgress } from "@mui/material";

import { createStyles } from "./styles";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  variant: "outline" | "solid" | "error" | "text";
  color?: "white" | "primary" | "red";
  isLoading?: boolean;
  type?: "button" | "submit";
  withIcon?: boolean;
  sizes?: "sm" | "md" | "lg";
  successAnimation?: boolean;
  enterAnimation?: boolean;
  className?: string;
  ref?: React.LegacyRef<HTMLButtonElement> | undefined;
}
export const Button = forwardRef<HTMLButtonElement, HTMLMotionProps<"button"> & Props>((props, ref) => {
  const isOutlined = props.variant === "outline";
  const isText = props.variant === "text";
  const isError = props.variant === "error";
  const { initial, isAnimating, control } = useButtonAnimation({
    successAnimation: props.successAnimation,
    enterAnimation: props.enterAnimation,
    primaryAnimation: !isOutlined,
  });

  const styles = createStyles({
    isOutlined,
    isError,
    isText,
    color: props.color,
    sizes: props.sizes,
    disabled: props.disabled,
  });

  const { isLoading, withIcon, variant, ...restOfProps } = props;
  return (
    <div className="flex w-full justify-center">
      <motion.button
        {...restOfProps}
        ref={ref}
        className={`${styles} ${props.className}`}
        animate={control}
        initial={initial}
      >
        {isAnimating ? (
          <img src={check} alt="success" className="pt-1" />
        ) : props.isLoading ? (
          <CircularProgress size={30} color="secondary" />
        ) : (
          props.children
        )}
      </motion.button>
    </div>
  );
});
