export const setupShareLinkPage = {
  en: {
    "user.setup.shareLink.title": "Share your link to your friends",
    "user.setup.shareLink.subtitle": "We'll let you know when a birthday is coming up!",
    "user.setup.shareLink.shareLabel": "Share",
    "user.setup.shareLink.copyLabel": "or copy your link",
    "user.setup.shareLink.copiedLabel": "Copied!",
    "user.setup.shareLink.copyAction": "Copy",
    "user.setup.shareLink.calendarLabel": "Go to my calendar",
    "user.setup.shareLink.label#1": "Whatsapp",
    "user.setup.shareLink.send": "Send a message via",
  },
  es: {
    "user.setup.shareLink.title": "Comparte tu enlace con tus amigos",
    "user.setup.shareLink.subtitle": "¡Te avisaremos cuando se acerque un cumpleaños!",
    "user.setup.shareLink.shareLabel": "Compartir",
    "user.setup.shareLink.copyLabel": "o copia tu enlace",
    "user.setup.shareLink.copiedLabel": "Copiado!",
    "user.setup.shareLink.copyAction": "Copiar",
    "user.setup.shareLink.calendarLabel": "Ir a mi calendario",
    "user.setup.shareLink.label#1": "Whatsapp",
    "user.setup.shareLink.send": "Enviar un mensaje a través de",
  },
};
