import { AuthContextType } from "app/common/auth/services";
import clouds_full from "assets/cloud_lg_share.png";
import clouds_mid from "assets/cloud_md_share.png";
import red_cloud from "assets/footer_cloud1.svg";
import welcome_cloud from "assets/welcome_cloud.svg";
import { BigHeaderLayout } from "layout/components/BigHeaderLayout";
import { Button } from "layout/components/Button/Button";
import { LinkButton } from "layout/components/Button/LinkButton";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { LocalStorage } from "shared/services";

interface Props {
  slug: string;
  authSrv: AuthContextType;
}

export const SlugShareGuestPage: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const alreadyAdded: string[] = LocalStorage.alreadyAdded.get();
  if (alreadyAdded.find((x) => x === props.slug)) return <Navigate to={`../already-added`} replace />;

  function handleLogin() {
    props.authSrv.loginWithRedirect(`${encodeURIComponent(props.slug)}`);
  }

  function handleSignup() {
    LocalStorage.signupAndBday.set({
      enabled: true,
      slug: props.slug,
    });
    props.authSrv.signup();
  }

  return (
    <BigHeaderLayout
      titleNode={
        <h2 className="z-30 text-center text-3xl font-medium leading-10 tracking-semiwide text-text-title">
          {t("slug.guest.title").replace(":SLUG", props.slug)}
        </h2>
      }
      headerBgColor={"bg-brand-secondary"}
      bgColor={"bg-brand-primary"}
      small={false}
      fullWidth
      separator={
        <>
          <div className="relative h-[48px] w-full bg-brand-secondary tablet:hidden">
            <img src={red_cloud} alt="cloud separator" className="absolute -bottom-2 w-full object-cover" />
            <img src={welcome_cloud} alt="cloud separator" className="absolute -bottom-1 w-full object-cover " />
          </div>
          <div className="relative hidden h-[84px] w-full bg-brand-secondary tablet:block md:h-[100px]">
            {/* Full width cloud separators */}
            <img
              src={clouds_full}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover xl:block"
            />

            {/* Mid width cloud separators */}
            <img
              src={clouds_mid}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover tablet:block xl:hidden"
            />
          </div>
        </>
      }
      footer={
        <div className="mx-auto flex max-w-xl flex-col gap-6 bg-brand-primary px-6 pt-12 pb-8">
          <Button variant="solid" className="flex items-center justify-center" onClick={handleSignup}>
            {t("slug.guest.birthdaySignupLabel")}
          </Button>
          <LinkButton isOutlined to="add-birthday">
            {t("slug.guest.birthdayLabel")}
          </LinkButton>
          <p className="text-xs text-center font-normal leading-3 tracking-wider">
            {t("slug.guest.birthdayFasterLabel")}{" "}
            <button onClick={handleLogin} className="font-bold leading-3 tracking-wider text-button focus:text-focus">
              {t("slug.guest.loginLabel")}
            </button>
          </p>
        </div>
      }
    ></BigHeaderLayout>
  );
};
