import clouds_full from "assets/cloud_lg_share.png";
import clouds_mid from "assets/cloud_md_share.png";
import red_cloud from "assets/footer_cloud1.svg";
import welcome_cloud from "assets/welcome_cloud.svg";
import { BigHeaderLayout } from "layout/components/BigHeaderLayout";
import { Button } from "layout/components/Button/Button";
import { Title } from "layout/components/Title";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Props {
  msg: string;
  acceptMsg: string;
  onAccept: () => void;
  isLoading?: boolean;
  success?: boolean;
}

export const VerifyAction: React.FC<Props> = ({ onAccept, msg, acceptMsg, isLoading, success }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BigHeaderLayout
      canGoBack
      small
      fullWidth
      headerBgColor={"bg-brand-secondary"}
      bgColor={"bg-brand-primary"}
      separator={
        <>
          <div className="relative h-[60px] w-full bg-brand-secondary tablet:hidden">
            <img src={red_cloud} alt="cloud separator" className="absolute -bottom-2 w-full object-cover" />
            <img src={welcome_cloud} alt="cloud separator" className="absolute -bottom-1 w-full object-cover " />
          </div>
          <div className="relative hidden h-[84px] w-full bg-brand-secondary tablet:block">
            {/* Full width cloud separators */}
            <img
              src={clouds_full}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover xl:block"
            />

            {/* Mid width cloud separators */}
            <img
              src={clouds_mid}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover tablet:block xl:hidden"
            />
          </div>
        </>
      }
      footer={
        <div className="flex w-full flex-col items-center gap-3 pl-6 pr-6">
          <Button
            id="unforget-confirm"
            variant="solid"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onAccept}
            className="flex items-center justify-center"
            successAnimation={success}
          >
            {acceptMsg}
          </Button>
          <Button variant="outline" onClick={() => navigate(-1)} className="flex items-center justify-center">
            {t("globals.verify.cancel")}
          </Button>
        </div>
      }
    >
      <div className="mx-auto mt-8 flex max-w-xl flex-col gap-10 px-7">
        <Title variant="small">{msg}</Title>
      </div>
    </BigHeaderLayout>
  );
};
