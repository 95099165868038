export const displayEventPage = {
  en: {
    "events.displayEvent.morning": "Hello, :SLUG!",
    "events.displayEvent.today": "Today’s Birthdays",
    "events.displayEvent.ops": "No birthdays today!",
    "events.displayEvent.noBirthdays": "No birthdays here!",
    "events.displayEvent.number": ":NUMBER birthdays today!",
    "events.displayEvent.upcoming": "Upcoming",
    "events.displayEvent.recent": "Recent",
    "events.displayEvent.no-birthdays": "No birthdays today.",
    "events.displayEvent.share": "Ask Friends for Bdays",

    "events.displayEvent.descriptor.greetings": "Happy Birthday *:NICKNAME*!",
    "events.displayEvent.descriptor.send": "Send a Message",
    "events.displayEvent.descriptor.empty": "No birthdays today.",
    "events.displayEvent.descriptor.sendVia": "Send a message via",
    "events.displayEvent.descriptor.whatsapp": "Whatsapp",
  },
  es: {
    "events.displayEvent.morning": "Hola, :SLUG!",
    "events.displayEvent.today": "Cumpleaños de hoy",
    "events.displayEvent.ops": "No hay cumpleaños hoy!",
    "events.displayEvent.noBirthdays": "No hay cumpleaños aquí!",
    "events.displayEvent.number": ":NUMBER cumpleaños hoy!",
    "events.displayEvent.upcoming": "Próximos",
    "events.displayEvent.recent": "Reciente",
    "events.displayEvent.no-birthdays": "No hay cumpleaños hoy.",
    "events.displayEvent.share": "Consigue más cumpleaños",

    "events.displayEvent.descriptor.greetings": "¡Feliz cumpleaños *:NICKNAME*!",
    "events.displayEvent.descriptor.send": "Enviar un mensaje",
    "events.displayEvent.descriptor.empty": "No hay cumpleaños hoy.",
    "events.displayEvent.descriptor.sendVia": "Enviar un mensaje a través de",
    "events.displayEvent.descriptor.whatsapp": "Whatsapp",
  },
};
