import { PageBody, PageContainer } from "layout/components";
import { BodyHeader } from "layout/components/BodyHeader";
import { Button } from "layout/components/Button/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "@emotion/styled";
import { Chip } from "@mui/material";

const FormActions = styled.div`
  width: 100%;
  position: fixed;
  background: #fef6e4;
  box-shadow: 0px -4px 20px rgb(24 22 20 / 14%);
  margin-right: -1.25rem;
  padding: 1.25rem;
  bottom: 0;
  z-index: 1;
  margin-left: -1.25rem;
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`;

export const InterestsSettingsPage = ({ selectedInterests, interests, onCancel, onSave }: any) => {
  const { t } = useTranslation();

  const getCategories = () => {
    const categories = interests
      .map((interest) => {
        interest.selected = false;
        interest.disabled = false;

        return interest;
      })
      .map((category) => {
        const isSelected = selectedInterests.filter((s) => s.name === category.name).length > 0;

        category.selected = isSelected;
        category.disabled = !isSelected;

        return category;
      });

    const selected = categories.filter((category) => category.selected === true).length;

    if (selected === 3) {
      categories
        .filter((category) => category.selected === false)
        .forEach((category) => {
          category.disabled = true;
        });
    } else {
      categories.forEach((category) => {
        category.disabled = false;
      });
    }

    return categories;
  };

  const [categories, setCategories] = useState(getCategories());

  function handleCancel() {
    onCancel();
  }

  function handleSave() {
    const interests = categories.filter((category) => category.selected === true).map((i) => i.id);

    onSave(interests);
    onCancel();
  }

  const selecteCounter = () => {
    return categories.filter((category) => category.selected === true).length;
  };

  const handleClick = (idx: number) => () => {
    categories[idx].selected = !categories[idx].selected;

    const selected = selecteCounter();

    categories.forEach((category) => {
      category.disabled = false;
    });

    if (selected === 3) {
      categories
        .filter((category) => category.selected === false)
        .forEach((category) => {
          category.disabled = true;
        });
    }

    setCategories(JSON.parse(JSON.stringify(categories)));
  };

  return (
    <>
      <PageContainer hideProfile auth>
        <PageBody>
          <div className="relative flex w-full flex-col gap-8 lg:px-5" style={{ marginTop: -21, marginBottom: 80 }}>
            <BodyHeader title={t("user.setting.interests.title")} />
            <h3 style={{ fontSize: "14px" }} className="-mt-2 w-[300px] font-light leading-5 text-text-paragraph">
              {t("user.setup.interests.paragraph")}
            </h3>

            <div className="scroll -mt-4 flex w-full flex-row flex-wrap gap-2">
              {categories.map((category, idx) => (
                <Chip
                  key={category.id}
                  label={category.name}
                  variant={category.selected ? "filled" : "outlined"}
                  onClick={handleClick(idx)}
                  disabled={category.disabled}
                />
              ))}
            </div>
          </div>

          <FormActions className="flex gap-2.5">
            <Button variant="outline" className="mt-2 flex items-center justify-center" onClick={handleCancel}>
              {t("user.settings.form.cancelLabel")}
            </Button>
            <Button variant="solid" className="mt-2 flex items-center justify-center" onClick={handleSave}>
              {t("user.settings.form.saveLabel")}
            </Button>
          </FormActions>
        </PageBody>
      </PageContainer>
    </>
  );
};
