import { useAuthService } from "app/common/auth/services";
import { InterestsRepository } from "app/common/events/services/interestsRepository";
import { UserRepository } from "app/common/user/services/userRepository";
import { SettingsPage } from "app/pages/user/settings";
import { SetupNavigation } from "app/pages/user/setup";
import { Route, Routes, useSearchParams } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { useUser } from "../../common/user/hooks/useUser";

const repo = new UserRepository();
const interestsRepository = new InterestsRepository();

export const UserNavigation = () => {
  const authSrv = useAuthService();
  return (
    <Routes>
      <Route path="setup/*" element={<SetupNavigation />} />
      <Route
        path="settings"
        element={
          <SettingsPage
            authSrv={authSrv}
            repo={repo}
            useAuth0={useAuth0}
            useUser={useUser}
            useSearchParams={useSearchParams}
            interestsRepository={interestsRepository}
          />
        }
      />
    </Routes>
  );
};
