export const settingsPage = {
  en: {
    "user.settings.title": "My profile",
    "user.settings.delete": "Delete my account",
    "user.settings.change": "Reset password",
    "user.settings.google": "Signed in with Google",
    "user.settings.apple": "Signed in with Apple",
    "user.settings.signinMethod": "Signed in with :METHOD",
    "user.settings.deleteConfirm": "Delete account? This action can not be undone",
    "user.settings.deleteAction": "Delete",
    "user.settings.updateConfirm": "Update profile? This action can not be undone",
    "user.settings.updateAction": "Update",
    "user.settings.form.slugLabel": "Link",
    "user.settings.form.birthday-date": "Birthday date",
    "user.settings.form.your-name": "Name",
    "user.settings.form.nickname": "Name",
    "user.settings.form.phone": "Phone (optional)",
    "user.setup.birthday.phoneHint":
      "No worries, we'll just use this number to give you a friendly heads up about any upcoming birthdays.",
    "user.settings.form.email": "Email (optional)",
    "user.settings.form.emailHint": "We’ll use this email to help you send greetings.",
    "user.settings.form.slug": "Slug is not available",
    "user.settings.signinMethod.description": "Your email is your user ID and therefore cannot be modified.",
    "user.settings.form.saveLabel": "Save",
    "user.settings.form.cancelLabel": "Cancel",

    "user.settings.deleteDecision.confirm": "Do you want to keep your friends connnected with your birthday?",
    "user.settings.deleteDecision.acceptLabel": "Delete connections",
    "user.settings.deleteDecision.cancelLabel": "Keep my connections",

    "user.settings.reset.confirm": "Reset password? We'll send you an email with intructions to reset your password",
    "user.settings.reset.acceptLabel": "Send email",
    "user.settings.form.interests": "Top 3 gift’s interests",
    "user.setup.birthday.interestsHint": "We'll use this information to send you some great gift ideas.",

    "user.settings.noInterests": "There are no interests selected.",
  },
  es: {
    "user.settings.title": "Mi perfil",
    "user.settings.delete": "Borrar mi cuenta",
    "user.settings.change": "Restablecer contraseña",
    "user.settings.google": "Sesión iniciada con Google",
    "user.settings.apple": "Sesión iniciada con Apple",
    "user.settings.signinMethod": "Sessión iniciada con :METHOD",
    "user.settings.slugLabel": "Enlace",
    "user.settings.deleteConfirm": "¿Borrar cuenta? Esta acción no se puede deshacer",
    "user.settings.deleteAction": "Eliminar",
    "user.settings.updateConfirm": "¿Actualización del perfil? Esta acción no se puede deshacer",
    "user.settings.updateAction": "Actualizar",
    "user.settings.form.birthday-date": "Fecha de cumpleaños",
    "user.settings.form.your-name": "Nombre",
    "user.settings.form.nickname": "Nombre",
    "user.settings.form.phone": "Teléfono (opcional)",
    "user.setup.birthday.phoneHint":
      "No se preocupe, solo usaremos este número para darle un aviso amistoso sobre los próximos cumpleaños.",
    "user.settings.form.email": "Email (opcional)",
    "user.settings.form.emailHint": "Usaremos este correo electrónico para ayudarlo a enviar saludos.",
    "user.settings.form.slug": "Slug no está disponible",
    "user.settings.signinMethod.description":
      "Su correo electrónico es su ID de usuario y, por lo tanto, no puede modificarse.",
    "user.settings.form.saveLabel": "Guardar",
    "user.settings.form.cancelLabel": "Cancelar",

    "user.settings.deleteDecision.confirm": "¿Quieres mantener a tus amigos conectados con tu cumpleaños?",
    "user.settings.deleteDecision.acceptLabel": "Eliminar conexiones",
    "user.settings.deleteDecision.cancelLabel": "Mantenga mis conexiones",
    "user.settings.form.interests": "Top 3 gift’s interests",
    "user.setup.birthday.interestsHint": "We'll use this information to send you some great gift ideas.",
    "user.settings.noInterests": "There are no interests selected.",
  },
};
