import { EventRepository } from "app/common/events/services/eventRepository";
import { Loading } from "app/common/main/pages/Loading";
import { UserRepository } from "app/common/user/services/userRepository";
import { PageBody, PageContainer } from "layout/components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "shared/services";
import { getMonthOptions, toSnakeCase, validateDate } from "shared/utils";

import { useSuperMutation } from "@vadiun/react-hooks";

import { SetupLayout } from "../../../../common/user/setup/components/SetupLayout";
import { BirthdaySetupForm } from "../../../../common/user/setup/forms/birthdaySetupForm/BirthdaySetupForm";
import { BirthdaySetupFormType } from "../../../../common/user/setup/forms/birthdaySetupForm/BirthdaySetupFormType";

interface Props {
  eventRepository: EventRepository;
  repo: UserRepository;
  useAuth0: any;
}

export const SetupBirthdayPage: React.FC<Props> = ({ repo, eventRepository, useAuth0 }) => {
  const { user, isLoading } = useAuth0();
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userMutation = useSuperMutation(repo.updateProfileOnboard, {
    showSuccessMessage: false,
  });

  const userShareMutation = useSuperMutation(repo.updateSlug, {
    showSuccessMessage: false,
  });

  const eventMutation = useSuperMutation(eventRepository.addSubscription, {
    showSuccessMessage: false,
    showErrorMessage: false,
  });

  async function getSlug(day, month, nickname) {
    const birthdate = moment(`${day}/${month}`, "DD/MM").format("YYYY-MM-DD");
    let slug = toSnakeCase(`${nickname}-${moment(birthdate).format("MMM")} ${moment(birthdate).format("DD")}`);
    const { validated } = await repo.getSlug(slug);

    if (!validated) {
      slug = (await repo.getUniqueSlug()).slug;
    }

    return slug;
  }

  const createUser = async (values: BirthdaySetupFormType) => {
    const { day, month } = validateDate(values.day, values.month);

    await userMutation.mutateAsync({
      nickname: values.nickname,
      phone: values.phone,
      day,
      month,
      email: user?.email,
    });

    await userShareMutation.mutateAsync({
      id: user.id,
      slug: await getSlug(day, month, values.nickname),
    });
  };

  async function onSubmit(values: BirthdaySetupFormType) {
    await createUser(values);
    LocalStorage.joinUsFlow.remove();

    const { enabled, slug } = LocalStorage.signupAndBday.get();

    if (enabled) {
      return navigate(`/add-their-bday?slug=${slug}`);
    }

    navigate("/user/setup/interests");
  }

  useEffect(() => {
    //Join us flow - subscription data
    const subscriptionData = LocalStorage.joinUsFlow.get();

    if (subscriptionData !== null) {
      setIsCreatingUser(true);

      createUser(subscriptionData).then(() => {
        if (subscriptionData.addRequesterBirthday) {
          eventMutation
            .mutateAsync(subscriptionData.requesterSlug)
            .then((e) => {
              const date = e.birthday.split("T")[0];
              const birthday = moment(date);

              navigate(`/main/events/display?day=${birthday.date()}&month=${birthday.month() + 1}`);
            })
            .catch(() => {
              navigate("/main/events/display");
            })
            .finally(() => {
              LocalStorage.joinUsFlow.remove();
            });
        } else {
          LocalStorage.joinUsFlow.remove();
          navigate("/user/setup/interests");
        }
      });
    }
  }, []);

  if (isLoading) return <Loading />;

  if (isCreatingUser) return <Loading />;

  let initialValues: BirthdaySetupFormType | undefined = undefined;

  return (
    <PageContainer footer hideProfile>
      <PageBody>
        <SetupLayout title={t("user.setup.birthday.title")} titleVariant="small">
          <div className="flex w-full flex-col gap-6 lg:px-5">
            {/* Birthdate form */}
            <div className="flex w-full flex-col gap-8">
              <BirthdaySetupForm monthOptions={getMonthOptions} onSubmit={onSubmit} initialValues={initialValues} />
            </div>
          </div>
        </SetupLayout>
      </PageBody>
    </PageContainer>
  );
};
