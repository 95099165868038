import { User } from "app/common/user/models/User";
import moment from "moment";
import { httpClient } from "shared/services/httpClient";
import { animals, colors, NumberDictionary, uniqueNamesGenerator } from "unique-names-generator";

import { Slug } from "../models/Slug";

export const getUserMapper = (x: any): User =>
  new User({
    ...x,
    profile: {
      ...x.profile,
      onboarding: Boolean(x.profile.onboarding),
    },
  });

export const getSlugMapper = (x: any): Slug => new Slug(x);

export const getUniqueSlugMapper = (x: any): IUniqueSlug => x as IUniqueSlug;

interface IUniqueSlug {
  slug: string;
}

export const updateProfileMapper = (x: IUpdateUser) => ({
  birthdate: moment(`${x.day}/${x.month}`, "DD/MM").format("YYYY-MM-DD"),
  nickname: x.nickname,
  phone: x.phone ?? x.user.profile.phone,
  slug: x.user.profile.slug,
  email: x.email,
  interests: x.interests,
});

export const updateProfileOnBoardMapper = (x: ICreateProfile) => ({
  birthdate: moment(`${x.day}/${x.month}`, "DD/MM").format("YYYY-MM-DD"),
  nickname: x.nickname,
  phone: x.phone,
  email: x.email,
});

const updateSlugMapper = (x: IUpdateSlug) => ({
  slug: x.slug,
  onboarding: true,
});

interface IUpdateUser {
  user: User;
  day: number;
  month: string;
  nickname: string;
  phone: string | undefined;
  email: string | undefined;
  interests: number[];
}

interface ICreateProfile {
  day: number;
  month: string;
  nickname: string;
  phone: string | undefined;
  email: string | undefined;
}

interface IUpdateSlug {
  id: number;
  slug: string;
}

export const updateUserMapper = (x: IUpdateUser) => x;

export class UserRepository {
  keys = {
    all: () => ["user"],
    one: (id: string) => ["user", id],
    validateSlug: (slug: string) => ["validateSlug", slug],
    uniqueSlug: () => ["slug"],
    updateSlug: (slug: string) => ["setSlug", slug],
  };

  getAll = async () => {
    const res = await httpClient.get("users");
    return res.map(getUserMapper);
  };

  get = async (id: string) => {
    const res = await httpClient.get(`users/${id}`);

    return getUserMapper(res);
  };

  getSlug = async (slug: string) => {
    const res = await httpClient.get(`validators/slug?slug=${encodeURIComponent(slug)}`);
    return getSlugMapper(res) as Slug;
  };

  getUniqueSlug = async () => {
    const numberDictionary = NumberDictionary.generate({ min: 1, max: 999 });
    let newSlug: string | undefined = undefined;
    for (let i = 0; i <= 20; i++) {
      const slug = uniqueNamesGenerator({
        dictionaries: [colors, animals, numberDictionary],
        separator: "_",
        length: 3,
      });
      const validation = await this.getSlug(slug);
      if (validation.validated) {
        newSlug = slug;
        break;
      }
    }
    if (newSlug === undefined) {
      // TODO: apply i18n
      throw new Error("Could not create a random slug. Try again later");
    }
    return getUniqueSlugMapper({ slug: newSlug });
  };

  updateProfile = async (x: IUpdateUser) => httpClient.put(`profiles/me`, updateProfileMapper(x));

  updateProfileOnboard = async (x: ICreateProfile) =>
    httpClient.post(`users/me/onboarding`, updateProfileOnBoardMapper(x));

  updateSlug = async (x: IUpdateSlug) => httpClient.put(`profiles-slug/me`, updateSlugMapper(x));

  delete = async (id: string) => httpClient.delete(`users/${id}`);
}
