import { getBaseUrl } from "app/common/helpers";
import { Loading } from "app/common/main/pages/Loading";
import { UserRepository } from "app/common/user/services/userRepository";
import clouds_full from "assets/cloud_lg_share.png";
import clouds_mid from "assets/cloud_md_share.png";
import red_cloud from "assets/footer_cloud1.svg";
import T_LOGO from "assets/telegram-icon.svg";
import welcome_cloud from "assets/welcome_cloud.svg";
import WS_LOGO from "assets/whatsapp-icon.png";
import { BigHeaderLayout } from "layout/components/BigHeaderLayout";
import { Button } from "layout/components/Button/Button";
import { LinkButton } from "layout/components/Button/LinkButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swipeable, Tooltip } from "shared/components";

import styled from "@emotion/styled";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { useSuperMutation } from "@vadiun/react-hooks";

interface Props {
  repo: UserRepository;
  useUser: any;
}

const CopyLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #f582ae;
`;

export const SetupShareLink = ({ repo: UserRepository, useUser }: Props) => {
  const [drawerState, setDrawerState] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const { user, isLoadingUser } = useUser();
  const { t } = useTranslation();

  //We have to wrap the mutationFn. because share is only available in secure context
  const shareMutation = useSuperMutation((values: ShareData) => navigator.share(values), {
    showSuccessMessage: false,
    showErrorMessage: false,
  });
  if (isLoadingUser) return <Loading />;

  const { slug } = user.profile;

  const urlOrigin = window.location.origin;
  const url = `${urlOrigin}/${slug}`;
  const message = t("share.message").replace(":SLUG", slug!).replace(":URL", url);
  const wsMessage = `https://wa.me/?text=${encodeURIComponent(message + url)}`;
  const tMessage = `https://t.me/share/url?url=${urlOrigin}&text=${encodeURIComponent(message + url)}`;

  function handleCopyLink() {
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setOpenTooltip(true);
    }, 200);
  }

  function handleOpenTooltip() {
    setOpenTooltip(false);
  }

  async function handleShare() {
    const shareData: ShareData = {
      title: "Unforget",
      text: message,
      url,
    };
    try {
      if (navigator.canShare(shareData)) {
        shareMutation.mutate(shareData);
      }
    } catch (e) {
      //fallback share
      setDrawerState(true);
    }
  }
  return (
    <>
      <BigHeaderLayout
        title={t("user.setup.shareLink.title")!}
        headerBgColor="bg-brand-secondary"
        bgColor="bg-brand-primary"
        small={false}
        fullWidth
        separator={
          <>
            <div className="relative h-[48px] w-full bg-brand-secondary tablet:hidden">
              <img src={red_cloud} alt="cloud separator" className="absolute -bottom-2 w-full object-cover" />
              <img src={welcome_cloud} alt="cloud separator" className="absolute -bottom-1 w-full object-cover " />
            </div>
            <div className="relative hidden h-[84px] w-full bg-brand-secondary tablet:block md:h-[100px]">
              {/* Full width cloud separators */}
              <img
                src={clouds_full}
                alt="clouds separator"
                className="absolute -bottom-1 z-30 hidden w-full object-cover xl:block"
              />

              {/* Mid width cloud separators */}
              <img
                src={clouds_mid}
                alt="clouds separator"
                className="absolute -bottom-1 z-30 hidden w-full object-cover tablet:block xl:hidden"
              />
            </div>
          </>
        }
      >
        <div className="mx-auto flex max-w-xl flex-col items-center justify-center gap-4 bg-brand-primary px-7 pt-14">
          <Button variant="solid" onClick={handleShare}>
            {t("user.setup.shareLink.shareLabel")}
          </Button>
          <div className="flex h-4 w-full items-center">
            <div className="h-[1px] w-full bg-text-paragraph" />
            <span className="whitespace-nowrap px-2 text-sm leading-5 text-text-paragraph">
              {t("user.setup.shareLink.copyLabel")}
            </span>
            <div className="h-[1px]  w-full bg-text-paragraph" />
          </div>
          <TooltipPrimitive.Provider delayDuration={0}>
            <Tooltip
              open={openTooltip}
              onOpenChange={handleOpenTooltip}
              content={
                <span className="rounded-lg bg-button px-2 py-1 text-center text-white">
                  {t("user.setup.shareLink.copiedLabel")}
                </span>
              }
              sideOffset={5}
            >
              <Button
                variant="solid"
                color="white"
                onClick={handleCopyLink}
                className="pl-5 pr-5"
                title={`${getBaseUrl()}/${slug}`}
              >
                <span className="overflow-hidden text-ellipsis whitespace-nowrap leading-normal">
                  {getBaseUrl()}/{slug}
                </span>
                <CopyLabel>{t("user.setup.shareLink.copyAction")}</CopyLabel>
              </Button>
            </Tooltip>
          </TooltipPrimitive.Provider>

          <LinkButton id="unforget-action" to="/main/events/display" replace variant="text">
            {t("user.setup.shareLink.calendarLabel")}
          </LinkButton>
        </div>
      </BigHeaderLayout>
      <Swipeable
        state={drawerState}
        onClose={() => setDrawerState(false)}
        onOpen={() => setDrawerState(true)}
        title={t("user.setup.shareLink.send")!}
      >
        <a target="_blank" href={wsMessage} rel="noreferrer">
          <div className="flex flex-col items-center">
            <img width="36px" src={WS_LOGO} alt="whatsapp logo" />
            <span className="text-xs" style={{ marginTop: "8px" }}>
              {t("share.whatsapp")}
            </span>
          </div>
        </a>
        <a target="_blank" href={tMessage} rel="noreferrer">
          <div className="flex flex-col items-center">
            <img width="36px" src={T_LOGO} alt="telegram logo" />
            <span className="text-xs" style={{ marginTop: "8px" }}>
              Telegram
            </span>
          </div>
        </a>
      </Swipeable>
    </>
  );
};
