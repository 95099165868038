export const setupLinkExplanation = {
  en: {
    "user.setup.link.explanation": "Ask your friends to add their birthday to your calendar",
    "user.setup.link.skipLabel": "Skip",
  },
  es: {
    "user.setup.link.explanation": "Pídale a sus amigos que agregen su cumpleaños a su calendario",
    "user.setup.link.skipLabel": "Omitir",
  },
};
