import "moment/locale/es";

import moment from "moment";

export interface MonthOptions {
  label: string;
  value: string;
}

export interface MonthDaysOptions {
  label: string;
  value: string;
}

export function validateDate(day: number, month: string) {
  if (day === 29 && month === "02") {
    day = 28;
  }

  return day === 29 && month === "02" ? { day: day - 1, month } : { day, month };
}

export const getMonthOptions = Array.apply(0, Array(12)).map(function (_, i) {
  moment.locale(navigator.language);
  const month = moment().month(i);
  const monthValue = month.format("MM");
  const lowerCaseMonth = month.format("MMMM");
  return {
    label: lowerCaseMonth.charAt(0).toUpperCase() + lowerCaseMonth.slice(1),
    value: monthValue,
  } as MonthOptions;
});

export const getMonthDaysOptions = (month) => {
  moment.locale(navigator.language);
  const days: MonthDaysOptions[] = [];
  const length = moment(`${moment().year()}-${month}`, "YYYY-MM").daysInMonth();

  Array.from({ length }, (v, k) => k + 1).forEach((i) => {
    const day = i.toString();

    days.push({
      label: day,
      value: day,
    });
  });

  return days;
};

export enum SIGNED_METHOD {
  GOOGLE = "Google",
  APPLE = "Apple",
  AUTH0 = "auth0",
}

export const userSignedMethod = (userId: string): SIGNED_METHOD => {
  return userId.includes("google-oauth2")
    ? SIGNED_METHOD.GOOGLE
    : userId.includes("auth0")
    ? SIGNED_METHOD.AUTH0
    : SIGNED_METHOD.APPLE;
};

export const toSnakeCase = (str = "") => {
  const strArr = str.split(" ");
  const snakeArr = strArr.reduce((acc, val: any) => {
    return acc.concat(val.toLowerCase());
  }, []);
  return snakeArr.join("_");
};
