interface Profile {
  birthdate: string | null;
  id: number;
  nickname: string;
  onboarding: boolean;
  phone: string;
  slug: string;
  interests: any;
}

interface IUser {
  id: number;
  name: string | null;
  profile: Profile;
}

export class User {
  id: number;
  name: string | null;
  profile: Profile;

  constructor(args: IUser) {
    this.id = args.id;
    this.name = args.name;
    this.profile = args.profile;
  }
}
