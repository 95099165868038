export const slugSharePage = {
  en: {
    "slug.share.title": "Do you want to add your birthday to :SLUG’s calendar?",
    "slug.share.share": "Add my birthday",
    "slug.share.cancel": "Nope, don’t add",
    "slug.share.already": "you are already subscribed",
  },
  es: {
    "slug.share.title": "¿Quieres agregar tu cumpleaños a calendario de :SLUG?",
    "slug.share.share": "Agregar mi cumpleaños",
    "slug.share.cancel": "No, No agregar",
    "slug.share.already": "ya estas suscrito",
  },
};
