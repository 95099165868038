import { Box, Divider, SwipeableDrawer } from "@mui/material";

interface Props {
  state: boolean;
  onClose: () => void;
  onOpen: () => void;
  children: React.ReactNode;
  title?: string;
}

export const Swipeable: React.FC<Props> = ({ state, onClose, onOpen, title, children }) => {
  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={state}
      onClose={onClose}
      onOpen={onOpen}
      sx={{
        "& .MuiDrawer-paper": {
          borderRadius: 0,
          maxWidth: "576px",
          margin: "auto",
        },
      }}
    >
      <div className="flex h-full flex-col">
        <Box sx={{ padding: "15px 10px", fontSize: "15px" }}>{title ?? "Share via"}</Box>
        <Divider />
        <Box
          sx={{
            padding: "15px 10px",
            display: "grid",
            gridTemplateColumns: "repeat(4, 90px)",
            rowGap: "2rem",
          }}
        >
          {children}
        </Box>
      </div>
    </SwipeableDrawer>
  );
};
