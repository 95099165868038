import { useQueryClient } from 'react-query';

import { useSuperMutation } from '@vadiun/react-hooks';

import { EventRepository } from '../services/eventRepository';

const repo = new EventRepository();

export const useSendGreetings = () => {
  const queryClient = useQueryClient();
  const sendGreetingsMutation = useSuperMutation(repo.sendGreetings, {
    showSuccessMessage: false,
  });

  async function handleSendGreetings(
    eventId: number,
    invalidateQuery: "today" | "futureday" | "pastday" | "all"
  ) {
    await sendGreetingsMutation.mutateAsync(eventId);

    if (invalidateQuery === "today") {
      await queryClient.invalidateQueries(["calendarEvents"]);
      return;
    }
    if (invalidateQuery === "all") {
      await queryClient.invalidateQueries({active: true})
      return;
    }
    const key = invalidateQuery === "futureday" ? "upcomingEvents" : "pastEvents";
    await queryClient.refetchQueries(repo.keys[key]());
  }

  return {
    sendGreetings: handleSendGreetings,
  };
};
