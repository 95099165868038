import logo from "assets/logo.png";
import welcome from "assets/welcome.png";
import WelcomeCloud from "assets/welcome_cloud.svg";
import WelcomeCloudFull from "assets/welcome_cloud_full.png";
import WelcomeCloudMid from "assets/welcome_cloud_mid.png";
import { Title } from "layout/components/Title";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { AuthContextType } from "../../../common/auth/services";

interface Props {
  authSrv: AuthContextType;
}

export const WelcomePage: React.FC<Props> = ({ authSrv }) => {
  const { t } = useTranslation();

  function handleLogin() {
    authSrv.login();
  }

  function handleSignup() {
    authSrv.signup();
  }

  return (
    <div className="z-0 flex h-full w-full flex-col bg-brand-primary">
      <div className="flex w-full flex-col gap-8">
        <div className={`relative z-10 flex h-[345px] w-full flex-col gap-8 overflow-hidden`}>
          <img
            alt={"cloud illustration"}
            src={WelcomeCloud}
            className="absolute inset-x-0 -bottom-1 w-full object-cover tablet:hidden"
          />
          <img
            alt={"cloud illustration"}
            src={WelcomeCloudMid}
            className="absolute inset-x-0 -bottom-1 hidden w-full object-cover tablet:block xl:hidden"
          />
          <img
            alt={"cloud illustration"}
            src={WelcomeCloudFull}
            className="absolute inset-x-0 -bottom-1 hidden w-full object-cover tablet:hidden xl:block"
          />
          <img
            src={welcome}
            className="absolute inset-x-0 top-0 -z-20 h-[344px] w-full object-cover"
            alt="background"
          />
          <div className={`text-primary z-10 flex h-16 flex-row items-center justify-center px-4 md:px-8`}>
            <img alt="unforget logo" src={logo} className={`absolute right-0 left-0 mx-auto w-[89px] object-cover`} />
          </div>
        </div>
        {/* Header */}
        <div className="mx-auto flex w-full max-w-xl flex-col gap-8 bg-brand-primary px-5">
          <div className="flex flex-col gap-3">
            <Title variant="normal">{t("welcomePage.title")}</Title>
            <p className="text-center text-sm font-normal leading-5">{t("welcomePage.subtitle")}</p>
          </div>
          {/* CTA */}
          <div className="flex w-full flex-col gap-5">
            <Button
              id="unforget-signup"
              onClick={handleSignup}
              className="text-primary-600 duration-0 flex h-14 w-full items-center justify-center rounded-4xl bg-button font-medium leading-4 tracking-wider text-text-button transition hover:bg-hover hover:duration-150 focus:bg-focus"
            >
              {t("welcomePage.signup")}
            </Button>
            <p className="text-xs text-center font-normal leading-3 tracking-wider">
              {t("welcomePage.text")}{" "}
              <button
                id="unforget-login"
                onClick={handleLogin}
                className="font-bold leading-3 tracking-wider text-button focus:text-focus"
              >
                {t("welcomePage.login")}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
