import React from "react";

interface Props {
  variant: "small" | "normal";
  children: React.ReactNode;
  setup?: boolean;
}

export const Title: React.FC<Props> = ({ children, variant, setup }) => {
  const textVariant = variant === "small" ? "text-3xl font-medium px-2" : "text-4xl font-bold";

  return (
    <span id="unforget-title" className={`text-center ${textVariant} z-30 leading-10 text-text-title`}>
      {children}
    </span>
  );
};
