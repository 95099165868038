import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalStorage } from "shared/services";

import { useAuth0 } from "@auth0/auth0-react";

import { useAuthRepository } from "./AuthRepository";

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  authenticateUser: (access_token: string) => void;
  forgotPassword: (email: string) => Promise<any>;
  login: (email?: string) => Promise<void>;
  loginWithRedirect: (redirectTo: string) => Promise<void>;
  signup: (email?: string) => Promise<void>;
  changePassword: (x: { password: string }) => Promise<void>;
  logout: () => void;
  deleteLoggedUser: (deleteUser: boolean) => Promise<void>;
}
export const AuthContext = React.createContext({} as AuthContextType);

export const AuthProvider = (props: any) => {
  const { loginWithRedirect: auth0_login, logout: logoutAuth0, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const authRepo = useAuthRepository();

  const login = async (email?: string) => {
    return auth0_login({
      redirectUri: `${window.location.origin}/logged-in`,
      login_hint: email,
    });
  };

  const signup = (email?: string) => {
    return auth0_login({
      redirectUri: `${window.location.origin}/logged-in`,
      login_hint: email,
      screen_hint: "signup",
    });
  };

  const loginWithRedirect = async (redirectTo: string) => {
    const redirectUri = `/${encodeURIComponent(redirectTo)}`;
    LocalStorage.redirectUri.set(redirectUri);
    return login();
  };

  const logout = () => {
    LocalStorage.token.remove();
    logoutAuth0({ returnTo: window.location.origin });
    if (!location.pathname.includes("auth/login")) {
      navigate("auth/welcome");
      return;
    }
  };

  const deleteLoggedUser = async (deleteUser: boolean) => {
    await authRepo.deleteLoggedUser(deleteUser);
    logout();
  };

  const authenticateUser = (access_token: string) => {
    LocalStorage.token.set(access_token);
  };

  const forgotPassword = (email: string) => authRepo.forgotPassword(email);

  const changePassword = (x: { password: string }) => authRepo.changePassword(x);

  const value: AuthContextType = {
    isAuthenticated,
    isLoading,
    authenticateUser,
    forgotPassword,
    login,
    loginWithRedirect,
    signup,
    changePassword,
    logout,
    deleteLoggedUser,
  };
  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuthService = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth debe estar dentro del proveedor AuthContext");
  }
  return authContext;
};
