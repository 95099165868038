export const setupLinkPage = {
  en: {
    "user.setup.link.header": "Alright, let's get your link sorted",
    "user.setup.link.title": "Pick a short, memorable link to share with friends. Or hit the randomizer for fun!",

    "user.setup.link.validateError": "Couldn't validate the slug. Try again later.",
    "user.setup.link.generateError": "Couldn't generate a slug. Try again later.",
    "user.setup.link.errorTitle": "Oops!",

    "user.setup.link.slug.available": "Slug is available",
    "user.setup.link.slug.required": "This field is required",
    "user.setup.link.slug.invalid": 'Slug is not valid, "/" character or empty spaces are not allowed.',
    "user.setup.link.slug.notAvailable": "Slug is not available",

    "user.setup.form.slugLabel": "Link",
  },
  es: {
    "user.setup.link.header": "Muy bien, vamos a configurar tu enlace",
    "user.setup.link.title":
      "Elija un enlace corto y memorable para compartir con amigos.¡O presione al aleatorizador por diversión!",
    "user.setup.link.validateError": "No se pudo validar el slug. Vuelva a intentarlo más tarde.",
    "user.setup.link.generateError": "No se pudo generar un slug. Vuelva a intentarlo más tarde.",
    "user.setup.link.errorTitle": "Ups!",

    "user.setup.link.slug.available": "Slug está disponible",
    "user.setup.link.slug.required": "Este campo es obligatorio",
    "user.setup.link.slug.invalid": 'Slug no es válido, no se permiten caracteres "/" ni espacios vacíos.',
    "user.setup.link.slug.notAvailable": "Slug no está disponible",

    "user.settings.slugLabel": "Enlace",
  },
};
