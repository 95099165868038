interface Options {
  isOutlined: boolean;
  isError: boolean;
  isText: boolean;
  color?: "white" | "primary" | "red";
  disabled?: boolean;
  sizes?: "sm" | "md" | "lg";
}

export const createStyles = ({
  isOutlined,
  isError,
  isText,
  disabled,
  color,
  sizes,
}: Options) => {
  const solidClass = `transition duration-0 ${
    color === "white" ? "bg-brand-white" : "bg-button"
  } text-text-button hover:bg-hover focus:bg-focus hover:duration-150`;

  const outlineClass = "bg-transparent border-text-button text-text-button";
  const errorClass =
    "transition duration-0 bg-status-error text-text-button hover:bg-hover focus:bg-focus hover:duration-150";
  const textClass = `${
    color === "red" ? "text-status-error" : "text-text-button"
  } text-base font-medium`;

  const variantClass = isOutlined
    ? outlineClass
    : isError
    ? errorClass
    : isText
    ? textClass
    : solidClass;

  const borderClass = isOutlined ? "border-text-button" : "border-hidden";

  const sizeClass =
    sizes === "sm"
      ? "h-10 text-sm"
      : sizes === "lg"
      ? "h-16 text-base"
      : "h-14 text-base";

  const disableClass = disabled ? "opacity-50" : "";

  return ` ${disableClass} ${borderClass} ${sizeClass} w-full rounded-4xl flex w-full items-center justify-center gap-2.5 border font-medium leading-4 tracking-wider ${variantClass}`;
};
