export const welcomePage = {
  en: {
    "welcomePage.title": "Never let a birthday slip away",
    "welcomePage.subtitle": "Don't join the thousands who forget birthdays. Stand out by remembering them all!",
    "welcomePage.signup": "Sign up",
    "welcomePage.text": "Already have an account?",
    "welcomePage.login": "Log in",
  },
  es: {
    "welcomePage.title": "Nunca olvides otro cumpleaños!",
    "welcomePage.subtitle": "No te unas a los miles que olvidan los cumpleaños. ¡Destaca recordándolos a todos!",
    "welcomePage.signup": "Regstrarse",
    "welcomePage.text": "¿Ya tienes una cuenta?",
    "welcomePage.login": "Iniciar sessión",
  },
};
