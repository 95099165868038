export const addBirthday = {
  en: {
    "birthday.details": "Add birthday",
    "birthday.description": "Enter your friend’s birthday info.",
  },
  es: {
    "birthday.details": "Agregar cumpleaños",
    "birthday.description": "Ingrese la información de cumpleaños de su amigo.",
  },
};
