import { EventRepository } from "app/common/events/services/eventRepository";
import { PageBody, PageContainer } from "layout/components";
import { BodyHeader } from "layout/components/BodyHeader";
import { debounceButtonAnimation } from "layout/hooks/useButtonAnimation";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getMonthOptions, validateDate } from "shared/utils";

import { useSuperMutation } from "@vadiun/react-hooks";

import { AddBirthdayForm } from "../../../../common/events/forms/addBirthdayForm/AddBirthdayForm";
import { AddBirthdayFormType } from "../../../../common/events/forms/addBirthdayForm/AddBirthdayFormType";

interface Props {
  repo: EventRepository;
}

export const AddBirthdayPage = ({ repo }: Props) => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const day = searchParams.get("day") || "";
  const month = searchParams.get("month") || "";
  const navigate = useNavigate();

  const saveMutation = useSuperMutation(repo.create, {
    showSuccessMessage: false,
    showErrorMessage: false,
    onSuccess: navigateBack,
    onError: () => navigate("/error"),
  });

  function navigateBack(values) {
    debounceButtonAnimation(() => navigate(`/main/events/display?day=${values.day}&month=${values.month}`));
  }

  const saveAnotherMutation = useSuperMutation(repo.create, {
    showSuccessMessage: false,
    showErrorMessage: false,
    onError: () => navigate("/error"),
  });

  async function handleSubmit(values: AddBirthdayFormType) {
    const { day, month } = validateDate(values.day, values.month);
    const { nickname, email, phone } = values;

    return values.type === "single"
      ? await saveMutation.mutateAsync({ nickname, email, phone, day, month })
      : await saveAnotherMutation.mutateAsync({
          nickname,
          email,
          phone,
          day,
          month,
        });
  }

  const momentMonth = moment(`${month.length > 0 ? month : 1}`, "MM").format("MM");

  const initialValues = {
    day: day.length > 0 ? parseInt(day, 10) : 1,
    month: momentMonth,
    nickname: "",
    email: "",
    phone: "",
    type: "single",
  };

  return (
    <PageContainer child footer>
      <PageBody>
        <div className="flex flex-col gap-8 lg:px-5">
          <BodyHeader title={t("birthday.details")}>
            <BodyHeader.Description>{t("birthday.description")}</BodyHeader.Description>
          </BodyHeader>
          <AddBirthdayForm
            onSubmit={handleSubmit}
            successSave={saveMutation.isSuccess}
            isSubmitting={saveMutation.isLoading}
            successSaveAnother={saveAnotherMutation.isSuccess}
            isSubmittingAnother={saveAnotherMutation.isLoading}
            monthOptions={getMonthOptions}
            initialValues={initialValues}
          />
        </div>
      </PageBody>
    </PageContainer>
  );
};
