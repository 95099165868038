import moment from "moment";
import { useState } from "react";

export interface DateDescriptor {
  date: number;
  month: number;
  year: number;
  startOfMonth: number;
  endOfMonth: number;
  startOfThisMonth: string;
  endOfThisMonth: string;
}
/**
 * Summary. Returns a currentDateDescriptor and methods to modify the instance. You also
 * have access to an individual instance with the current date.
 *
 *
 * @returns {DateDescriptor} currentDateDescriptor - Mutable current date descriptor.
 * @returns {Number} selectedDay - Unlinked from descriptor day selected via HandleSelectDay.
 * @returns {Moment} todayMoment - Unlinked moment with current date.
 * @returns {*} handleSelectDay - Set value of selectedDay.
 * @returns {*} subtractMonth - Subtracts 1 month to currentDateDescriptor instance.
 * @returns {*} addMonth - Adds 1 month to currentDateDescriptor instance.
 */
export function useDate(queryDate?, queryMonth?) {
  const fixedMoment = moment();
  const nowMoment =
    queryDate && queryMonth ? moment(`${moment().year()}-${queryMonth}-${queryDate}`, "YYYY-MM-DD") : moment();
  const date = queryDate || nowMoment.date();
  const month = queryMonth ? queryMonth - 1 : nowMoment.month();
  const year = nowMoment.year();
  const startOfMonth = nowMoment.startOf("month").day();
  const endOfMonth = nowMoment.endOf("month").date();

  const [selectedDay, setSelectedButton] = useState(date);
  const initialValue = {
    date,
    month,
    year,
    startOfMonth,
    endOfMonth,
    startOfThisMonth: nowMoment.startOf("month").format("YYYY-MM-DD"),
    endOfThisMonth: nowMoment.endOf("month").format("YYYY-MM-DD"),
  };
  const [now, setNow] = useState<DateDescriptor>(initialValue);

  function handleSelectDay(day: number) {
    setSelectedButton(day);

    const stateMoment = moment(now);
    const prevMonth = moment(stateMoment).date(day);
    const newDateString = `${prevMonth.format("YYYY-MM-DD")}`;

    setNow({
      date: prevMonth.date(),
      month: prevMonth.month(),
      year: prevMonth.year(),
      startOfMonth: prevMonth.startOf("month").day(),
      endOfMonth: prevMonth.endOf("month").date(),
      startOfThisMonth: moment(newDateString, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD"),
      endOfThisMonth: moment(newDateString, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD"),
    });
  }

  /**
   * Summary. Subtracts a day of currentDateDescriptor instance.
   */
  function subtractDay() {
    const stateMoment = moment(now);
    const prevMonth = moment(stateMoment).subtract(1, "day");
    const newDateString = `${prevMonth.format("YYYY-MM-DD")}`;

    setSelectedButton(prevMonth.date());

    setNow({
      date: prevMonth.date(),
      month: prevMonth.month(),
      year: prevMonth.year(),
      startOfMonth: prevMonth.startOf("month").day(),
      endOfMonth: prevMonth.endOf("month").date(),
      startOfThisMonth: moment(newDateString, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD"),
      endOfThisMonth: moment(newDateString, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD"),
    });
  }

  /**
   * Summary. Adds a day of currentDateDescriptor instance
   */
  function addDay() {
    const stateMoment = moment(now);
    const nextMonth = moment(stateMoment).add(1, "day");
    const newDateString = `${nextMonth.format("YYYY-MM-DD")}`;

    setSelectedButton(nextMonth.date());

    setNow({
      date: nextMonth.date(),
      month: nextMonth.month(),
      year: nextMonth.year(),
      startOfMonth: nextMonth.startOf("month").day(),
      endOfMonth: nextMonth.endOf("month").date(),
      startOfThisMonth: moment(newDateString, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD"),
      endOfThisMonth: moment(newDateString, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD"),
    });
  }

  /**
   * Summary. Subtracts a month of currentDateDescriptor instance.
   */
  function subtractMonth() {
    const stateMoment = moment(now);
    const prevMonth = moment(stateMoment).subtract(1, "months");
    const newDateString = `${prevMonth.format("YYYY-MM-DD")}`;

    if (prevMonth.date() < selectedDay) setSelectedButton(prevMonth.date());

    setNow({
      date: prevMonth.date(),
      month: prevMonth.month(),
      year: prevMonth.year(),
      startOfMonth: prevMonth.startOf("month").day(),
      endOfMonth: prevMonth.endOf("month").date(),
      startOfThisMonth: moment(newDateString, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD"),
      endOfThisMonth: moment(newDateString, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD"),
    });
  }

  /**
   * Summary. Adds a month of currentDateDescriptor instance
   */
  function addMonth() {
    const stateMoment = moment(now);
    const nextMonth = moment(stateMoment).add(1, "months");
    const newDateString = `${nextMonth.format("YYYY-MM-DD")}`;

    if (nextMonth.date() < selectedDay) setSelectedButton(nextMonth.date());

    setNow({
      date: nextMonth.date(),
      month: nextMonth.month(),
      year: nextMonth.year(),
      startOfMonth: nextMonth.startOf("month").day(),
      endOfMonth: nextMonth.endOf("month").date(),
      startOfThisMonth: moment(newDateString, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD"),
      endOfThisMonth: moment(newDateString, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD"),
    });
  }

  /**
   * Summary. Navigates to today.
   */
  function goToToday() {
    const today = moment();
    const todayString = `${today.format("YYYY-MM-DD")}`;

    setSelectedButton(today.date());

    setNow({
      date: today.date(),
      month: today.month(),
      year: today.year(),
      startOfMonth: today.startOf("month").day(),
      endOfMonth: today.endOf("month").date(),
      startOfThisMonth: moment(todayString, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD"),
      endOfThisMonth: moment(todayString, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD"),
    });
  }

  function isToday() {
    const today = moment();

    return now.date === today.date() && now.month === today.month();
  }

  return {
    currentDateDescriptor: now,
    selectedDay,
    todayMoment: fixedMoment,
    handleSelectDay,
    subtractMonth,
    addMonth,
    subtractDay,
    addDay,
    goToToday,
    isToday,
  };
}
