export interface IInterest {
  id: number;
  name: string;
}

export class Interest {
  id: number;
  name: string;

  constructor(args: IInterest) {
    this.id = args.id;
    this.name = args.name;
  }
}
