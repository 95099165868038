import { useAuthService } from "app/common/auth/services";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "shared/services";

import { useAuth0 } from "@auth0/auth0-react";
import { GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js/dist/typings/global";

export const useSetAuthToken = () => {
  const { authenticateUser } = useAuthService();
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const navigate = useNavigate();

  const navigation_options = {
    replace: true,
  };

  const goToHomePage = () => navigate("/main/events/display", navigation_options);
  const goToError = () => navigate("/error", navigation_options);
  const goToUri = (redirectUri: string) => navigate(redirectUri, navigation_options);

  const onSuccessLogin = (res: GetTokenSilentlyVerboseResponse) => {
    authenticateUser(res.access_token);

    //if login was referred
    const redirectUri = LocalStorage.redirectUri.get();

    return redirectUri ? handleloginWithRedirection(redirectUri) : goToHomePage();
  };

  function handleloginWithRedirection(redirectUri: string) {
    LocalStorage.redirectUri.remove();
    goToUri(decodeURIComponent(redirectUri));
  }

  useEffect(() => {
    if (!isLoading) {
      getAccessTokenSilently({ detailedResponse: true }).then(onSuccessLogin).catch(goToError);
    }
  }, [isLoading]);
};
