import clouds_full from "assets/cloud_lg_share.png";
import clouds_mid from "assets/cloud_md_share.png";
import cloud_setup from "assets/cloud_slug.svg";
import red_cloud from "assets/footer_cloud1.svg";
import welcome_cloud from "assets/welcome_cloud.svg";
import { BigHeaderLayout } from "layout/components/BigHeaderLayout";
import { LinkButton } from "layout/components/Button/LinkButton";
import { Title } from "layout/components/Title";
import { useTranslation } from "react-i18next";

interface Props {
  slug: string;
}
export const AlreadyAdded = ({ slug }: Props) => {
  const { t } = useTranslation();
  return (
    <BigHeaderLayout
      headerCloud={<img src={cloud_setup} alt="cloud" className="z-0 mt-4 flex h-6 self-start" />}
      small
      fullWidth
      headerBgColor={"bg-brand-secondary"}
      bgColor={"bg-brand-primary"}
      separator={
        <>
          <div className="relative h-[60px] w-full bg-brand-secondary tablet:hidden">
            <img src={red_cloud} alt="cloud separator" className="absolute -bottom-2 w-full object-cover" />
            <img src={welcome_cloud} alt="cloud separator" className="absolute -bottom-1 w-full object-cover " />
          </div>
          <div className="relative hidden h-[84px] w-full bg-brand-secondary tablet:block">
            {/* Full width cloud separators */}
            <img
              src={clouds_full}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover xl:block"
            />

            {/* Mid width cloud separators */}
            <img
              src={clouds_mid}
              alt="clouds separator"
              className="absolute -bottom-1 z-30 hidden w-full object-cover tablet:block xl:hidden"
            />
          </div>
        </>
      }
      footer={
        <div className="flex w-full flex-col items-center pl-6 pr-6">
          <LinkButton to="/main/events/display" replace>
            {t("slug.added.action")}
          </LinkButton>
        </div>
      }
    >
      <div className="mx-auto mt-14 flex max-w-xl flex-col gap-12 bg-brand-primary px-8 pb-8">
        <Title variant="small">{t("slug.added.title").replace(":SLUG", slug)}</Title>
      </div>
    </BigHeaderLayout>
  );
};
