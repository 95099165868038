import moment from "moment";
import * as Yup from "yup";

import { BirthdaySetupFormType } from "./BirthdaySetupFormType";

export const validation = (t): Yup.SchemaOf<BirthdaySetupFormType> =>
  Yup.object().shape({
    month: Yup.string().required(t("user.setup.birthday.validation#1")),
    day: Yup.number()
      .required(t("user.setup.birthday.validation#2"))
      .test("validBirthdate", t("user.setup.birthday.validation#3"), (value, context) => {
        const { month } = context.parent;
        if (value === undefined) return false;

        //since moment consider 1 invalid but 01 valid
        const momentCero = value < 10 ? "0" : "";

        const birthday = moment(`${momentCero}${value}-${month}`, "DD-MM", true);
        const isValidDate = birthday.isValid();
        if (month === "02" && value === 29) return true; //accept 29 of February
        return isValidDate;
      }) as any,
    nickname: Yup.string().required(t("user.setup.birthday.validation#4")),
    phone: Yup.string(),
  });
