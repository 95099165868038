import { Calendar } from "app/common/events/components/Calendar";
import { EventDescriptor } from "app/common/events/components/EventDescriptor";
import { ShareLink } from "app/common/events/components/ShareLink";
import Cloud1 from "assets/footer_cloud1.svg";
import Cloud2 from "assets/footer_cloud2.svg";
import ADD_EVENT from "assets/plus_event.svg";
import { PageContainer } from "layout/components";
import { PageBody } from "layout/components/PageBody";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LocalStorage } from "shared/services";

import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useSuperQuery } from "@vadiun/react-hooks";

import { useDate } from "../../../../common/events/hooks/useDate";
import { EventRepository } from "../../../../common/events/services/eventRepository";
import { getSelectedDayEvents, sortEvents } from "../../../../common/events/utils";

const Loader = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? (
        <div className="m-auto w-full max-w-xs pt-6">
          <LinearProgress color="primary" />
        </div>
      ) : (
        children
      )}
    </>
  );
};

const AccordionTitle = styled(Typography)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #172c66;
`;

interface Props {
  repo: EventRepository;
  useUser: any;
  useSearchParams: any;
  localStorage: typeof LocalStorage;
}

function removeQueryString() {
  const params = new URLSearchParams(window.location.search);
  params.delete("day");
  params.delete("month");
  window.history.replaceState(null, "", "?" + params + window.location.hash);
}

export const DisplayEventPage: React.FC<Props> = ({ repo, useUser, useSearchParams, localStorage }) => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();

  const { user } = useUser();
  let queryDay = searchParams.get("day");
  let queryMonth = searchParams.get("month");

  if (isNaN(Number.parseInt(queryDay)) || isNaN(Number.parseInt(queryMonth))) {
    queryDay = null;
    queryMonth = null;
  }

  removeQueryString();

  const {
    currentDateDescriptor,
    selectedDay,
    todayMoment,
    handleSelectDay,
    addMonth,
    subtractMonth,
    subtractDay,
    addDay,
    goToToday,
    isToday,
  } = useDate(queryDay, queryMonth);

  const todayEventsQuery = useSuperQuery(
    repo.keys.calendarEvents(currentDateDescriptor.startOfThisMonth, currentDateDescriptor.endOfThisMonth),
    () => {
      return repo.getEventsOnInterval(currentDateDescriptor.startOfThisMonth, currentDateDescriptor.endOfThisMonth);
    }
  );

  const upcomingEventsQuery = useSuperQuery(repo.keys.upcomingEvents(), () => {
    return repo.getUpcomingEvents();
  });

  const pastEventsQuery = useSuperQuery(repo.keys.pastEvents(), () => {
    return repo.getPastEvents();
  });

  const [thisDayEvents, setThisDayEvents] = useState(getSelectedDayEvents(selectedDay, todayEventsQuery.data ?? []));

  const upcomingEvents = upcomingEventsQuery.data ?? [];

  const pastEvents = pastEventsQuery.data ?? [];

  function handleChangeMonth(direction: number, isExpanded) {
    if (isExpanded) {
      if (direction === -1) {
        subtractMonth();
      } else {
        addMonth();
      }
    } else {
      if (direction === -1) {
        subtractDay();
      } else {
        addDay();
      }
    }
  }

  function handleToday() {
    goToToday();
  }

  useEffect(() => {
    localStorage.selectedDate.set(currentDateDescriptor);
  }, [currentDateDescriptor]);

  useEffect(() => {
    setThisDayEvents(getSelectedDayEvents(selectedDay, todayEventsQuery.data ?? []));
  }, [todayEventsQuery.data, selectedDay]);

  if (todayEventsQuery.isLoading && pastEventsQuery.isLoading && upcomingEventsQuery.isLoading) {
    return (
      <PageContainer slug>
        <PageBody>
          <div className="flex flex-row items-center justify-center">
            <CircularProgress />
          </div>
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      footerComponent={
        <>
          <div className="relative">
            <img
              src={Cloud1}
              alt="footer cloud"
              className="absolute inset-x-0 bottom-0 z-0 w-full object-cover sm:hidden"
            />
            <img
              src={Cloud2}
              alt="footer cloud"
              className="z-1 absolute inset-x-0 bottom-0 w-full object-cover sm:hidden"
            />
          </div>
          <ShareLink slug={user.profile.slug} />
        </>
      }
    >
      <PageBody notPadding>
        <div className="relative flex flex-col gap-8" style={{ marginTop: -21 }}>
          {/* Header */}
          <div className="flex max-w-xs flex-col gap-3 px-5">
            {/* Title */}
            <h2 id="unforget-title" className="text-2xl font-medium leading-8 tracking-semiwide text-text-title">
              {t("events.displayEvent.morning").replace(":SLUG", user.profile.nickname)}
            </h2>
          </div>
          {/* Content */}
          <div className="flex flex-col">
            <Calendar
              onTodayClick={handleToday}
              onDayClick={handleSelectDay}
              changeMonth={handleChangeMonth}
              currentDate={currentDateDescriptor}
              selectedDay={selectedDay}
              today={todayMoment.date()}
              isLoading={todayEventsQuery.isLoading}
              events={todayEventsQuery.data ?? []}
              isToday={isToday}
            />

            <TabContext value={"0"}>
              {/* <Divider /> */}
              <div className="px-5">
                <TabPanel value={"0"} className="p-0">
                  <div className="flex w-full flex-col gap-2 pt-6">
                    {/* Today */}
                    <div className="relative flex flex-row justify-between">
                      <p className="text-xl font-bold leading-5 tracking-wider text-text-paragraph">
                        {isToday()
                          ? t("events.displayEvent.today")
                          : moment(`${currentDateDescriptor.month + 1}-${selectedDay}`, "MM-DD").format("DD MMMM")}
                      </p>
                      <Link
                        to={{
                          pathname: `/main/events/add-birthday`,
                          search: `?day=${selectedDay}&month=${currentDateDescriptor.month + 1}`,
                        }}
                        className="absolute right-0 flex flex-row items-center gap-3"
                      >
                        <img src={ADD_EVENT} alt="add event" className="h-10 w-10 object-cover" />
                      </Link>
                    </div>

                    {/* Today */}
                    <Loader isLoading={false}>
                      <>
                        {thisDayEvents.length === 0 ? (
                          <div>
                            <p className="pb-4 text-sm font-light leading-5 text-text-paragraph">
                              {t("events.displayEvent.ops")}
                            </p>
                            <Divider />
                          </div>
                        ) : (
                          <p className="text-sm font-light leading-5 text-text-paragraph">
                            {t("events.displayEvent.number").replace(":NUMBER", thisDayEvents.length.toString())}
                          </p>
                        )}

                        {thisDayEvents.length > 0 &&
                          thisDayEvents.map((event) => (
                            <div className="pt-2" key={event.id}>
                              <EventDescriptor key={event.id} repo={repo} event={event} />
                            </div>
                          ))}
                      </>
                    </Loader>

                    {/* Recent */}
                    <Accordion disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <AccordionTitle>{t("events.displayEvent.recent")}</AccordionTitle>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Loader isLoading={false}>
                          <Typography>
                            <div className="flex w-full flex-col gap-3 pt-6">
                              {pastEvents.length > 0 ? (
                                !pastEventsQuery.isLoading &&
                                sortEvents(pastEvents, false).map((event) => (
                                  <EventDescriptor key={event.id} repo={repo} event={event} isPastEvent />
                                ))
                              ) : (
                                <p className="text-sm font-light leading-5 text-text-paragraph">
                                  {t("events.displayEvent.noBirthdays")}
                                </p>
                              )}
                            </div>
                          </Typography>
                        </Loader>
                      </AccordionDetails>
                    </Accordion>

                    {/* Upcoming */}
                    <Accordion disableGutters className="pt-1" defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <AccordionTitle>{t("events.displayEvent.upcoming")}</AccordionTitle>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Loader isLoading={false}>
                          <Typography>
                            <div className="flex w-full flex-col gap-3 pt-6">
                              {upcomingEvents.length > 0 ? (
                                !upcomingEventsQuery.isLoading &&
                                sortEvents(upcomingEvents, true).map((event) => (
                                  <EventDescriptor key={event.id} repo={repo} event={event} isFutureEvent />
                                ))
                              ) : (
                                <p className="text-sm font-light leading-5 text-text-paragraph">
                                  {t("events.displayEvent.noBirthdays")}
                                </p>
                              )}
                            </div>
                          </Typography>
                        </Loader>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </TabPanel>
              </div>
            </TabContext>
          </div>
        </div>
      </PageBody>
    </PageContainer>
  );
};
