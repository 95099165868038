import { User } from "app/common/auth/models/User";
import axios from "axios";
import { httpClient } from "shared/services";

export interface ICreateUser {
  email: string;
  password: string;
}

const getDomain = () => process.env.REACT_APP_DOMAIN_AUTH0 ?? "";
const getClientId = () => process.env.REACT_APP_CLIENT_AUTH0 ?? "";
const getDBConnection = () => process.env.REACT_APP_AUTH0_DB_CONNECTION ?? "";

export const useAuthRepository = () => {
  const login = (x: { email: string; password: string }) => httpClient.post("login", x);

  const add = (user: ICreateUser) => httpClient.post("register", user);

  const remove = (user: User) => httpClient.delete("users/" + user.id);

  const edit = (user: User) => httpClient.put("users/" + user.id, user);

  const forgotPassword = async (email: string) => {
    const payload = {
      client_id: getClientId(),
      email: email,
      connection: getDBConnection(),
    };
    return axios.post(`https://${getDomain()}/dbconnections/change_password`, payload);
  };

  const deleteLoggedUser = (deleteUser: boolean) => httpClient.delete(`users/me?delete_connections=${deleteUser}`);

  const changePassword = (x: { password: string }) =>
    httpClient.put(`users/me/change-password`, {
      password: x.password,
    });

  return {
    login,
    add,
    remove,
    edit,
    deleteLoggedUser,
    forgotPassword,
    changePassword,
  };
};
