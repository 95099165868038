export const share = {
  en: {
    "share.title": "Share your link",
    "share.description": "Let your friends add their birthdays",
    "share.action": "SHARE",
    "share.sendTitle": "Send a message via",
    "share.whatsapp": "WhatsApp",
    "share.message": `Hey there! *:SLUG* is giving you a friendly nudge to add your birthday to their calendar. It only takes a few seconds to be unforgettable: \n\n`,
  },
  es: {
    "share.title": "Comparte tu enlace",
    "share.description": "Deja que tus amigos agreguen sus cumpleaños.",
    "share.action": "COMPARTE",
    "share.sendTitle": "Enviar un mensaje a través de",
    "share.whatsapp": "WhatsApp",
    "share.message": `¡Hola! *:SLUG* te está dando un empujoncito amistoso para agregar tu cumpleaños a su calendario. \n\nSolo tardaras unos segundos en ser inolvidable: \n\n`,
  },
};
