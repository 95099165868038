import ARROW_LEFT from "assets/arrow_left.svg";
import ARROW_RIGHT from "assets/arrow_right.svg";
import ILLUSTRATION from "assets/onboarding-3.png";
import { BigHeaderLayout } from "layout/components/BigHeaderLayout";
import { Button } from "layout/components/Button/Button";
import { Title } from "layout/components/Title";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "@emotion/styled";

const ActionsContainer = styled.div`
  justify-content: space-between;

  & div {
    display: relative !important;
    width: auto !important;
    min-width: 48px;
  }
`;

export const SetupReminderExplanation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleNext() {
    navigate("/user/setup/share");
  }

  return (
    <>
      <BigHeaderLayout
        headerBgColor="bg-brand-primary"
        bgColor="bg-brand-primary"
        small={false}
        fullWidth
        titleNode={
          <>
            <img src={ILLUSTRATION} alt="ilustration" className="m-auto h-[330px] object-contain" />
          </>
        }
        footer={
          <ActionsContainer className="flex w-full flex-row pl-6 pr-6">
            <Button
              variant="outline"
              onClick={() => {
                navigate("/user/setup");
              }}
              className="h-12 w-12"
            >
              <img src={ARROW_LEFT} className="object-cover" alt={t("user.settings.change")!} />
            </Button>
            <div className="mx-auto flex w-full max-w-xl items-center justify-center">
              <svg width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4.5" cy="4" r="4" fill="#6A7699" />
                <circle cx="20.5" cy="4" r="4" fill="#6A7699" />
                <circle cx="36.5" cy="4" r="4" fill="#F582AE" />
              </svg>
            </div>
            <Button id="unforget-next" variant="solid" onClick={handleNext} className="h-12 w-12">
              <img src={ARROW_RIGHT} className="object-cover" alt={t("user.settings.change")!} />
            </Button>
          </ActionsContainer>
        }
      >
        <div
          className="mx-auto flex w-full max-w-xl items-center px-7 pb-5 pt-2 text-center"
          style={{ minHeight: 200 }}
        >
          <Title variant="small">{t("user.setup.reminder.explanation")!}</Title>
        </div>
      </BigHeaderLayout>
    </>
  );
};
