import { FC } from "react";

interface Props {
  notPadding?: boolean;
}

export const PageBody: FC<Props> = (props) => {
  const paddingClass = props.notPadding ? "p-0 py-8" : "px-5 py-8";
  return (
    <div
      className={`mx-auto flex justify-center ${paddingClass} w-full sm:space-y-4 md:space-y-0 lg:space-x-4 xl:px-40`}
    >
      <div className="w-full max-w-xl">{props.children}</div>
    </div>
  );
};
