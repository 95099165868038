const keys = {
  REDIRECT_URI: "redirectUri",
  TOKEN: "token",
  SLUG_SETUP: "slugSetup",
  ALREADY_ADDED: "alreadyAdded",
  JOIN_US_FLOW: "join-us-flow",
  SELECTED_DATE: "selected-date",
  SINGUP_BIRTHDAY: "singup-birthday",
};
const storage = window.localStorage;

export const LocalStorage = {
  signupAndBday: {
    get: () => {
      return JSON.parse(storage.getItem(keys.SINGUP_BIRTHDAY) || "{}");
    },
    set: (value) => {
      storage.setItem(keys.SINGUP_BIRTHDAY, JSON.stringify(value));
    },
    remove: () => {
      storage.removeItem(keys.SINGUP_BIRTHDAY);
    },
  },
  selectedDate: {
    get: () => {
      return JSON.parse(storage.getItem(keys.SELECTED_DATE) || "{}");
    },
    set: (value) => {
      storage.setItem(keys.SELECTED_DATE, JSON.stringify(value));
    },
    remove: () => {
      storage.removeItem(keys.SELECTED_DATE);
    },
  },
  redirectUri: {
    get: () => {
      return storage.getItem(keys.REDIRECT_URI);
    },
    set: (value) => {
      storage.setItem(keys.REDIRECT_URI, value);
    },
    remove: () => {
      storage.removeItem(keys.REDIRECT_URI);
    },
  },
  token: {
    get: () => {
      return storage.getItem(keys.TOKEN);
    },
    set: (value) => {
      storage.setItem(keys.TOKEN, value);
    },
    remove: () => {
      storage.removeItem(keys.TOKEN);
    },
  },
  slugSetup: {
    get: () => {
      return storage.getItem(keys.SLUG_SETUP);
    },
    set: (value) => {
      storage.setItem(keys.SLUG_SETUP, value);
    },
    remove: () => {
      storage.removeItem(keys.SLUG_SETUP);
    },
  },
  alreadyAdded: {
    get: () => {
      return JSON.parse(storage.getItem(keys.ALREADY_ADDED) ?? "[]");
    },
    set: (value) => {
      storage.setItem(keys.ALREADY_ADDED, JSON.stringify(value));
    },
    remove: () => {
      storage.removeItem(keys.ALREADY_ADDED);
    },
  },
  joinUsFlow: {
    get: () => {
      const data = storage.getItem(keys.JOIN_US_FLOW);

      if (data !== null) {
        return JSON.parse(data);
      }

      return data;
    },
    set: (value) => {
      storage.setItem(keys.JOIN_US_FLOW, JSON.stringify(value));
    },
    remove: () => {
      storage.removeItem(keys.JOIN_US_FLOW);
    },
  },
};
