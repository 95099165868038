import "moment/locale/es";
import "./App.css";
import "./i18n";

import { AuthProvider } from "app/common/auth/services/AuthService";
import { AppNavigation } from "app/pages";
import { WarningDialog } from "layout/components/WarningDialog";
import moment from "moment";
import { SnackbarProvider as NotiStackProvider } from "notistack";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import { Auth0Provider } from "@auth0/auth0-react";
import { LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { createTheme, experimental_sx as sx, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { Message, Provider as RCProvider, Snackbar, Spinner } from "@vadiun/react-components";
import { MessageProvider, SnackbarProvider, SpinnerProvider, VerifyActionProvider } from "@vadiun/react-hooks";

const locale = navigator.language;

moment.locale(locale);

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>(
  (props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
  }
);

const rootElement = document.getElementById("root");

export const theme = createTheme({
  typography: {
    fontFamily: `"Poppins", sans-serif`,
  },
  palette: {
    primary: {
      main: "#172C66",
    },
    secondary: {
      main: "#90caf9",
    },
    error: { main: "#FB7171" },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#001858",
        },
        filled: {
          border: "1px solid #bdbdbd",
          backgroundColor: "rgba(33, 33, 33, 0.08)",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          ":before": {
            backgroundColor: "transparent",
          },
          ":after": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 55,
            right: 0,
            height: 1,
            opacity: 1,
            backgroundColor: "rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },

    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as any,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: sx({
          "&:hover": {
            backgroundColor: "transparent",
            outlineColor: "red",
          },
        }),
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        root: sx({
          letterSpacing: "0.025em",
          borderRadius: "10px",
          textTransform: "none",
          "&.Mui-focusVisible": {
            outlineColor: (theme: { palette: { primary: { main: string } } }) => theme.palette.primary.main,
            outlineWidth: "2px",
            outlineOffset: "2px",
            outlineStyle: "solid",
          },
        }),
      },
    },

    MuiInput: {
      styleOverrides: {
        root: sx({
          backgroundColor: "transparent",
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: sx({
          fontWeight: 500,
          fontSize: "18px",
          letterSpacing: "0.05em",
          color: "#172C66",
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        color: "primary",
        variant: "standard",
        fullWidth: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: sx({
          borderRadius: "10px",
        }),
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true,
      },
      styleOverrides: {
        root: sx({
          padding: "0 20px",
          "& .MuiTablePagination-root": {
            color: "#5F5F5F",
          },
          "& .MuiTableCell-head .MuiButton-root": {
            fontWeight: "bold",
            color: "#5F5F5F",
            letterSpacing: "0.05em",
          },
          "& .MuiTableCell-head": {
            fontWeight: "bold",
            color: "#5F5F5F",
            letterSpacing: "0.05em",
          },
          "& .MuiTableCell-body": {
            color: "#5F5F5F",
          },
          "& .MuiTableCell-root": {
            borderBottom: "1px solid #F3F3F3",
            padding: "12px",
          },
          "& .MuiTableCell-footer": {
            borderBottom: 0,
          },
          "& .MuiTableRow-hover:hover": {
            backgroundColor: "#f9fafb !important",
          },
        }),
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: sx({
          "& 	.MuiButton-root": {
            fontSize: 20,
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: sx({
          "& .MuiPaper-root": {
            borderRadius: 3,
          },
        }),
      },
    },
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const domain = process.env.REACT_APP_DOMAIN_AUTH0 ?? "";
const client = process.env.REACT_APP_CLIENT_AUTH0 ?? "";
const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";

export function App() {
  return (
    <Auth0Provider
      domain={domain}
      clientId={client}
      redirectUri={window.location.origin}
      audience={audience}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <QueryClientProvider client={queryClient}>
        <RCProvider localization="EN">
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterMoment} locale={locale}>
                <NotiStackProvider>
                  <SpinnerProvider component={Spinner}>
                    <MessageProvider component={Message}>
                      <SnackbarProvider component={Snackbar}>
                        <VerifyActionProvider component={WarningDialog}>
                          <AuthProvider>
                            <AppNavigation />
                          </AuthProvider>
                        </VerifyActionProvider>
                      </SnackbarProvider>
                    </MessageProvider>
                  </SpinnerProvider>
                </NotiStackProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </RCProvider>
      </QueryClientProvider>
    </Auth0Provider>
  );
}
