import { AuthContextType } from "app/common/auth/services";
import { InterestsRepository } from "app/common/events/services/interestsRepository";
import { Loading } from "app/common/main/pages/Loading";
import { UserRepository } from "app/common/user/services/userRepository";
import Cloud from "assets/cloud_secondary.png";
import LOGOUT_ICON from "assets/logout.svg";
import RESET_ICON from "assets/reset.svg";
import TRASH_ICON from "assets/trash.svg";
import { PageBody, PageContainer } from "layout/components";
import { BodyHeader } from "layout/components/BodyHeader";
import { debounceButtonAnimation } from "layout/hooks/useButtonAnimation";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VerifyAction } from "shared/components/VerifyAction/VerifyAction";
import { VerifyDecision } from "shared/components/VerifyDecision/VerifyDecision";
import { SIGNED_METHOD, userSignedMethod, validateDate } from "shared/utils";

import styled from "@emotion/styled";
import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";

import { SettingsForm } from "../../../common/user/forms/settingsForm/SettingsForm";
import { SettingsFormType } from "../../../common/user/forms/settingsForm/SettingsFormType";
import { User } from "../../../common/user/models/User";
import { InterestsSettingsPage } from "./interests";

const SignInMethod = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #172c66;
  padding-bottom: 8px;
  border-bottom: 1px solid #6a7699;
  cursor: default;
`;

const DeleteButton = styled.button`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #df6565;
  border-top: 1px solid #6a7699;
`;

const Button = styled.button`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #172c66;
  border-top: 1px solid #6a7699;
`;

interface Props {
  repo: UserRepository;
  authSrv: AuthContextType;
  useUser: any;
  useAuth0: any;
  useSearchParams: any;
  interestsRepository: InterestsRepository;
}

export const SettingsPage = ({ repo, authSrv, useAuth0, useUser, useSearchParams, interestsRepository }: Props) => {
  const { t } = useTranslation();
  const { user: Auth0_user, isLoading } = useAuth0();
  const { user, isLoadingUser, refetchUser } = useUser();
  const [displayInterestsPage, setdisplayInterestsPage] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();
  const isVerifyingDelete = !!searchParams.get("verify-delete");
  const isVerifyingDeleteConnection = !!searchParams.get("verify-delete-my-connection");
  const isVerifyingUpdate = !!searchParams.get("verify-update");
  const isVerifyingResetPassword = !!searchParams.get("verify-reset-password");

  const formValuesRef = useRef<SettingsFormType | undefined>();

  const navigate = useNavigate();
  const goToError = () => navigate("/error");

  const interestsQuery = useSuperQuery(interestsRepository.keys.all(), () => interestsRepository.getAll(), {
    showErrorMessage: false,
  });

  //Delete user
  const deleteUserMutation = useSuperMutation(authSrv.deleteLoggedUser, {
    showErrorMessage: false,
    showSuccessMessage: false,
  });

  const userMutation = useSuperMutation(repo.updateProfile, {
    showErrorMessage: false,
    showSuccessMessage: false,
    onSuccess: async () => {
      await refetchUser();
      debounceButtonAnimation(() => navigate("/main/events/display"));
    },
    onError: goToError,
  });

  const interestsMutation = useSuperMutation(repo.updateProfile, {
    showErrorMessage: false,
    showSuccessMessage: false,
    onSuccess: async () => {
      await refetchUser();
    },
    onError: goToError,
  });

  const changePasswordMutation = useSuperMutation(authSrv.forgotPassword, {
    showErrorMessage: false,
    showSuccessMessage: false,
    onSuccess: () => {
      debounceButtonAnimation(() => navigate("/user/settings", { replace: true }));
    },
    onError: goToError,
  });

  if (isLoadingUser || isLoading || interestsQuery.isLoading) {
    return <Loading />;
  }

  const birthday = moment(user.profile.birthdate ?? "");
  const day = parseInt(birthday.format("DD"), 10);
  const month = birthday.format("MM");
  const initialValues: SettingsFormType = {
    day: day,
    month: month,
    nickname: user.profile.nickname,
    phone: user.profile.phone ?? "",
    slug: user.profile.slug,
    interests: user.profile.interests,
  };

  async function handleChangePassword() {
    changePasswordMutation.mutateAsync(Auth0_user?.email ?? "");
  }

  const verifyChangePassword = () => setSearchParams(`verify-reset-password=true`);

  async function handleSubmit(values: SettingsFormType) {
    formValuesRef.current = values;
    setSearchParams(`verify-update=true`);
    return;
  }

  async function updateUser(interests?) {
    const values = formValuesRef.current ?? initialValues;

    const { day, month } = validateDate(values.day, values.month);
    const userProfile: User = {
      ...user,
      profile: {
        ...user.profile,
        slug: values.slug,
      },
    };

    const mutation = interests ? interestsMutation : userMutation;

    await mutation.mutateAsync({
      user: userProfile,
      ...values,
      day,
      month,
      email: Auth0_user?.email,
      interests: interests || values.interests.map((interest) => interest.id),
    });
  }

  function handleVerifyDeleteAccount() {
    setSearchParams(`verify-delete=true`);
    return;
  }

  async function handleDeleteAccount() {
    setSearchParams(`verify-delete-my-connection=true`);
  }

  async function handleDeleteConfirmation(confirmation: boolean) {
    await deleteUserMutation.mutateAsync(confirmation);
  }

  function handleLogout() {
    authSrv.logout();
  }

  function getUser(email: string) {
    if (typeof email === "string" && email.indexOf("@") >= 0) {
      return ` (${email.split("@")[0]})`;
    }

    return null;
  }

  function onInterestsChange() {
    setdisplayInterestsPage(true);
  }

  function handleCancel() {
    setdisplayInterestsPage(false);
  }

  return (
    <>
      {isVerifyingDelete ? (
        <VerifyAction
          onAccept={handleDeleteAccount}
          isLoading={deleteUserMutation.isLoading}
          success={deleteUserMutation.isSuccess}
          msg={t("user.settings.deleteConfirm")}
          acceptMsg={t("user.settings.deleteAction")}
        />
      ) : isVerifyingDeleteConnection ? (
        <VerifyDecision
          onDecision={handleDeleteConfirmation}
          isLoading={deleteUserMutation.isLoading}
          success={deleteUserMutation.isSuccess}
          msg={t("user.settings.deleteDecision.confirm")}
          acceptLabel={t("user.settings.deleteDecision.acceptLabel")}
          denyLabel={t("user.settings.deleteDecision.cancelLabel")}
        />
      ) : isVerifyingUpdate ? (
        <VerifyAction
          onAccept={() => updateUser()}
          success={userMutation.isSuccess}
          isLoading={userMutation.isLoading}
          msg={t("user.settings.updateConfirm")}
          acceptMsg={t("user.settings.updateAction")}
        />
      ) : isVerifyingResetPassword /* Remove: This path is not in use! */ ? (
        <VerifyAction
          onAccept={handleChangePassword}
          success={changePasswordMutation.isSuccess}
          isLoading={changePasswordMutation.isLoading}
          msg={t("user.settings.reset.confirm")}
          acceptMsg={t("user.settings.reset.acceptLabel")}
        />
      ) : displayInterestsPage ? (
        <InterestsSettingsPage
          selectedInterests={initialValues.interests}
          interests={interestsQuery.data}
          onSave={updateUser}
          onCancel={handleCancel}
        />
      ) : (
        <PageContainer child footer hideProfile auth>
          <PageBody>
            <div className="relative flex w-full flex-col gap-8 lg:px-5" style={{ marginTop: -21 }}>
              <BodyHeader title={t("user.settings.title")} />
              <img src={Cloud} alt="cloud" className="absolute top-7 right-0 z-0 h-10" />
              <SettingsForm
                repo={repo}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onInterestsChange={onInterestsChange}
              />
            </div>
            <div className="flex w-full flex-col gap-3 lg:px-5">
              <div className="flex flex-col gap-2">
                {userSignedMethod(Auth0_user?.sub ?? "") === SIGNED_METHOD.GOOGLE && (
                  <SignInMethod className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {t("user.settings.signinMethod").replace(":METHOD", "Google")} {getUser(user.profile.email)}
                  </SignInMethod>
                )}
                {userSignedMethod(Auth0_user?.sub ?? "") === SIGNED_METHOD.APPLE && (
                  <SignInMethod>
                    {t("user.settings.signinMethod").replace(":METHOD", "Apple")} {getUser(user.profile.email)}
                  </SignInMethod>
                )}
                {userSignedMethod(Auth0_user?.sub ?? "") === SIGNED_METHOD.AUTH0 && (
                  <SignInMethod className="overflow-hidden text-ellipsis whitespace-nowrap" title={user.profile.email}>
                    {t("user.settings.signinMethod").replace(":METHOD", user.profile.email)}
                  </SignInMethod>
                )}
                <span className="text-xs font-light leading-4 tracking-semiwide text-text-paragraph">
                  {t("user.settings.signinMethod.description")}
                </span>
              </div>

              <div className="mt-6 flex flex-col">
                {userSignedMethod(Auth0_user?.sub ?? "") === SIGNED_METHOD.AUTH0 && (
                  <Button type="button" onClick={verifyChangePassword} className="flex items-center gap-3 pt-5 pb-5">
                    <img src={RESET_ICON} className="object-cover" alt={t("user.settings.change")!} />
                    {t("user.settings.change")}
                  </Button>
                )}

                <Button type="button" onClick={handleLogout} className="flex items-center gap-3 pt-5 pb-5">
                  <img src={LOGOUT_ICON} className="object-cover" alt={t("globals.logout")!} />
                  {t("globals.logout")}
                </Button>

                <DeleteButton
                  type="button"
                  onClick={handleVerifyDeleteAccount}
                  className="flex items-center gap-3 pt-5 pb-5"
                  id="unforget-delete"
                >
                  <img src={TRASH_ICON} className="object-cover" alt={t("user.settings.delete")!} />
                  {t("user.settings.delete")}
                </DeleteButton>
              </div>
            </div>
          </PageBody>
        </PageContainer>
      )}
    </>
  );
};
