import { PartyIcon } from "layout/components/Icons/PartyIcon";
import { useTranslation } from "react-i18next";

import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";

import { SetupLinkStatus } from "../../../../pages/user/setup/root";

interface Props {
  onChange: (slug: string) => void;
  status: SetupLinkStatus;
  onShuffle: () => void;
  onBlur: () => void;
  text: string;
  touched: boolean;
}

export const SlugInput: React.FC<Props> = ({ status, onChange, onShuffle, onBlur, touched, text }) => {
  const { t } = useTranslation();
  const getHelperMsg = () => {
    if (status === SetupLinkStatus.VALID_SLUG) {
      return {
        type: "success",
        body: (
          <FormHelperText id="slug-text" className="text-green-600" style={{ marginLeft: 0 }}>
            {t("user.setup.link.slug.available")}
          </FormHelperText>
        ),
      };
    }
    if (!touched) return null;
    if (text.length === 0) {
      return {
        type: "error",
        body: (
          <FormHelperText id="slug-text" style={{ marginLeft: 0 }}>
            {t("user.setup.link.slug.required")}
          </FormHelperText>
        ),
      };
    }
    if (status === SetupLinkStatus.INVALID_FORMAT) {
      return {
        type: "error",
        body: (
          <FormHelperText id="slug-text" style={{ marginLeft: 0 }}>
            {t("user.setup.link.slug.invalid")}
          </FormHelperText>
        ),
      };
    }
    if (status === SetupLinkStatus.INVALID_SLUG) {
      return {
        type: "error",
        body: (
          <FormHelperText id="slug-text" style={{ marginLeft: 0 }}>
            {t("user.setup.link.slug.notAvailable")}
          </FormHelperText>
        ),
      };
    }

    return null;
  };

  const helperMsg = getHelperMsg();

  const onTextChange = (text: string) => {
    if (
      status === SetupLinkStatus.FETCHING_CURRENT_SLUG ||
      status === SetupLinkStatus.GENERATING_SLUG ||
      status === SetupLinkStatus.VALIDATING
    )
      return;
    onChange(text);
  };

  return (
    <FormControl error={helperMsg?.type === "error"}>
      <InputLabel htmlFor="slug" shrink error={helperMsg?.type === "error"} style={{ marginLeft: "-14px" }}>
        {t("user.setup.form.slugLabel")}
      </InputLabel>
      <Input
        id="slug"
        type="text"
        value={text}
        onChange={(e) => {
          onTextChange(e.target.value);
        }}
        onBlur={onBlur}
        placeholder="happysnake"
        aria-describedby="slug-text"
        endAdornment={
          <InputAdornment position="end">
            {status === SetupLinkStatus.GENERATING_SLUG ||
            status === SetupLinkStatus.FETCHING_CURRENT_SLUG ||
            status === SetupLinkStatus.VALIDATING ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                aria-label="randomize slug"
                disabled={status === SetupLinkStatus.DEBOUNCING}
                onClick={() => onShuffle()}
                onMouseDown={(e) => e.preventDefault()}
                className="group"
              >
                <PartyIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
      {helperMsg?.body}
    </FormControl>
  );
};
