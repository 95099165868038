import Arrow from "assets/arrow.svg";
import logo from "assets/logo.png";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Title } from "./Title";

interface Props {
  headerCloud?: React.ReactNode;
  canGoBack?: boolean;
  headerTitle?: string;
  title?: string;
  titleNode?: React.ReactNode;
  separator?: React.ReactNode;
  headerBgColor: string;
  bgColor: string;
  small: boolean;
  medium?: boolean;
  fullWidth?: boolean;
  footer?: React.ReactNode;
  headerFullHeight?: boolean;
  footerPadding?: boolean;
  bodyFullHeight?: boolean;
}

export const BigHeaderLayout: FC<Props> = (props) => {
  const fullWidthClass = props.fullWidth ? "w-full" : "mx-auto max-w-xl";
  // const leftElement = props.canGoBack ?
  const navigate = useNavigate();

  function navigateBack() {
    navigate(-1);
  }

  return (
    <div className={`relative flex h-full w-full flex-col overflow-auto bg-brand-primary`}>
      <div
        className={`h-full ${props.bgColor} mx-auto w-full max-w-xl pb-5`}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <header
          className={`${fullWidthClass} relative z-0 flex ${
            props.headerFullHeight ? "h-full" : "h-fit"
          } w-full flex-col overflow-auto ${props.headerBgColor}`}
        >
          <div className={`text-primary z-10 flex h-16 flex-row items-center justify-start px-4 md:px-8`}>
            {props.canGoBack ? (
              <button
                className={`mr-4 flex h-8 w-8 items-center justify-center rounded-full bg-brand-secondary`}
                onClick={navigateBack}
              >
                <img src={Arrow} alt="navigate back" className="object-cover" />
              </button>
            ) : (
              props.headerCloud
            )}
            <img alt="unforget logo" src={logo} className={`absolute right-0 left-0 mx-auto w-[89px] object-cover`} />
          </div>
          <div className={`flex h-fit w-full flex-col overflow-hidden ${props.headerBgColor}`}>
            {!props.small && (
              <div
                className="mx-auto flex w-full max-w-xl items-center px-7 pb-5 pt-2 text-center"
                style={{ minHeight: props.medium ? 100 : 200 }}
              >
                {props.titleNode ? props.titleNode : props.title ? <Title variant="small">{props.title}</Title> : null}
              </div>
            )}

            {props.separator}
          </div>
        </header>
        <div
          style={{
            flex: "1 1 auto",
            overflowY: "auto",
            height: props.bodyFullHeight ? "100%" : "auto",
          }}
        >
          {props.children}
        </div>

        {props.footer && (
          <footer style={{ flex: "0 0 auto", paddingTop: props.footerPadding ? "13px" : "0" }}>{props.footer}</footer>
        )}
      </div>
    </div>
  );
};
