import HEART from "assets/heart.svg";
import MSG from "assets/msg.svg";
import T_LOGO from "assets/telegram-icon.svg";
import WS_LOGO from "assets/whatsapp-icon.png";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Swipeable } from "shared/components";

import { Divider } from "@mui/material";
import { useSuperMutation } from "@vadiun/react-hooks";

import { useSendGreetings } from "../hooks/useSendGreetings";
import { Event } from "../models/Event";
import { EventRepository } from "../services/eventRepository";
import { tenorRepository } from "../services/tenorRepository";
import { CalendarButton } from "./CalendarButton";

interface Props {
  event: Event;
  isFutureEvent?: boolean;
  isPastEvent?: boolean;
  repo: EventRepository;
}

export const EventDescriptor: React.FC<Props> = ({ event, isFutureEvent, isPastEvent, repo }) => {
  const { t } = useTranslation();
  const [drawerState, setDrawerState] = useState(false);
  const { sendGreetings } = useSendGreetings();

  //We have to wrap the mutationFn. because share is only available in secure context
  const shareMutation = useSuperMutation((values: ShareData) => navigator.share(values), {
    showSuccessMessage: false,
    showErrorMessage: false,
  });

  const eventDateMoment = moment(`${event?.day}-${event?.month}`, "DD-MM");
  const eventDate = eventDateMoment.format("DD MMMM").toUpperCase();
  const day = event?.day;

  const variant = isFutureEvent ? "futureday" : isPastEvent ? "pastday" : "today";

  const phoneNumber = event?.phone ?? "";

  const text = t("events.displayEvent.descriptor.greetings").replace(":NICKNAME", event?.nickname);
  const message = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(text)}`;
  const tMessage = `https://t.me/share/url?url=${window.location.origin}&text=${encodeURIComponent(text)}`;

  async function handleShare() {
    const shareData: ShareData = {
      title: text,
      text,
    };

    try {
      if (navigator.canShare(shareData)) {
        shareData.files = await tenorRepository().getGif();
        shareMutation.mutate(shareData);
      }
    } catch (e) {
      //fallback share
      setDrawerState(true);
    }
  }

  return (
    <>
      {event ? (
        <div className="flex w-full flex-col gap-3">
          <div className="flex flex-row items-center justify-between">
            <Link to={`/main/events/birthday-details?event=${event.id}`} className="flex max-w-[85%] flex-1">
              <div className="flex max-w-[100%] flex-1 flex-row gap-5">
                <CalendarButton eventThisDay variant={variant}>
                  {day}
                </CalendarButton>
                <div className="flex max-w-[75%] flex-col justify-center gap-1">
                  <p className="overflow-hidden text-ellipsis text-base font-medium leading-4 text-text-paragraph">
                    {event.nickname}
                  </p>
                  <span className="text-xs font-normal leading-3 tracking-wider text-text-paragraph">{eventDate}</span>
                </div>
              </div>
            </Link>

            <button disabled={event.greetings} onClick={handleShare}>
              <img
                src={event.greetings ? HEART : MSG}
                alt={t("events.displayEvent.descriptor.send")!}
                className="h-6 w-6 object-cover"
              />
            </button>
          </div>
          <Divider />
        </div>
      ) : (
        <p className="text-base font-light leading-5 text-text-paragraph">
          {t("events.displayEvent.descriptor.empty")}
        </p>
      )}
      <Swipeable
        state={drawerState}
        onClose={() => setDrawerState(false)}
        onOpen={() => setDrawerState(true)}
        title={t("events.displayEvent.descriptor.sendVia")!}
      >
        <a target="_blank" href={message} onClick={() => sendGreetings(event.id, variant)} rel="noreferrer">
          <div className="flex flex-col items-center">
            <img width="36px" src={WS_LOGO} alt="whatsapp logo" />
            <span className="text-xs" style={{ marginTop: "8px" }}>
              {t("share.whatsapp")}
            </span>
          </div>
        </a>
        <a target="_blank" href={tMessage} onClick={() => sendGreetings(event.id, variant)} rel="noreferrer">
          <div className="flex flex-col items-center">
            <img width="36px" src={T_LOGO} alt="telegram logo" />
            <span className="text-xs" style={{ marginTop: "8px" }}>
              Telegram
            </span>
          </div>
        </a>
      </Swipeable>
    </>
  );
};
